<div class="modal-body">
  <div *ngIf="showMenteeProfile; else loadingTemplate">
    <div class="side-panel-header-q d-flex justify-content-between px-4 py-2">
      <span class="size-18">Update Profile Information</span>
      <a
        href="javascript:void(0)"
        class="closebtn"
        (click)="activeModal.dismiss('Cross click')"
        >×</a
      >
    </div>
    <div class="menteeProfileTabs">
      <div class="menteeProfileSection">
        <div class="warning-msg size-16 px-4 py-3">
          <span
            ><b>Sorry!</b> Your profile is incomplete. Please enter your missing
            information below and select 'Save & Continue'.</span
          >
        </div>
        <div class="mentee-profile d-flex px-4 py-4">
          <img
            class="user-image mr-2 border-radius-25"
            [src]="
              imgurl
                ? imgurl
                : 'https://lfx-cdn-prod.s3.amazonaws.com/users/avatar/' +
                  menteeName.toLowerCase().charAt(0) +
                  '.png'
            "
          />
          <div class="ml-3">
            <h5 class="text-dark weight-600 size-24">
              {{ userServiceCall[0]?.Name }}
              <img src="{{ lnImgSrc }}" class="linkedIn pl-1" alt="LinkedIn" />
              <a href="{{ githubId }}" target="_blank"
                ><img src="{{ gitImgSrc }}" class="github pl-1" alt="Github"
              /></a>
              <img
                src="{{ twitterImgSrc }}"
                class="twitter pl-1"
                alt="Twitter"
              />
            </h5>
            <h6 class="size-16">
              {{ userServiceCall[0]?.Emails[0]?.EmailAddress }}
            </h6>
          </div>
        </div>
        <div class="d-flex pl-0 pb-0 menteeProfileTabs">
          <div
            class="tabs profile active size-16"
            (click)="ShowTabs('profile')"
            [ngClass]="{ active: activeTabIndex === 1 }"
          >
            PROFILE
            <div
              class="redDot ml-2"
              style="
                background-color: red;
                width: 15px;
                height: 15px;
                border-radius: 50px;
              "
            ></div>
          </div>
          <div
            class="tabs skills size-16"
            (click)="ShowTabs('skills')"
            [ngClass]="{ active: activeTabIndex === 2 }"
          >
            SKILLS
            <div
              class="redDot ml-2"
              style="
                background-color: red;
                width: 15px;
                height: 15px;
                border-radius: 50px;
              "
            ></div>
          </div>
          <div
            class="tabs menteeDemographics size-16"
            (click)="ShowTabs('demographics')"
            [ngClass]="{ active: activeTabIndex === 3 }"
          >
            DEMOGRAPHICS (Optional)
          </div>
        </div>
        <form [formGroup]="editMenteeForm">
          <div class="profile-body" *ngIf="activeTabIndex === 1">
            <div class="contact-details pt-4 px-4">
              <div
                class="introduction mt-3 mx-1 mb-4 pb-2"
                style="display: none"
              >
                <h6 class="txtColor weight-600 size-16 pt-3 pl-3">
                  Social Networks
                </h6>
                <div class="socialNt d-flex justify-content-between mt-4">
                  <div class="d-flex ml-3" *ngIf="!gitImgEnable">
                    <img src="{{ gitImgSrc }}" class="githubSN" alt="Github" />
                    <div class="github_actionR pl-2">
                      <h5 class="size-14 sn">GitHub</h5>
                      <h6
                        class="txtColor socialM size-14"
                        (click)="connectToGit()"
                      >
                        <b>Action required:</b> Connect account
                        <i class="fas fa-chevron-right ml-1"></i>
                      </h6>
                    </div>
                  </div>
                  <div
                    class="line"
                    *ngIf="!gitImgEnable"
                    style="border-right: 1px solid #ccc"
                  ></div>
                  <div class="d-flex mr-3 ml-2" *ngIf="!lnimgEnable">
                    <img
                      src="/assets/linkedin_grey.svg"
                      class="linkedinSN"
                      alt="LinkedIn"
                    />
                    <div class="github_actionR pl-2">
                      <h5 class="size-14 sn">LinkedIn</h5>
                      <h6 class="txtColor size-14">
                        <b>Action required:</b> Connect account
                        <i class="fas fa-chevron-right ml-1"></i>
                      </h6>
                    </div>
                  </div>
                  <div class="line" style="border-right: 1px solid #ccc"></div>
                  <div class="d-flex mr-3 ml-2" *ngIf="!twitterImgEnable">
                    <img
                      src="/assets/twitter_grey.svg"
                      class="twitterSN"
                      alt="Twitter"
                    />
                    <div class="github_actionR pl-2">
                      <h5 class="size-14 sn">Twitter</h5>
                      <h6 class="txtColor size-14">
                        <b>Action required:</b> Connect account
                        <i class="fas fa-chevron-right ml-1"></i>
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
              <div class="cDetails size-14 pl-3 pt-3" formGroupName="formPage1">
                <h6 class="text-dark weight-600">Contact Details</h6>
                <div class="row d-flex">
                  <div class="col-6">
                    <label
                      class="input-title txtColor size-14 weight-600"
                      for="address1"
                      >Street<span class="text-danger">*</span></label
                    >
                    <input
                      type="text"
                      [placeholder]="'Enter address here'"
                      class="form-control"
                      name="address1"
                      formControlName="address1"
                    />
                    <div
                      *ngIf="
                        formPage1.controls['address1'].touched &&
                        formPage1.controls['address1'].invalid
                      "
                      class="text-danger mt-2"
                    >
                      Street is required
                    </div>
                    <label
                      class="input-title txtColor size-14 weight-600 mt-2"
                      for="city"
                      >City <span class="text-danger">*</span></label
                    >
                    <input
                      type="text"
                      [placeholder]="'Enter city here'"
                      class="form-control"
                      formControlName="city"
                      name="city"
                    />
                    <div
                      *ngIf="
                        formPage1.controls['city'].touched &&
                        formPage1.controls['city'].invalid
                      "
                      class="text-danger mt-2"
                    >
                      City is required
                    </div>
                    <label
                      class="input-title txtColor size-14 weight-600 mt-2"
                      for="country"
                      >Country<span class="text-danger">*</span></label
                    >
                    <select
                      class="form-control"
                      name="country"
                      (change)="openStatesFields()"
                      formControlName="country"
                    >
                      <option *ngFor="let c of countryList" [value]="c.name">
                        {{ c.name }}
                      </option>
                    </select>
                    <div
                      *ngIf="
                        formPage1.controls['country'].touched &&
                        formPage1.controls['country'].invalid
                      "
                      class="text-danger mt-2"
                    >
                      country is required
                    </div>
                    <label class="txtColor size-14 weight-600 mt-2"
                      >Phone<span class="text-danger">*</span></label
                    >
                    <input
                      type="text"
                      [placeholder]="'123-345-1224'"
                      class="form-control mb-3"
                      name="phone"
                      formControlName="phone"
                    />
                    <div
                      *ngIf="
                        formPage1.controls['phone'].touched &&
                        formPage1.controls['phone'].invalid
                      "
                      class="text-danger mt-2"
                    >
                      Phone is required
                    </div>
                    <div></div>
                  </div>
                  <div class="col-6">
              
                    <label
                      class="input-title txtColor size-14 weight-600 mt-2"
                      for="states"
                      >State/Province <span class="text-danger">*</span></label
                    >
                    <input
                      type="text"
                      [placeholder]="'Enter State'"
                      class="form-control"
                      formControlName="state"
                      name="states"
                      class="form-control states"
                    />
                    <div
                      *ngIf="state && state.touched && state.invalid"
                      class="text-danger mt-2"
                    >
                      State is required
                    </div>
                    <label
                      class="input-title txtColor size-14 weight-600 mt-2"
                      for="postal-code"
                      >Postal Code <span class="text-danger">*</span></label
                    >
                    <input
                      type="text"
                      [placeholder]="'Enter Postal Code'"
                      class="form-control"
                      formControlName="postalCode"
                      name="postalCode"
                      class="form-control postalCode"
                    />
                    <div
                      *ngIf="
                        formPage1.controls['postalCode'].touched &&
                        formPage1.controls['postalCode'].invalid
                      "
                      class="text-danger mt-2"
                    >
                      Postal code is required
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-4 mx-4 mb-4 pb-2" formGroupName="formPage1">
              <div class="row">
                <div class="col-6 tileStyle">
                  <h6 class="txtColor weight-600 size-16 pt-3 pl-3">
                    Introduction <span class="text-danger">*</span>
                  </h6>
                  <span class="txtColor size-14 pt-2 pl-3"
                    >Tell us little about yourself</span
                  >
                  <div class="pt-3 px-3 mb-3 pb-3">
                    <textarea
                      class="w-100"
                      placeholder="Testing Mentorship Platform to experience what it is like to sign up as a mentee."
                      rows="5"
                      formControlName="about"
                    ></textarea>
                    <div
                      *ngIf="
                        formPage1.controls['about'].touched &&
                        formPage1.controls['about'].invalid
                      "
                      class="text-danger mt-2"
                    >
                      Introduction is required
                    </div>
                  </div>
                </div>
                <div class="col-6 tileStyle">
                  <h6 class="txtColor weight-600 size-16 pt-3 pl-3">
                    Resume/CV
                  </h6>
                  <span class="txtColor size-14 pt-2 pl-3"
                    >Accepted file types: DOC, DOCX, PDF, TXT</span
                  >
                  <div class="pt-3 px-3 pb-3">
                    <div
                      class="add-attachment d-flex align-items-center flex-column"
                    >
                      <span>Drop files to upload</span><span>Or</span>
                      <button
                        type="button"
                        class="btn btn-primary custom-btn size-14"
                      >
                        Browse Files
                      </button>
                      <div
                        *ngIf="isLoading"
                        class="spinner-border text-primary"
                        style="width: 2rem; height: 2rem"
                        role="status"
                      >
                        <span class="sr-only">Loading...</span>
                      </div>
                      <input
                        type="file"
                        name="uploadFile"
                        id="avatar"
                        accept="image/png, image/jpeg, image/svg+xml"
                        avatarImage="logoUrl"
                        (change)="handleFiles($event, true)"
                      />
                    </div>
                    <span class="text-danger" *ngIf="fileTypeErrMsg">{{fileTypeErrMsg}}</span>
                    <div
                      class="d-flex flex-column align-items-center justify-content-center"
                    >
                      <img id="avatarImage" />
                      <a
                        type="button"
                        id="avatarImage"
                        target="_blank"
                        *ngIf="fileUrl"
                        class="py-1 px-3 mx-1 cursor-pointer"
                        [href]="fileUrl"
                      >
                        View
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="profile-body" *ngIf="activeTabIndex === 2">
            <div class="mt-4 mx-4 pb-2" formGroupName="formPage4">
              <div class="row d-flex justify-content-around">
                <div class="col-6 tileStyle">
                  <h6 class="txtColor weight-600 size-16 pt-3 pl-3">
                    Proficiency<span class="text-danger pl-1">*</span>
                  </h6>
                  <div class="txtColor size-14 pt-2 pl-3 pr-3">
                    <b>What skills are you proficient in?</b> List upto five
                    skills for each category
                  </div>
                  <div class="skills-selector pt-3 px-3 mb-3">
                    <!-- <textarea
                      class="w-100"
                      placeholder="What skills are you proficient in?"
                      rows="5"
                      formControlName="skills"
                    > -->
                    <ng-select
                      [items]="skillSet"
                      bindLabel="name"
                      placeholder="Select skills"
                      [multiple]="true"
                      (add)="addSkill($event)"
                      (remove)="removeSkill($event)"
                      (clear)="removeAllSkills()"
                      [hideSelected]="true"
                      [(ngModel)]="selectedProficiencySkills"
                      placeholder="Enter programming languages, operating systems and technologies"
                      [ngModelOptions]="{ standalone: true }"
                    >
                      <ng-template
                        ng-label-tmp
                        let-skill="item"
                        let-index="index"
                        let-clear="clear"
                      >
                        {{ skill.name }}
                        <i class="fas fa-times" (click)="clear(skill)"></i>
                      </ng-template>
                      <ng-template ng-option-tmp let-skill="item">
                        {{ skill.name }}
                      </ng-template>
                    </ng-select>
                    <div
                      *ngIf="
                        formPage4.controls['skills'].touched &&
                        formPage4.controls['skills'].invalid
                      "
                      class="text-danger mt-2"
                    >
                      Proficiency is required
                    </div>
                  </div>
                </div>
                <div class="col-6 tileStyle">
                  <h6 class="txtColor weight-600 pt-3 pl-3">
                    Need imporvement
                  </h6>
                  <span class="txtColor size-12 pt-2 pl-3"
                    ><b>What areas do you want to improve in?</b></span
                  >
                  <div class="skills-selector pt-3 mb-3">
                    <ng-select
                      [items]="skillSet"
                      bindLabel="name"
                      placeholder="Select skills"
                      [multiple]="true"
                      (add)="addSkill($event)"
                      (remove)="removeSkill($event)"
                      (clear)="removeAllSkills()"
                      [hideSelected]="true"
                      [(ngModel)]="selectedImprovementSkills"
                      [ngModelOptions]="{ standalone: true }"
                    >
                      <ng-template
                        ng-label-tmp
                        let-skill="item"
                        let-index="index"
                        let-clear="clear"
                      >
                        {{ skill.name }}
                        <i class="fas fa-times" (click)="clear(skill)"></i>
                      </ng-template>
                      <ng-template ng-option-tmp let-skill="item">
                        {{ skill.name }}
                      </ng-template>
                    </ng-select>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="introduction mt-4 mx-4 pb-2 mb-2"
              formGroupName="formPage2"
            >
              <h6 class="txtColor weight-600 pt-3 pl-3">More Information</h6>
              <span class="txtColor pt-2 pl-3"
                >Anything else you want mentors to know?</span
              >
              <div class="pt-3 px-3">
                <textarea
                  class="w-100"
                  rows="5"
                  formControlName="moreInformation"
                ></textarea>
                <div
                  *ngIf="
                    formPage2.controls['moreInformation'].touched &&
                    formPage2.controls['moreInformation'].invalid
                  "
                  class="text-danger mt-2"
                >
                  More information is required
                </div>
              </div>
            </div>
          </div>
        </form>
        <div
          class="profile-body Demographics pb-4"
          *ngIf="activeTabIndex === 3"
        >
          <div class="textCard py-4 mx-4">
            <div class="text1">
              We want to encourage greater diversity within the open source
              community, and demographic information helps us achieve that goal.
              Demographic information is collected for the purpose of compiling,
              analyzing and disclosing aggregate statistics regarding diversity
              of participation in open source projects and communities
              (including in Mentorship programs and Mentorship opportunities),
              to help track progress towards meeting our commitment to diversity
              initiatives.
            </div>
            <div class="text2 my-4">
              Consent to provide demographic information for the purpose listed
              above is optional and purely voluntary, and is provided by
              selecting an option from the drop-down for the corresponding
              category. Demographic information is considered confidential and
              private, and will not be shared with mentors as part of your
              registration details when you are considered for mentorships, nor
              will it be used to subject you to adverse treatment.
            </div>
            <div class="text3">
              Should you choose to provide any demographic information, you may
              request removal of that information from Mentorship at any time by
              writing to
              <a href="mailto:privacy@linuxfoundation.org"
                >privacy@linuxfoundation.org</a
              >.
            </div>
          </div>
          <div class="row DemoRow">
            <div class="col-sm-12 col-md-5 col-lg-5 DemoTiles py-3">
              <label>How old are you?</label>
              <div class="d-flex align-items-baseline pb-2">
                <input
                  type="checkbox"
                  class="mr-2"
                  (change)="toggleSelection('one')"
                  [checked]="isAgeQuestion"
                />
                <div>
                  {{ consentText }}
                </div>
              </div>
              <div class="dropdownDiv">
                <select [disabled]="!isAgeQuestion">
                  <option>Select</option>
                  <option *ngFor="let op of ageQuestion.options" [value]="op">
                    {{ op.text }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-sm-12 col-md-5 col-lg-5 DemoTiles py-3">
              <label>What is your racial or ethnic identity?</label>
              <div class="d-flex align-items-baseline pb-2">
                <input
                  type="checkbox"
                  class="mr-2"
                  (change)="toggleSelection('two')"
                  [checked]="isRacesQuestion"
                />
                <div>
                  {{ consentText }}
                </div>
              </div>
              <div class="dropdownDiv">
                <select [disabled]="!isRacesQuestion">
                  <option>Select</option>
                  <option *ngFor="let op of racesQuestion.options" [value]="op">
                    {{ op.text }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="row DemoRow my-3">
            <div class="col-sm-12 col-md-5 col-lg-5 DemoTiles py-3">
              <label>Which gender do you identify with?</label>
              <div class="d-flex align-items-baseline pb-2">
                <input
                  type="checkbox"
                  class="mr-2"
                  (change)="toggleSelection('three')"
                  [checked]="isGenderQuestion"
                />
                <div>
                  {{ consentText }}
                </div>
              </div>
              <div class="dropdownDiv">
                <select [disabled]="!isGenderQuestion">
                  <option>Select</option>
                  <option
                    *ngFor="let op of genderQuestion.options"
                    [value]="op"
                  >
                    {{ op.text }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-sm-12 col-md-5 col-lg-5 DemoTiles py-3">
              <label>Which socioeconomic class do you identify with?</label>
              <div class="d-flex align-items-baseline pb-2">
                <input
                  type="checkbox"
                  class="mr-2"
                  (change)="toggleSelection('four')"
                  [checked]="isIncomeConsentQuestion"
                />
                <div>
                  {{ consentText }}
                </div>
              </div>
              <div class="dropdownDiv">
                <select [disabled]="!isIncomeConsentQuestion">
                  <option>Select</option>
                  <option
                    *ngFor="let op of incomeConsentQuestion.options"
                    [value]="op"
                  >
                    {{ op.text }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="row lastRow">
            <div class="tiles DemoTiles py-3 px-3">
              <label>What is your education level?</label>
              <div class="d-flex align-items-baseline pb-2">
                <input
                  type="checkbox"
                  class="mr-2"
                  (change)="toggleSelection('five')"
                  [checked]="isEducationLevelQuestion"
                />
                <div>
                  {{ consentText }}
                </div>
              </div>
              <div class="dropdownDiv">
                <select [disabled]="!isEducationLevelQuestion">
                  <option>Select</option>
                  <option
                    *ngFor="let op of educationLevelQuestion.options"
                    [value]="op"
                  >
                    {{ op.text }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-end mr-2 py-3">
        <button
          *ngIf="!showContinueBtn"
          class="btn btns btn-primary ml-2"
          (click)="moveToNextTab()"
        >
          <!-- [disabled]="menteeProfileForm.invalid" -->
          Continue
        </button>
        <button
          *ngIf="showContinueBtn"
          class="btn btns btn-primary ml-2"
          (click)="SubmitMenteeProfile()"
        >
          Submit
        </button>
      </div>
    </div>
  </div>
</div>
<ng-template #loadingTemplate>
  <lfx-loading *ngIf="isLoading" class="flex-grow-1"></lfx-loading>
</ng-template>
