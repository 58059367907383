import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { AuthService } from "@auth0/auth0-angular";
import {
  distinctUntilChanged,
  forkJoin,
  mergeMap,
  of,
  Subject,
  switchMap,
  tap,
} from "rxjs";
import {
  MAX_PHONE_NUMBER_LENGTH,
  MIN_PHONE_NUMBER_LENGTH,
  PHONE_NUMBER_PATTERN,
} from "src/app/core/constants";
import { LinkedAccountService } from "src/app/core/services/linked-account.service";
import { UserService } from "src/app/core/services/user.service";
import { CustomValidators } from "src/app/core/validators";
import { COUNTRY_LIST } from "src/app/mentee/data/countries";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Router } from "@angular/router";
import { SkillsService } from "src/app/core/services/skills.service";
import { MenteeApplicationController } from "src/app/core/controllers/mentee-application.controller";
import { FileUploadService } from "src/app/core/services/file-upload.service";

@Component({
  selector: "lfx-mentee-side-panel",
  templateUrl: "./mentee-side-panel.component.html",
  styleUrls: ["./mentee-side-panel.component.scss"],
})
export class MenteeSidePanelComponent implements OnInit {
  readonly minPhoneNumberLength = MIN_PHONE_NUMBER_LENGTH;
  readonly maxPhoneNumberLength = MAX_PHONE_NUMBER_LENGTH;
  @Output() closePanel = new EventEmitter();
  @Input() newProjectForm!: FormGroup;
  @Input() programID!: string;

  showMenteeProfile = true

  editMenteeForm = new FormGroup({});
  showSaveNContinue: boolean = false;
  showContinueBtn: boolean = false;
  activeTabIndex = 1;
  menteeName = "Drishya";
  menteeMailId = "drishyauk@gmail.com";
  countryList = COUNTRY_LIST;
  avatarSrc: string = "";
  isUsSelected = false;
  imgurl = "";
  userServiceCall = new Array();
  skillSet = new Array();
  skillsTypeAhead$ = new Subject<string>();
  skillsLoader = false;
  skillFormControl = new FormControl([]);
  loggedInUser: boolean = false;
  prompt = "login";
  connection = "github";
  githubId = "";
  linkedinId = "";
  twitterId = "";
  gitImgEnable = false;
  lnimgEnable = false;
  twitterImgEnable = false;
  gitImgSrc = "/assets/github_grey.svg";
  lnImgSrc = "/assets/linkedin_grey.svg";
  twitterImgSrc = "/assets/twitter_grey.svg";
  isLoading = false;
  selectedProficiencySkills: any;
  selectedImprovementSkills: any;
  isAgeQuestion: boolean = false;
  isRacesQuestion: boolean = false;
  isGenderQuestion: boolean = false;
  isIncomeConsentQuestion: boolean = false;
  isEducationLevelQuestion: boolean = false;
  racesQuestion = {
    question: "What is your racial or ethnic identity?",
    options: [
      {
        text: "Select",
        value: "",
      },
      {
        text: "White",
        value: "white",
      },
      {
        text: "Hispanic or Latino",
        value: "hispanicOrLatino",
      },
      {
        text: "Black or African-American",
        value: "blackOrAfricanAmerican",
      },
      {
        text: "American Indian or Alaskan Native",
        value: "americanIndianOrAlaskanNative",
      },
      {
        text: "Asian",
        value: "asian",
      },
      {
        text: "Native Hawaiian or other Pacific islander",
        value: "NativeHawaiianOrOtherPacificIslander",
      },
      {
        text: "Two or more races",
        value: "twoOrMoreRaces",
      },
      {
        text: `I don't want to provide`,
        value: "IDonotWantToProvide",
      },
    ],
  };
  ageQuestion = {
    question: "How old are you?",
    options: [
      {
        text: "Select",
        value: "",
      },
      {
        text: "19 or younger",
        value: "-19",
      },
      {
        text: "20-39",
        value: "20-39",
      },
      {
        text: "40-60",
        value: "40-60",
      },
      {
        text: "61 or older",
        value: "61 or older",
      },
      {
        text: `I don't want to provide`,
        value: "IDonotWantToProvide",
      },
    ],
  };
  genderQuestion = {
    question: "Which gender do you identify with?",
    options: [
      {
        text: "Select",
        value: "",
      },
      {
        text: "Male",
        value: "male",
      },
      {
        text: "Female",
        value: "female",
      },
      {
        text: "Non-binary",
        value: "nonBinary",
      },
      {
        text: `I don't want to provide`,
        value: "IDonotWantToProvide",
      },
    ],
  };
  incomeConsentQuestion = {
    question: "Which socioeconomic class do you identify with?",
    options: [
      {
        text: "Select",
        value: "",
      },
      {
        text: "Working class",
        value: "workingClass",
      },
      {
        text: "Lower middle class",
        value: "lowerMiddleClass",
      },
      {
        text: "Upper middle class",
        value: "upperMiddleClass",
      },
      {
        text: "Upper class",
        value: "upperClass",
      },
      {
        text: `I don't want to provide`,
        value: "IDonotWantToProvide",
      },
    ],
  };
  educationLevelQuestion = {
    question: "What is your education level?",
    options: [
      {
        text: "Select",
        value: "",
      },
      {
        text: "Some high school",
        value: "someHighSchool",
      },
      {
        text: "Some college/technical training",
        value: "someCollege",
      },
      {
        text: "Completed college",
        value: "college",
      },
      {
        text: `Completed master's degree`,
        value: "masters",
      },
      {
        text: "Completed Ph.D",
        value: "phd",
      },
      {
        text: `I don't want to provide`,
        value: "IDonotWantToProvide",
      },
    ],
  };
  consentText =
    "I consent to use of this information for the purpose listed above.";
   fileUrl = ''; 
   userId = '';
  skillsNew: any[] = [];
  uploadedResume: any = [];
  acceptedFileExtensions = '.doc,.docx,.pdf,.txt';
  fileTypeErrMsg = '';
  get skills() {
    return this.formPage4.get("skills") as FormArray;
  }

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private authService: AuthService,
    private linkedAccountService: LinkedAccountService,
    public activeModal: NgbActiveModal,
    private route: Router,
    private skillsService: SkillsService,
    private menteeApplicationController: MenteeApplicationController,
    private fileUploadService: FileUploadService
  ) {
    this.editMenteeForm = new FormGroup({
      formPage1: new FormGroup({
        // first section
        firstName: new FormControl("", Validators.required),
        lastName: new FormControl("", Validators.required),
        email: new FormControl("", [Validators.required, Validators.email]),
        phone: new FormControl("", [
          Validators.required,
          Validators.minLength(this.minPhoneNumberLength),
          Validators.maxLength(this.maxPhoneNumberLength),
          Validators.pattern(PHONE_NUMBER_PATTERN),
        ]),
        logoUrl: new FormControl(""),
        about: new FormControl("", [Validators.required]),

        // second section
        address1: new FormControl("", Validators.required),
        country: new FormControl("", Validators.required),
        state: new FormControl(
          "",
          this.validateState.bind(this) as ValidatorFn
        ),
        city: new FormControl("", Validators.required),
        postalCode: new FormControl("", Validators.required),

        // third section
        githubUrl: new FormControl("", CustomValidators.url),
        linkedInUrl: new FormControl("", CustomValidators.url),
        resumeUrl: new FormControl(""),
      }),
      formPage2: new FormGroup({
        moreInformation: new FormControl(""),
      }),
      formPage3: new FormGroup({
        age: new FormControl({ value: "", disabled: false }),
        race: new FormControl({ value: "", disabled: false }),
        gender: new FormControl({ value: "", disabled: false }),
        householdIncome: new FormControl({ value: "", disabled: false }),
        educationLevel: new FormControl({ value: "", disabled: false }),
      }),
      formPage4: new FormGroup({
        skills: new FormControl("", Validators.required),
      }),
    });
  }

  get formPage1(): FormGroup {
    return this.editMenteeForm.get("formPage1") as FormGroup;
  }

  get formPage2(): FormGroup {
    return this.editMenteeForm.get("formPage2") as FormGroup;
  }

  get formPage3(): FormGroup {
    return this.editMenteeForm.get("formPage3") as FormGroup;
  }

  get formPage4(): FormGroup {
    return this.editMenteeForm.get("formPage4") as FormGroup;
  }

  get country(): FormControl {
    return this.formPage1.get("country") as FormControl;
  }

  get state(): FormControl {
    return this.formPage1.get("state") as FormControl;
  }

  get city(): FormControl {
    return this.formPage1.get("city") as FormControl;
  }

  ngOnInit(): void {
    this.authService.isAuthenticated$.subscribe((res) => {
      this.loggedInUser = res;
    });
    this.loadMenteeProfileDetails();
  }

  private loadMenteeProfileDetails() {
    this.skillSet = this.skillsService.skillsList;
    this.skillFormControl.setValue(this.skillsService.skillsList);
    this.userService.getUserDetails().subscribe((res) => {
      this.isLoading = true;
      this.userServiceCall.push(res);
      this.githubId = res.GithubID;
      this.linkedinId = res.LinkedinId;
      this.twitterId = res.TwitterId;
      this.isLoading = false;
      this.formPage1.get("address1")?.setValue(res.Address?.Street);
      this.formPage1.get("city")?.setValue(res.Address?.City);
      this.formPage1.get("state")?.setValue(res.Address?.State);
      this.formPage1.get("country")?.setValue(res.Address?.Country);
      this.formPage1.get("postalCode")?.setValue(res.Address?.PostalCode);
      this.formPage1.get("phone")?.setValue(res.Phone);
      this.formPage1.get("about")?.setValue(res?.Bio);
      this.userId = res.ID
      this.socialNetworkIcons();
    });
    this.loadSkills();
  }

  private loadSkills() {
    this.skillsTypeAhead$.pipe(
      distinctUntilChanged(),
      tap(() => (this.skillsLoader = true)),
      switchMap((term) => {
        const filteredSkills = this.skillSet.filter(
          (skill) => skill.name.toLowerCase().indexOf(term.toLowerCase()) > -1
        );
        this.skillsLoader = false;
        return of(filteredSkills);
      })
    );
  }

  private validateState(state: FormControl): ValidationErrors | null {
    if (
      !this.editMenteeForm ||
      !this.formPage1 ||
      this.country.value !== "US"
    ) {
      return null;
    }

    return Validators.required(state);
  }

  addSkill(skill: string) {
    this.skillsNew.push(skill);
    this.formPage4.get("skills")?.setValue(this.skillsNew);
  }

  removeSkill(skill: any) {
    this.skillsNew.forEach((control, index) => {
      if (control.name === skill.value.name) {
        this.skills.removeAt(index);
      }
    });
  }

  removeAllSkills() {
    this.skills.clear();
  }

  closesideNav() {
    let closePanel = true;
    this.closePanel.emit(closePanel);
  }

  moveToMenteeProfile() {
    this.showContinueBtn = true;
  }

  ShowTabs(tab: string) {
    if (tab == "profile") {
      this.activeTabIndex = 1;
      this.showContinueBtn = false;
    } else if (tab == "skills") {
      this.activeTabIndex = 2;
      this.showContinueBtn = false;
    } else {
      this.activeTabIndex = 3;
      this.showContinueBtn = true;
    }
  }

  clearAvatar() {
    const preview: any = document.querySelector("#avatarImage");
    preview.src = "/assets/no_logo_uploaded.png";
  }

  socialNetworkIcons() {
    if (this.githubId) {
      this.gitImgEnable = true;
      this.gitImgSrc = "/assets/github.svg";
    } else {
      this.gitImgEnable = false;
      this.gitImgSrc = "/assets/github_grey.svg";
    }
    if (this.linkedinId) {
      this.lnimgEnable = true;
      this.lnImgSrc = "/assets/linkedin.svg";
    } else {
      this.lnimgEnable = false;
      this.lnImgSrc = "/assets/linkedin_grey.svg";
    }
    if (this.twitterId) {
      this.twitterImgEnable = true;
      this.twitterImgSrc = "/assets/twitter.svg";
    } else {
      this.twitterImgEnable = false;
      this.twitterImgSrc = "/assets/twitter_grey.svg";
    }
  }

  handleFiles(ev: any, isAvatar: boolean = false) {
    this.isLoading = true;
    this.fileTypeErrMsg = '';
    if (!ev.target.files || ev.target.files.length !== 1) {
      this.isLoading = false;
      this.fileTypeErrMsg = 'Please select a file';
      return;
    }
    const file: File = ev.target.files[0];
    const acceptedFilesTypes = this.acceptedFileExtensions.split(',');
    const extension = file.name.substring(file.name.lastIndexOf('.'));
    if (!acceptedFilesTypes.includes(extension)) {
      this.isLoading = false;
      this.fileTypeErrMsg = `The supported extensions are ${this.acceptedFileExtensions} only`;
      return;
    }
    const fileSize = (file.size / 1024 / 1024) // file size in MiB
    if (fileSize > 10) {
      this.isLoading = false;
      this.fileTypeErrMsg = 'Can not upload file with size more than 10 MB';
      return;
    }
    const reqBody = {
      "Filename": this.userId+'_'+ev.target.files[0].name,
      "Overwrite": false
      }
      
    this.userService.getResumePresignedURL(reqBody, this.userId).subscribe(res => {
      this.fileTypeErrMsg = '';
      const resumeBody = {
        "Resume": res,
        "Title": "Resume test"
        }
      if(res) {
        this.userService.updateUserResume(resumeBody, this.userId).subscribe(res => {
          this.uploadedResume = res;
        });

        this.fileUploadService.uploadFile(ev.target.files[0]).subscribe((url) => {
          const elem: any = document.querySelector(`#avatarImage`);
          const file = elem;
          file.submitFile = url;
          this.fileUrl = file.submitFile;
          this.isLoading = false;
        });
      }
    });
  }

  openStatesFields() {
    const country = this.formPage1.get("country")?.value;
    if (country === "US") {
      this.isUsSelected
        ? (this.isUsSelected = false)
        : (this.isUsSelected = true);
    } else {
      this.isUsSelected = false;
      this.formPage1.removeControl("state");
    }
  }

  moveToNextTab() {
    if (this.activeTabIndex === 1) {
      this.activeTabIndex = 2;
      this.showContinueBtn = false;
    } else if (this.activeTabIndex === 2) {
      this.activeTabIndex = 3;
      this.showContinueBtn = true;
    }
  }

  SubmitMenteeProfile() {
    var userServicereqBody = {
      Address: {
        City: this.formPage1.value.city,
        Country: this.formPage1.value.country,
        PostalCode: this.formPage1.value.postalCode,
        State: this.formPage1.value.state,
        Street: this.formPage1.value.address1,
      },
      Bio: this.formPage1.value.about,
      Phone: this.formPage1.value.phone,
    };
    var createAppliReqBody = {
      programID: this.programID,
    };
    const updateUser = this.userService.updateUserDetails(userServicereqBody, this.userId);
    const createApplication =
      this.menteeApplicationController.createMenteeApplication(
        createAppliReqBody
      );
    forkJoin([updateUser, createApplication]).subscribe(
      (res: any) => {
        if (res) {
          this.activeModal.dismiss();
          this.route.navigateByUrl(`/program/${this.programID}/application/${res[1].menteeTermEnrollment.id}`);
        }
      },
      (err) => {
        console.log("error:", err);
      }
    );
  }

  connectToGit() {
    let popup;
    if (this.loggedInUser) {
      this.linkedAccountService.loginWithPopup(this.connection);
      if (this.connection == "facebook") {
        popup = window.open("/", "sample", "width=800,height=600");
      }
      if (this.connection == "github") {
        this.prompt = "consent";
      }
    } else {
      this.authService.loginWithRedirect();
    }
  }

  toggleSelection(str: string) {
    if (str == "one") {
      this.isAgeQuestion = !this.isAgeQuestion;
    } else if (str == "two") {
      this.isRacesQuestion = !this.isRacesQuestion;
    } else if (str == "three") {
      this.isGenderQuestion = !this.isGenderQuestion;
    } else if (str == "four") {
      this.isIncomeConsentQuestion = !this.isIncomeConsentQuestion;
    } else if (str == "five") {
      this.isEducationLevelQuestion = !this.isEducationLevelQuestion;
    }
  }
}

