import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrerequisitesComponent } from './components/prerequisites/prerequisites.component';
import { MenteeApplicationComponent } from './pages/mentee-application/mentee-application.component';
import { SharedModule } from '../shared/shared.module';
import { MenteeApplicationDashboardComponent } from './pages/mentee-application-dashboard/mentee-application-dashboard.component';

@NgModule({
  declarations: [
    PrerequisitesComponent,
    MenteeApplicationComponent,
    MenteeApplicationDashboardComponent
  ],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports: [],
  providers: [],
})
export class MenteeApplicationModule { }
