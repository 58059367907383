import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgramOverviewComponent } from './pages/program-overview/program-overview.component';
import { SharedModule } from '../shared/shared.module';
import { MentorshipDetailsCardComponent } from './components/mentorship-details-card/mentorship-details-card.component';
import { AllProgramsComponent } from './pages/all-programs/all-programs.component';
import { AllMentorshipComponent } from './pages/all-mentorship/all-mentorship.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SkillsService } from 'src/app/core/services/skills.service';
import { CarouselModule } from 'ngx-owl-carousel-o';
@NgModule({
  declarations: [
    ProgramOverviewComponent,
    MentorshipDetailsCardComponent,
    AllProgramsComponent,
    AllMentorshipComponent,
  ],
  imports: [CommonModule, SharedModule, CarouselModule],
  exports: [],
  providers: [SkillsService, NgbActiveModal],
})
export class ProgramModule {}
