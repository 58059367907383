export const MAX_TASK_NAME_LENGTH = 20;
export const MAX_TASK_DESCRIPTION_LENGTH = 500;
export const MIN_TITLE_LENGTH = 2;
export const MAX_TITLE_LENGTH = 100;
export const MAX_DESCRIPTION_LENGTH = 3000;
export const MAX_MENTEE_BUDGET = 6600;
export const MIN_MENTEE_BUDGET = 3000;
export const PHONE_NUMBER_PATTERN = '^[0-9-+s()]*$';
export const MIN_PHONE_NUMBER_LENGTH = 4;
export const MAX_PHONE_NUMBER_LENGTH = 12;
export const PROGRAMS_PAGE_SIZE = 20;


export const ProgramsStatusFilters = {
  approved: 'approved',
  acceptedApplications: 'accepting-applications',
  inProgress: 'in-progress',
  completed: 'completed',
  validList: ['approved', 'accepting-applications', 'in-progress', 'completed']
};

export const ProgramStatus = {
  draft: 'draft',
  pending: "pending",
  completed: "completed",
  accepted: "accepted",
  inprogress: 'inprogress',
  upcoming: 'upcoming',
  approved: "approved",
  declined: "declined",
  acceptingApplication: "acceptingApplication"
}

export const MenteeApplicationStatus = {
  draft: 'draft',
  submitted: 'submitted',
  accepted: "accepted",
  approved: "approved",
  offerSent: 'offered',
  declined: "declined",
  withdrawn: 'withdrawn',
  graduated: "graduated"
}


export const MentorInvitationStatus = {
  pending: "pending",
  accepted: "accepted",
  declined: "declined"
}

export const taskStatus = {
  pending: 'pending',
  submitted: 'submitted',
  completed: 'completed',
  uploaded: 'uploaded'
}

export const SubmissionTypes = {
  url: 'URL',
  pdf: 'PDF',
  excel: 'Excel',
  text: 'Text'
}

export const AppInviteTypes = {
  pending: 'pending',
  declined: 'declined',
  accepted: 'accepted',
}

