
import { menteeApplication } from './../../../mentee-application/pages/mentee-application-dummy';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from '@angular/forms';
import {
  MenteeApplication,
  Program,
} from 'src/app/core/controllers/mentorship.models';
import {
  Component,
  Input,
  OnInit,
  ViewChild,
  TemplateRef,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MenteeApplicationStatus, taskStatus } from 'src/app/core/constants';
import { MentorApplicationController } from 'src/app/core/controllers/mentor-application.controller';
import {
  TableColumnConfig,
  TableConfig,
} from './../../../../core/controllers/mentorship.models';
import { AlertManagement } from 'src/app/core/alert-management';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'lfx-program-review-applications',
  templateUrl: './mentor-mentee-application-review.html',
  styleUrls: ['./mentor-mentee-application-review.scss'],
})
export class MentorMenteeApplicationReviewComponent implements OnInit {
  @ViewChild('prereqNameTemplate') prereqNameTemplate!: TemplateRef<any>;
  @ViewChild('descriptionTemplate') descriptionTemplate!: TemplateRef<any>;
  @ViewChild('manageTemplate') manageTemplate!: TemplateRef<any>;

  activePopover!: NgbPopover;

  //editDecisionForm: any;
  mentee: any = [];
  @Input() applicationDetails = [];

  taskStatus = taskStatus;
  menteeApplicationStatus = MenteeApplicationStatus;
  isLoading = false;
  breadcrumbs = [
    {
      name: 'MENTORSHIPS',
      url: '/',
    },
    {
      name: 'APPLICATION PREREQUISITES',
      url: '',
    },
  ];

  tableConfig: TableConfig = {
    xPadding: '20px',
    colXPadding: '20px',
    columns: new Array<TableColumnConfig>(),
    isLoading: false,
    error: false,
  };

  isTermsAndCondition: boolean = false;
  menteeApplication!: MenteeApplication;
  isApplicationSubmitting = false;
  editDecisionForm = new FormGroup({
    share: new FormControl(false),
    feedback: new FormControl('', Validators.required),
    isAcceptanceRecommended: new FormControl('', Validators.required),
    menteeTermEnrollmentID: new FormControl('', Validators.required),
    mentorID: new FormControl(null),
  });

  public constructor(
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private mentorController: MentorApplicationController,
    private route: Router
  ) {}

  ngOnInit() {
    this.activatedRoute.paramMap.subscribe((params) => {
      let applicationId = params.get('projectId');

      if (applicationId) {
        this.getMenteeApplication(applicationId);
      }
    });
  }

  ngAfterViewInit(): void {
    this.tableConfig.columns = [
      {
        title: 'Prerequisites Name',
        width: '30%',
        template: this.prereqNameTemplate,
      },
      {
        title: 'Description',
        width: '60%',
        template: this.descriptionTemplate,
      },
      {
        title: 'Manage',
        width: '10%',
        template: this.manageTemplate,
      },
    ];
  }

  getMenteeApplication(id: string) {
    this.isLoading = true;
    this.mentorController.getMentorMenteeApplicationById(id).subscribe({
      next: (res) => {
        this.menteeApplication = res;
        this.editDecisionForm.patchValue({
          menteeTermEnrollmentID: this.menteeApplication.id,
        });
        this.mentee = res.mentee;
        this.isLoading = false;
        this.isLoading = false;
      },
      error: (error) => {
        if (error.error?.message) {
          AlertManagement.createAlertDanger(error.error.message);
        } else if (error.error?.error) {
          AlertManagement.createAlertDanger(error.error.error);
        } else {
          AlertManagement.createAlertDanger(error.message);
        }
        this.isLoading = false;
      },
    });
  }

  isUploadAll() {
    return (
      this.menteeApplication.preRequisites.filter(
        (task: any) => task.status === this.taskStatus.uploaded
      ).length === this.menteeApplication.preRequisites.length
    );
  }

  public applicationColumns = [
    { fieldName: 'mentee', label: 'Mentee Name', checkbox: true },

    {
      fieldName: 'applicationDate',
      label: 'Applifed Date',
    },
    {
      fieldName: 'applicationStatus',
      label: 'Application Status',
    },
    {
      fieldName: 'programs',
      label: 'Competing Programs',
    },
    {
      fieldName: 'actions',
      label: '',
    },
  ];

  public programColums = [
    {
      fieldName: 'name',
      label: 'Program Name',
      cssClass: 'px-3 flex-6 text-left',
    },
    {
      fieldName: 'date',
      label: 'Application Date',
      cssClass: 'px-3 flex-2 text-center block',
    },
    {
      fieldName: 'status',
      label: 'Status',
      cssClass: 'px-3 flex-2 text-center block',
    },
  ];
  public applicationRows = [];
  finalDecision = '';
  preRequisite = {
    submission: '',
  };

  updateStatus() {}

  viewFile(preRequisite: any) {
    window.open(preRequisite.submitFile, '_blank')?.focus();
  }

  viewRepository() {}

  openPopover(popover: NgbPopover) {
    this.activePopover = popover;
    if (this.activePopover && !this.activePopover.isOpen()) {
      this.activePopover.open();
    }
  }

  closePopover() {
    if (this.activePopover && this.activePopover.isOpen()) {
      this.activePopover.close();
    }
  }

  mentorReview() {
    const request = this.editDecisionForm.value;
    delete request.share;
    this.mentorController.createMentorReview(request).subscribe({
      next: (_) => {
        AlertManagement.createAlertSuccess(
          `Mentor Review updated successfully!`
        );
        this.route.navigate(['/my-mentorships']);
      },
      error: (error) => {
        AlertManagement.createAlertDanger(error);
      },
    });
  }

  previewProgram() {}

  cancel() {
    this.route.navigate(['/my-mentorships']);
  }

  openLink(url: string) {
    if (!/^(?:f|ht)tps?\:\/\//.test(url)) {
      url = 'https://' + url;
    }
    window.open(url, '_blank');
  }
}
