// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  auth0: {
    domain: 'linuxfoundation-dev.auth0.com',
    clientId: '6HLOUKZwiqYvH0jhi9QMLyY8SrY8l8PA',
    redirectUrl: `${window.location.origin}`,
  },
  lfxHeaderFooter: 'https://cdn.dev.platform.linuxfoundation.org',
  // TODO: Replace with the development URL.
  mentorshipBFF: 'https://lvqu7r2py3.execute-api.us-east-1.amazonaws.com',
  adminBFF: 'https://lfx-acs-ui-api.dev.platform.linuxfoundation.org',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
