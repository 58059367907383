import { PaginationConfig } from './../../../core/controllers/mentorship.models';
import { Utilities } from 'src/app/core/utilities';

import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'lfx-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {

  @Input() paginationConfig = new PaginationConfig();
  @Output() paginationChange = new EventEmitter();
  utilities = new Utilities();
  constructor() { }

  ngOnInit() {
  }

  loadPreviousPage() {
    this.paginationConfig.activePageIndex--;
    this.paginationChange.emit();
  }

  loadNextPage() {
    this.paginationConfig.activePageIndex++;
    this.paginationChange.emit();
  }

  jumpTo() {
    this.paginationConfig.activePageIndex = this.paginationConfig.activePageIndex > this.paginationConfig.numberOfPages ?
      this.paginationConfig.numberOfPages : this.paginationConfig.activePageIndex < 1 ? 1 : this.paginationConfig.activePageIndex;
    this.paginationChange.emit();
  }

}
