<div class="d-flex w-100 flex-wrap overview-header-container">
  <div class="d-flex flex-column col-lg-6 col-sm-12 pl-0">
    <div class="d-flex align-items-center justify-content-between pb-3">
      <div class="d-flex">
        <label class="header-title mb-0">Program: </label>
        <span class="program-name size-18 pl-1">{{ program.name }}</span>
      </div>
    </div>
    <div class="d-flex flex-column">
      <div class="d-flex w-100 pb-2 header-title">Projects</div>
      <div class="d-flex align-items-center w-100 mb-2">
        <div
          class="d-flex justify-content-start pr-3 project-item"
          *ngFor="
            let projectRepository of program.projects | slice: 0:4;
            let last = last
          "
        >
          <span
            *ngIf="!projectRepository.project.logo"
            class="program-name size-14"
            lfxEllipsis
          >
            {{ projectRepository.project.name }}
          </span>
          <img
            *ngIf="projectRepository.project.logo"
            class="project-logo"
            [src]="projectRepository.project.logo"
          />
        </div>
        <div
          *ngIf="program.projects && program.projects.length > 4"
          (mouseleave)="closePopover()"
        >
          <span
            class="more-repo"
            [ngbPopover]="projectRepositoriesViewerTemplate"
            #projectPopover="ngbPopover"
            [autoClose]="false"
            (mouseenter)="openPopover(projectPopover)"
            [placement]="['bottom', 'top']"
            triggers="manual"
            popoverClass="custom-popover"
          >
            {{ "+" + (program.projects.length - 4) }}
          </span>
          <ng-template #projectRepositoriesViewerTemplate>
            <div
              class="selected-items-popover-item weight-400 size-12"
              *ngFor="let projectRepository of program.projects | slice: 4"
              lfxEllipsis
            >
              {{ projectRepository.project.name }}
            </div>
          </ng-template>
        </div>
        <div
          *ngIf="!program.projects.length"
          class="text-black size-14 col-lg-2 col-sm-3 px-0"
        >
          No Project Added
        </div>
      </div>
    </div>
  </div>
  <div class="d-flex col-lg-6 col-sm-12 pr-0">
    <div
      class="d-flex align-items-center justify-content-end px-0"
      [ngClass]="{
        'col-12':
          isMenteeApplicationPage ||
          program.displayStatus !== programStatus.acceptingApplication,
        'col-8':
          !isMenteeApplicationPage &&
          program.displayStatus === programStatus.acceptingApplication
      }"
    >
      <div class="info-card" *ngIf="isMenteeApplicationPage">
        <span class="info-title weight-600 size-24">
          {{ program.terms[0]?.name }}
        </span>
        <span class="info-text weight-600 size-14">{{
          program.terms[0]?.isFullTime ? "Full-Time" : "Part-Time"
        }}</span>
      </div>
      <div class="info-card">
        <span class="info-title weight-600 size-24">
          {{ statistics.remainSpots }}
        </span>
        <span class="info-text weight-600 size-14">Remaining Spots</span>
      </div>
      <div class="info-card">
        <span class="info-title weight-600 size-24 cursor-pointer">{{
          statistics.accepted
        }}</span>
        <span class="info-text weight-600 size-14">Accepted Mentees</span>
      </div>
      <div class="info-card">
        <span class="info-title weight-600 size-24 cursor-pointer">{{
          statistics.application
        }}</span>
        <span class="info-text weight-600 size-14">Number of Applicants</span>
      </div>
    </div>
    <div
      *ngIf="
        !isMenteeApplicationPage &&
        program.displayStatus === programStatus.acceptingApplication
      "
      class="actions d-flex flex-column col-4 pr-0"
    >
      <button
        type="button"
        class="btn btn-primary px-4 py-2 mb-3"
        (click)="applyAsMentee()"
      >
        Apply as Mentee
      </button>
      <button type="button" class="btn btn-primary px-4 py-2 mt-1" disabled>
        Apply as Mentor
      </button>
    </div>
  </div>
</div>
