<ng-container
  *ngIf="tableConfiguration && !tableConfiguration?.isLoading && !tableConfiguration?.error; else loadingTemplate">
  <div class="common-table-container">
    <div class="table-header"
      [ngStyle]="{'padding-left': tableConfiguration.xPadding, 'padding-right': tableConfiguration.xPadding}">
      <span class="table-header-column" lfxEllipsis [ngStyle]="{'minWidth': column.width,'maxWidth': column.width, 'padding-left': (first || (!first && tableConfiguration.columns[index - 1].isSelection)) ? '0' : tableConfiguration.colXPadding,
         'padding-right': (first || last) ? '0' :  tableConfiguration.colXPadding}"
        [ngClass]="{'text-center': column.isCenter}"
        *ngFor="let column of tableConfiguration.columns; let first = first; let last = last; let index = index">
        <ng-container *ngIf="!column.isSelection">
          {{column.title}}
        </ng-container>
        <ng-container *ngIf="column.isSelection">
          <div class="custom-control custom-checkbox">
            <input type="checkbox" class="custom-control-input" id="customCheck1" (change)="toggleAllSelection()"
              [checked]="isAllSelected()">
            <label class="custom-control-label cursor-pointer" for="customCheck1"></label>
          </div>
        </ng-container>
      </span>
    </div>
    <div class="table-body flex-grow-1">
      <ng-container *ngIf="tableRecords?.length; else noDataTemplate">
        <div class="table-row" *ngFor="let row of tableRecords; let rowIndex = index"
          [ngStyle]="{'padding-left': tableConfiguration.xPadding, 'padding-right': tableConfiguration.xPadding}">
          <div class="table-body-column d-flex align-items-start"
            [ngClass]="{'border-unset':  (first && column.isSelection)}" [ngStyle]="{'minWidth': column.width,'maxWidth': column.width,
            'padding-left': (first || (!first && tableConfiguration.columns[index - 1].isSelection)) ? '0' : tableConfiguration.colXPadding,
             'padding-right': (first || last) ? '0' : tableConfiguration.colXPadding}"
            *ngFor="let column of tableConfiguration.columns; let first = first; let last = last; let index =index">
            <ng-container
              *ngTemplateOutlet="column.template; context: {$implicit: {columnConfig: column, rowData: row, rowIndex: rowIndex}}">
            </ng-container>
          </div>
        </div>
      </ng-container>

    </div>
  </div>

</ng-container>
<ng-template #loadingTemplate>
  <lfx-loading></lfx-loading>
</ng-template>
<ng-template #noDataTemplate>
  <div class="size-20 weight-bold text-dark d-flex h-100 w-100 align-items-center justify-content-center">
    No records found
  </div>
</ng-template>
