<div class="program-mentee-application-container">
  <ng-container *ngIf="!isLoading else loadingTemplate">
    <lfx-breadcrumbs [breadcrumbs]="breadcrumbs"></lfx-breadcrumbs>
    <lfx-program-overview-header *ngIf="menteeApplication.program" [program]="menteeApplication.program"
      [isMenteeApplicationPage]="true">
    </lfx-program-overview-header>

    <div class="d-flex card mt-4 mb" id="Prerequisites">
      <div class="card-header d-flex flex-column justify-content-between">
        <span class="text-dark size-20 weight-600">
          Application Prerequisites
        </span>
        <span class="section-subtitle">
          Please complete the prerequisite tasks listed below for your application to be considered for this mentorship
          program.
        </span>
      </div>
      <div class="card-body">
        <lfx-prerequisites [preRequisites]="menteeApplication.preRequisites" [application]="menteeApplication">
        </lfx-prerequisites>
      </div>
    </div>

    <div class="card mt-4 mb" *ngIf="menteeApplication.applicationStatus === menteeApplicationStatus.draft">
      <div class="text-dark size-20 weight-600 my-4 px-4 pb-3 termsAndConditions">Terms and Conditions</div>
      <div class="termsText textColor px-4 mb-4">
        By applying to a mentorship program, you agree that if you are accepted the program may display and share
        portions of your profile information to publicize your participation.
      </div>
      <div class="textCheckbox d-flex px-4 mb-4 align-items-baseline">
        <input type="checkbox" class="mr-2 mt-2" (change)="toggleSelection()" [checked]="isTermsAndCondition">
        <div class="termsText terms-text-color"> <span class="text-danger">*</span>
          I agree to the LFX <a class="linkColor"
            href="https://communitybridge.dev.platform.linuxfoundation.org/platform-use-agreement/"
            target="_blank">Platform Use
            Agreement</a> and all terms incorporated therein, including the <a class="linkColor"
            href="https://communitybridge.dev.platform.linuxfoundation.org/service-terms/"
            target="_blank">Service-Specific Use Terms</a>, the <a class="linkColor"
            href="https://communitybridge.dev.platform.linuxfoundation.org/acceptable-use/" target="_blank">Acceptable
            Use Policy</a> and the <a class="linkColor" href="https://www.linuxfoundation.org/privacy-policy/"
            target="_blank">Privacy
            Policy</a>.
        </div>
      </div>
    </div>

    <div class="action-buttons d-flex pt-3 pb-3 justify-content-end">
      <button class="btn btn-outline-primary custom-btn px-3 mr-2 cancel" [disabled]="isApplicationSubmitting"
        (click)="isApplicationSubmitting ? false: goTo('cancel')">
        <span *ngIf="isApplicationSubmitting">
          <ng-container *ngTemplateOutlet="iconLoading; context: { color: '#0099CC' }"></ng-container>
        </span>
        Cancel
      </button>
      <button class="btn btn-secondary custom-btn px-3 mr-2 save" [disabled]="isApplicationSubmitting"
        *ngIf="menteeApplication.applicationStatus === menteeApplicationStatus.draft"
        (click)="isApplicationSubmitting ? false :goTo('save')">
        <span *ngIf="isApplicationSubmitting">
          <ng-container *ngTemplateOutlet="iconLoading; context: { color: '#0099CC' }"></ng-container>
        </span>
        Save & Close
      </button>
      <button class="btn btn-primary custom-btn px-3 submit"
        *ngIf="menteeApplication.applicationStatus === menteeApplicationStatus.draft"
        [disabled]="!isUploadAll() || !isTermsAndCondition || isApplicationSubmitting"
        (click)="isApplicationSubmitting ? false :goTo('submit')">
        <span *ngIf="isApplicationSubmitting">
          <ng-container *ngTemplateOutlet="iconLoading; context: { color: '#ffffff' }"></ng-container>
        </span>
        Submit Application
      </button>
    </div>
  </ng-container>
</div>

<ng-template #loadingTemplate>
  <lfx-loading></lfx-loading>
</ng-template>

<ng-template #iconLoading let-color="color">
  <div class="spinner-border mr-2" [ngStyle]="{'color': color}"
    style="width: 1.25rem; height: 1.25rem;border-width: 3px;" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</ng-template>
