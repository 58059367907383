import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MenteeApplication, Program } from 'src/app/core/controllers/mentorship.models';
import { MenteeApplicationStatus, taskStatus } from 'src/app/core/constants';
import { MenteeApplicationController } from 'src/app/core/controllers/mentee-application.controller';
import * as moment from 'moment';
import { AlertManagement } from 'src/app/core/alert-management';
@Component({
  selector: 'lfx-mentee-application',
  templateUrl: './mentee-application.component.html',
  styleUrls: ['./mentee-application.component.scss']
})
export class MenteeApplicationComponent implements OnInit {
  taskStatus = taskStatus;
  menteeApplicationStatus = MenteeApplicationStatus;
  isLoading = false;
  breadcrumbs = [
    {
      name: "MENTORSHIPS",
      url: '/'
    },
    {
      name: "APPLICATION PREREQUISITES",
      url: ''
    }
  ];
  isTermsAndCondition: boolean = false;
  menteeApplication!: MenteeApplication;
  isApplicationSubmitting = false;
  constructor(
    private activatedRoute: ActivatedRoute,
    private menteeController: MenteeApplicationController,
    private route: Router,
  ) { }

  ngOnInit() {
    const applicationId = this.activatedRoute.snapshot.paramMap.get('applicationId');
    if (applicationId) {
      this.getMenteeApplication(applicationId!);
    }
  }

  getMenteeApplication(id: string) {
    this.isLoading = true;
    this.menteeController.getMenteeApplicationById(id).subscribe({
      next: (res) => {
        this.menteeApplication = res;
        this.isLoading = false;
      },
      error: (error) => {
        if (error.error?.message) {
          AlertManagement.createAlertDanger(error.error.message);
        } else if (error.error?.error) {
          AlertManagement.createAlertDanger(error.error.error);
        } else {
          AlertManagement.createAlertDanger(error.message);
        }
        this.isLoading = false;
      }
    })
  }

  isUploadAll() {
    return this.menteeApplication.preRequisites.filter((task: any) => task.status === this.taskStatus.uploaded).length === this.menteeApplication.preRequisites.length;
  }

  goTo(action: string) {
    if (action === 'cancel') {
      this.route.navigate(['/my-mentorships']);
    } else if (action === 'save') {
      this.route.navigate(['/my-mentorships']);
    } else if (action === 'submit') {
      if (!this.checkDueDate()) {
        return;
      }
      this.isApplicationSubmitting = true;
      this.menteeController.updatePrerequisitesStatus({
        status: MenteeApplicationStatus.submitted,
        program: {
          programPreRequisites: this.menteeApplication.preRequisites.map(prerequisite => {
            return {
              id: prerequisite.id,
              status: taskStatus.submitted,
              response: prerequisite.submissionValue,
            }
          })
        }
      }, this.menteeApplication.id).subscribe({
        next: () => {
          AlertManagement.createAlertSuccess('Application submitted successfully');
          this.isApplicationSubmitting = false;
          this.route.navigate(['/my-mentorships']);
        },
        error: error => {
          this.isApplicationSubmitting = false;
          if (error.error?.message) {
            AlertManagement.createAlertDanger(error.error.message);
          } else if (error.error?.error) {
            AlertManagement.createAlertDanger(error.error.error);
          } else {
            AlertManagement.createAlertDanger(error.message);
          }
        }
      });
    }
  }

  private checkDueDate() {
    const today = moment().endOf('day');
    const endDate = moment(this.menteeApplication.program.terms[0].applicationAcceptanceEndDate).endOf('day');
    if (endDate.diff(today, 'days') < 0) {
      AlertManagement.createAlertDanger('Can not submit application after application end date');
      return false;
    }
    return true;
  }

  toggleSelection() {
    this.isTermsAndCondition = !this.isTermsAndCondition;
  }
}
