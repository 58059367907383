import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import axios from 'axios';
import { stringify } from 'query-string';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HttpServiceFactory {

   id_token: any;
   API =  environment.auth0.redirectUrl;//process.env.VUE_APP_API;
  constructor(private http: HttpClient, private authService: AuthService) {}


  async  getIDToken() {
  console.log("entered getIDToken in httpservice:");
    const claims = await this.authService.idTokenClaims$.subscribe(token => {
       this.id_token = token && token.__raw;
    });
    
  
    console.log('accessToken',  claims, this.id_token );
  
    return this.id_token ? `Bearer ${this.id_token}` : '';
  }

 LogAxiosError(error: any) {
    console.log('entered LogAxiosError', error );
  
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.log('data ? ', error.response.data);
    } else if (error.request) {
      console.log('error.request', error.request);
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log('Error ? ', error.message);
    }
    // log('error config ? ', error.config);
  }

  async GET(URL: any, data = null, noToken = false, cancelToken: any) {
    console.log('entered GET');

    let route = this.API + URL;

    if (data) {
      const queryParams = stringify(data);
      route = route + `?${queryParams}`;
    }

    /** @type {AxiosRequestConfig}*/
    const request = {
      method: 'get',
      url: route,
      cancelToken,
      headers: {
        'content-type': 'application/json'
      }
    };

    if (!noToken) {
      const token = await this.getIDToken();
      if (token) {
       // request.headers.Authorization = token;
      }
    }
    const currentUser = JSON.parse(sessionStorage.getItem('selectedUser') || '""');

    if (currentUser) {
      //request.headers['x-for-id'] = currentUser.ID;
      //request.headers['x-for-username'] = currentUser.Username;
    }

    //const [err, response] = await axios(request);
    // //var err = '';
    // if (err) {
    //   LogAxiosError(err);
    //  // const error = (err.response && err.response.data) || err.message;
    //   //throw error;
    // }

    // log('response ?', response.data );

    // return response.data;
  }

  // async REQUEST(URL: string, payload: any, token: string, method = 'post', useAPI = true, useHeaders = true, noToken = false) {
  //   console.log('entered REQUEST', { URL, payload, token, method, useAPI, useHeaders });

  //   let route = URL;

  //   if (useAPI) {
  //     route = API + URL;
  //   }

  //   const request = {
  //     method,
  //     url: route,
  //     data: payload
  //   };

  //   if (useHeaders) {
  //     request.headers = {
  //       'content-type': 'application/json'
  //     };
  //   }
  //   if (useHeaders && !noToken) {
  //     request.headers.Authorization = token || (await getIDToken());
  //   }
  //   const currentUser = JSON.parse(sessionStorage.getItem('selectedUser') || '""');

  //   if (currentUser) {
  //     request.headers['x-for-id'] = currentUser.ID;
  //     request.headers['x-for-username'] = currentUser.Username;
  //   }

  //   console.log('request ?', { request });

  //   const [err, response] = await axios(request);

  //   if (err) {
  //     LogAxiosError(err);
  //     const error = (err.response && err.response.data) || err.message;
  //     throw error;
  //   }

  //   console.log(method + ' response', { response: response.data });

  //   return response.data;
  // }

//   async UploadFile(URL, file, useAPI = true) {
//     log('entered UploadFile', { URL, file, useAPI });

//     if (useAPI) {
//       URL = API + URL;
//     }

//     let formData = new FormData();

//     formData.append('file', file);

//     const request = {
//       headers: {
//         Authorization: await getIDToken(),
//         'Content-Type': 'multipart/form-data'
//       }
//     };

//     const [err, response] = await to(axios.post(URL, formData, request));
//     if (err) {
//       LogAxiosError(err);
//       const error = (err.response && err.response.data) || err.message;
//       throw error;
//     }

//     log(' response', { response: response.data });

//     return response.data;
//   }

  async POST(URL: any, payload: any, token: any, useAPI = true, useHeaders = true, noToken = false) {
    //return await this.REQUEST(URL, payload, token, 'post', useAPI, useHeaders, noToken);
  }
//   async PUT(URL, payload, token, useAPI = true, useHeaders = true, noToken = false) {
//     return await this.REQUEST(URL, payload, token, 'put', useAPI, useHeaders, noToken);
//   }
//   async PATCH(URL, payload, token, useAPI = true, useHeaders = true, noToken = false) {
//     return await this.REQUEST(URL, payload, token, 'patch', useAPI, useHeaders, noToken);
//   }
//   async DELETE(URL, payload, token, useAPI = true, useHeaders = true, noToken = false) {
//     return await this.REQUEST(URL, payload, token, 'delete', useAPI, useHeaders, noToken);
//   }
}