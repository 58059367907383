<ng-container *ngIf="preRequisites.length; else noData">
  <div class="common-table-container">
    <div class="table-header grid">
      <span class="table-header-column" [ngClass]="{'text-center': column.isCenter}" lfxEllipsis
        *ngFor="let column of preRequisitesColumns;">
        {{column.title}}
      </span>
    </div>
    <div class="table-body">
      <div class="table-row" *ngFor="let row of preRequisites; let rowIndex = index"
        [ngClass]="{'active': expandedRow === rowIndex }">
        <div class="table-body-column align-items-center grid">
          <div class="column-data d-flex align-items-center">
            <button type="button" class="btn py-1 px-3 mx-1 action-collapsible" (click)="toggleSection(rowIndex)">
              <i class="text-primary fas fa fa-chevron-{{expandedRow === rowIndex ? 'down' : 'right'}}"></i>
            </button>
            <div class="d-flex flex-column flex-grow-1">
              <span class="text-black weight-600">
                {{row.name}}
              </span>
            </div>
          </div>
          <div class="column-data d-flex align-items-center justify-content-center">
            <div class="status mw-100 w-100 d-flex align-items-center justify-content-center" lfxEllipsis [ngClass]="{
                  'pending-status': row.status?.toLowerCase() === taskStatus.pending,
                  'submitted-status': row.status?.toLowerCase() === taskStatus.submitted,
                  'completed-status': row.status?.toLowerCase() === taskStatus.completed,
                  'uploaded-status': row.status?.toLowerCase() === taskStatus.uploaded}">
              {{row.status | titlecase}}
            </div>
          </div>
          <div class="column-data d-flex">
            <span class="text-black d-flex align-items-center justify-content-center w-100 mw-100" lfxEllipsis
              *ngIf="row.dueDate">
              {{row.dueDate | date:'mediumDate'}}
            </span>
          </div>
          <div class="d-flex text-right justify-content-center align-items-center w-100">
            <ng-container *ngIf="row.requiresUpload">
              <div class="upload-file-container d-flex flex-column align-items-center">
                <input [style.display]="'none'" [accept]="acceptedFileExtensions" type="file"
                  name="uploadFile-{{rowIndex}}" id="pre-req-{{rowIndex}}"
                  (change)="handleFiles($event, rowIndex, row.id)" />

                <div class="d-flex">
                  <div *ngIf="uploadLoading[rowIndex]" class="spinner-border text-primary"
                    style="width: 2rem; height: 2rem;" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                  <a *ngIf="!uploadLoading[rowIndex] && row.status !== taskStatus.submitted"
                    class="py-1 px-3 mx-1 ml-2 cursor-pointer" (click)="browseFiles(rowIndex)">
                    <!-- <i class="fa fa-upload size-20" aria-hidden="true"></i> -->
                    Upload
                  </a>
                  <a type="button" target="_blank"
                    *ngIf="!uploadLoading[rowIndex] && row.status != taskStatus.pending && row.submissionValue"
                    class="py-1 px-3 mx-1 cursor-pointer" [href]="row.submissionValue">
                    <!-- <i class="fas fa-binoculars"></i> -->
                    View
                  </a>
                </div>
                <span class="weight-600 size-10 text-light-black">Accepted file types</span>
                <span class="size-10 text-light-black">(.doc, .docx, .pdf, .txt)</span>
              </div>
            </ng-container>
          </div>
        </div>
        <div [id]="'lfx-pre-panel-'+rowIndex" class="max-height"
          [ngClass]="{'active': expandedRow === rowIndex, 'inactive': expandedRow !== rowIndex }">
          <div class="prerequisite-description p-3">
            <span class="text-black">Description:</span>
            <span [innerHTML]="row.description"></span>
            <div *ngIf="row.url">
              <span class="text-black weight-600">Course:</span>
              <a target="_blank" [href]="row.url?.includes('://') ? row.url : '//'+row.url" class="text-primary">
                {{' '+row.url}}
              </a>
            </div>
            <div *ngIf="!row.requiresUpload" class="col-xl-6 col-sm-12 pl-0 mt-3">
              <ng-container
                *ngTemplateOutlet="urlSubmissionTypeTemplate; context: {prerequisite: row, index: rowIndex}">
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #urlSubmissionTypeTemplate let-prerequisite="prerequisite" let-index="index">
  <input type="text" [(ngModel)]="prerequisite.submissionValue" class="form-control text-primary"
    [readonly]="prerequisite.status === taskStatus.submitted" placeholder="https://github.com/patriciacodes.com"
    [ngClass]="{'border-danger': errorIndexList.includes(index)}"
    (keyup.enter)="submitURLPrerequisite(prerequisite.submissionValue, prerequisite.id,index)">
  <small *ngIf="errorIndexList.includes(index)" class="form-text text-danger">Please enter valid URL</small>
  <!-- <div *ngIf="editIndex === index; else submissionText">
  </div>
  <ng-template #submissionText>
    <span class="text-primary">{{prerequisite.submissionValue}}</span>
  </ng-template> -->
</ng-template>

<ng-template #noData>
  <div>No Prerequisites.</div>
</ng-template>
<ng-template #loadingTemplate>
  <lfx-loading></lfx-loading>
</ng-template>
