<div class="playground-layout">
  <lfx-side-menu></lfx-side-menu>
  <div class="content">
    <router-outlet></router-outlet>
  </div>
  <div class="loading-part" *ngIf="(authService.isLoading$ | async)">
    <lfx-loading></lfx-loading>
  </div>
</div>

<div #alertMessagesContainer id="alert-messages-container"></div>
