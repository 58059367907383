import { Injectable } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { environment } from 'src/environments/environment';

function setHeaderScript() {
  const script = document.createElement('script');
  script.setAttribute('src', environment.lfxHeaderFooter + '/lfx-header.js');
  script.setAttribute('async', 'true');

  if (document && document.head) {
    document.head.appendChild(script);
  }

  const header: any = document.getElementById('lfx-header');
  if (header) {
    header.product = 'CB People';
    header.docslink = 'https://docs.linuxfoundation.org/lfx/mentorship';
    header.supportlink = 'https://jira.linuxfoundation.org/servicedesk/customer/portal/4';
    header.faqlink = 'https://docs.linuxfoundation.org/lfx/mentorship/mentorship-faqs';
    header.links = [
      // {
      //     title: 'Enroll a Program',
      //     url: '#',
      // },
      // {
      //     title: 'Become a Mentor',
      //     url: '#',
      // },
      // {
      //     title: 'Become a Mentee',
      //     url: '#',
      // },
    ];
  }
}

function setFooterScript() {
  const script = document.createElement('script');
  script.setAttribute('src', environment.lfxHeaderFooter + '/lfx-footer.js');
  script.setAttribute('async', 'true');

  if (document && document.head) {
    document.head.appendChild(script);
  }
}

setHeaderScript();
setFooterScript();

@Injectable({
  providedIn: 'root',
})
export class LfxHeaderFooterService {
  constructor(private auth: AuthService) {
    this.beforeLogin();
    this.beforeLogout();
    this.setUserInLFxHeader();
    this.setCallBackUrl();
    this.setLogoutUrl();
  }

  // Tweak to fix the bug with claims being null first login success.
  beforeLogin() {
    const lfHeaderEl: any = document.getElementById('lfx-header');
    if (!lfHeaderEl) {
      return;
    }

    lfHeaderEl.beforeLogin = () => {
      console.log('Done! before login redireciton');
      this.auth.isAuthenticated$.subscribe((isAuthenticated: boolean) => {
        if (!isAuthenticated || !localStorage.getItem('token')) {
          this.auth.loginWithRedirect();
        }
      });
    }
  }

  beforeLogout() {
    const lfHeaderEl: any = document.getElementById('lfx-header');
    if (!lfHeaderEl) {
      return;
    }
    lfHeaderEl.beforeLogout = () => {
      localStorage.clear();
    }
  }

  setCallBackUrl() {
    console.log('entered setCallBackUrl');
    const lfHeaderEl: any = document.getElementById('lfx-header');
    if (!lfHeaderEl) {
      return;
    }
    console.log('app setCallBackUrl ', window.location.origin);
    lfHeaderEl.callbackurl = window.location.origin;
  }
  setLogoutUrl() {
    const lfHeaderEl: any = document.getElementById('lfx-header');
    if (!lfHeaderEl) {
      return;
    }
    lfHeaderEl.logouturl = window.location.origin;
  }

  setUserInLFxHeader(): void {
    const lfHeaderEl: any = document.getElementById('lfx-header');
    if (!lfHeaderEl) {
      return;
    }

    this.auth.user$.subscribe((data: any) => {
      if (data) {
        lfHeaderEl.authuser = data;
      }
    });
  }
}
