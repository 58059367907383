import { DatePipe } from "@angular/common";
import { FormArray } from "@angular/forms";

export class Utilities {


  checkNumberInRange(e: any, max: number, min: number) {
    const charCode = typeof e.which === "undefined" ? e.keyCode : e.which;
    const charStr = String.fromCharCode(charCode);
    const currentValue =
      charCode === 8
        ? e.currentTarget.value.substring(0, e.currentTarget.value.length - 1)
        : e.currentTarget.value + charStr;
    // const regex = /^[1-9][0-9]?[0]?$/;
    // const x = regex.test(currentValue);
    const y = charCode === 8 ? true : /[0-9]/.test(charStr);
    if (!y || +currentValue > +max || +currentValue < +min) {
      e.preventDefault();
    }
  }

  checkPasteNumberInRange(e: any, max: number, min: number) {
    const paste = e.clipboardData.getData("text");
    const regex = new RegExp(
      `/^[0-9]{${(min + "").length},${(max + "").length - 1}}[0]?$/`
    );
    // const x = regex.test(currentValue);
    const isValid = regex.test(paste);
    if (!isValid || +paste > +max || +paste < +min) {
      e.preventDefault();
    }
  }

  getNameInitials(admin: any) {
    return admin?.firstName?.charAt(0).toUpperCase() + '' + admin?.lastName?.charAt(0).toUpperCase()
  }

  checkURLFormat(url: string) {
    const URL_REGEXP = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?%^#[\]@!\$&'\(\)\*\+,;=.]+$/;
    return URL_REGEXP.test(url);
  }

  clearFormArray = (formArray: FormArray) => {
    while (formArray.length !== 0) {
      formArray.removeAt(0)
    }
  }

  isDateInRange(from: any, to: any, date: any) {
    from = new Date(from);
    to = new Date(to);
    date = new Date(date);
    return date.getTime() < to.getTime() && date.getTime() > from.getTime();
  }


  static checkUrl(url: string) {
    const URL_REGEXP = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?%^#[\]@!\$&'\(\)\*\+,;=.]+$/;
    return URL_REGEXP.test(url);
  }

  uuidV4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  dateTransform(date: Date | number, datePipe: DatePipe): string {
    return datePipe.transform(date, 'yyyy-MM-dd') as string;
  }

  get organization() {
    return this.uuidV4();
  }
}
