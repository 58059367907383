import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  of,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';
import { ProgramsStatusFilters } from 'src/app/core/constants';
import { Program } from 'src/app/core/controllers/mentorship.models';
import { ProgramService } from 'src/app/core/services/program.service';

@Component({
  selector: 'lfx-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
})
export class SideMenuComponent implements OnInit {
  isMentorshipOpened = false;
  activeUrl = '';
  searchResult = new Array<Program>();
  showSearchResult = false;
  programsStatusFilters = ProgramsStatusFilters;
  isLoading = false;
  searchInput = new FormControl('');
  constructor(private route: Router, private programService: ProgramService) {}

  ngOnInit() {
    this.searchInput.valueChanges
      .pipe(
        debounceTime(600),
        distinctUntilChanged(),
        filter((res) => res.length >= 3 || res.length === 0),
        tap(() => {
          this.isLoading = true;
          this.showSearchResult = false;
        }),
        switchMap((filteredText: any) =>
          this.programService.filterProgram(filteredText).pipe(
            map((page: any) => {
              return page.programs?.length ? page.programs : null;
            }),
            tap(() => {
              this.isLoading = false;
              this.showSearchResult = !!this.searchInput.value.length;
            }),
            catchError((err) => {
              this.isLoading = false;
              return of([]);
            })
          )
        )
      )
      .subscribe((searchResult) => {
        this.searchResult = searchResult;
      });
  }

  changeActiveUrl(url: string) {
    this.activeUrl = url;
    switch (url) {
      case 'mentors':
        this.route.navigateByUrl('mentors');
        break;
      case 'mentees':
        this.route.navigateByUrl('mentees');
        break;
      case 'my-mentorship':
        this.route.navigateByUrl('my-mentorships');
        break;
      case 'all-mentorships':
        this.route.navigateByUrl('all-mentorships');
        break;
      default:
        break;
    }
  }

  changeMentorshipTab(url: string) {
    this.activeUrl = url;
    if (!ProgramsStatusFilters.validList.includes(url)) {
      this.route.navigate([''], {
        queryParams: { tab: ProgramsStatusFilters.approved },
      });
    } else {
      this.route.navigate([''], { queryParams: { tab: url } });
    }
  }

  viewProgram(program: Program) {
    this.route.navigateByUrl(`program/overview/${program.id}`);
    this.showSearchResult = false;
    this.searchResult = [];
    this.searchInput.patchValue('');
  }
}
