
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from './modules/shared/shared.module';
import { ProgramModule } from './modules/program/program.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { MentorModule } from './modules/mentor/mentor.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MenteeApplicationModule } from './modules/mentee-application/mentee-application.module';
import { AuthHttpInterceptor } from './core/interceptor/auth-http-interceptor';
import { AuthModule } from '@auth0/auth0-angular';
import { environment as env } from 'src/environments/environment';
import { ReactiveFormsModule } from '@angular/forms';


@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    ProgramModule,
    MentorModule,
    MenteeApplicationModule,
    SharedModule,
    NgbModule,
    NgSelectModule,
    ReactiveFormsModule,
    AuthModule.forRoot({
      ...env.auth0,
      useRefreshTokens: true,
      scope: 'access:api openid email profile offline_access'
    })
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
