import {
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
  AfterViewInit,
  Input,
  OnDestroy,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AlertManagement } from 'src/app/core/alert-management';
import { ProgramStatus } from 'src/app/core/constants';
import { MentorApplicationController } from 'src/app/core/controllers/mentor-application.controller';
import { ProgramController } from 'src/app/core/controllers/program.controller';
import {
  Program,
  TableColumnConfig,
  TableConfig,
} from 'src/app/core/controllers/mentorship.models';
import { Utilities } from 'src/app/core/utilities';
@Component({
  selector: 'lfx-mentor-invite-application',
  templateUrl: './mentor-invite-application.component.html',
  styleUrls: ['./mentor-invite-application.component.scss'],
})
export class MentorInviteApplicationComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() program!: Program;
  @Input() programId!: string;
  @Input() projectId!: string;

  @ViewChild('termNameTemplate') termNameTemplate!: TemplateRef<any>;
  @ViewChild('textTemplate') textTemplate!: TemplateRef<any>;
  @ViewChild('termTypeTemplate') termTypeTemplate!: TemplateRef<any>;
  @ViewChild('dateTemplate') dateTemplate!: TemplateRef<any>;
  @ViewChild('usernameTemplate') usernameTemplate!: TemplateRef<any>;
  @ViewChild('descriptionTemplate') descriptionTemplate!: TemplateRef<any>;
  @ViewChild('urlTemplate') urlTemplate!: TemplateRef<any>;
  @ViewChild('prerequisitesActionsTemplate')
  utilities = new Utilities();
  programStatus = ProgramStatus;
  config: TableConfig = {
    xPadding: '30px',
    colXPadding: '20px',
    columns: new Array<TableColumnConfig>(),
  };
  mentorsCoAdmins = new Array<any>();
  termConfig!: TableConfig;
  mentorConfig!: TableConfig;
  prerequisitesConfig!: TableConfig;
  isLoading = false;
  isInviteSaving = false;
  destroy$ = new Subject();
  breadcrumbs = [
    {
      name: 'PROGRAMS',
      url: '/',
    },
    {
      name: 'PROGRAM OVERVIEW',
      url: '',
    },
  ];
  isObjectiveExpanded = false;

  constructor(
    private programController: ProgramController,
    private mentorApplicationController: MentorApplicationController,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}
  ngOnInit() {
    // Scroll to top on component load.
    this.scrollToTop();

    this.projectId =
      this.activatedRoute.snapshot.queryParams['projectId'] || '';
    this.programId =
      this.activatedRoute.snapshot.queryParams['programId'] || '';

    this.getProgram(this.programId);

    if (this.program) {
      this.mentorsCoAdmins = [];
      this.program.coAdmins?.forEach((admin) => {
        this.mentorsCoAdmins.push({
          invitationType: 'Co-Administrator',
          ...admin,
        });
      });
      this.program.mentors?.forEach((mentor) => {
        this.mentorsCoAdmins.push({ invitationType: 'Mentor', ...mentor });
      });
    } else {
      this.isLoading = true;
    }
  }

  getProgram(programId: string) {
    this.isLoading = true;
    this.programController
      .getProgramByIdAndProjectId(programId, this.projectId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (program: Program) => {
          this.program = program;
          this.mentorsCoAdmins = [];
          this.program.coAdmins?.forEach((admin) => {
            this.mentorsCoAdmins.push({
              invitationType: 'Co-Administrator',
              ...admin,
            });
          });
          this.program.mentors?.forEach((mentor) => {
            this.mentorsCoAdmins.push({ invitationType: 'Mentor', ...mentor });
          });
          this.isLoading = false;
        },
        error: (_) => {
          this.isLoading = false;
          AlertManagement.createAlertDanger(`Failed to load program.`);
        },
      });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.fillTermsConfig();
      this.fillMentorsConfig();
      this.fillPrerequisitesConfig();
    }, 0);
  }

  private fillTermsConfig() {
    this.termConfig = Object.assign({}, this.config);
    this.termConfig.columns = [
      {
        title: 'Term Type',
        key: 'termType',
        width: '17%',
        template: this.termTypeTemplate,
      },
      {
        title: 'Term Name',
        key: 'name',
        width: '17%',
        template: this.termNameTemplate,
      },
      {
        title: 'Start Date-End Date',
        key: '',
        width: '26%',
        template: this.dateTemplate,
      },
      {
        title: 'Application Acceptance Period',
        key: 'applicationAcceptance',
        width: '26%',
        template: this.dateTemplate,
      },
      {
        title: 'Available Mentee Spots',
        isCenter: true,
        key: 'availableMenteeSpots',
        width: '14%',
        template: this.textTemplate,
      },
    ];
  }

  private fillMentorsConfig() {
    this.mentorConfig = Object.assign({}, this.config);
    this.mentorConfig.columns = [
      {
        title: 'Name',
        key: 'name',
        width: '33%',
        template: this.usernameTemplate,
      },
      {
        title: 'Role',
        key: 'role',
        width: '42%',
        isCenter: true,
        template: this.textTemplate,
      },
      {
        title: 'Invited As',
        key: 'invitationType',
        width: '25%',
        isCenter: true,
        template: this.textTemplate,
      },
    ];
  }
  private fillPrerequisitesConfig() {
    this.prerequisitesConfig = Object.assign({}, this.config);
    this.prerequisitesConfig.columns = [
      {
        title: 'Prerequisites Name',
        key: 'name',
        width: '21%',
        template: this.termNameTemplate,
      },
      {
        title: 'Description',
        key: 'description',
        width: '59%',
        template: this.descriptionTemplate,
      },
      {
        title: 'URL',
        key: 'url',
        width: '20%',
        template: this.urlTemplate,
      },
    ];
  }

  BackToMyMentorship() {
    this.router.navigateByUrl('/my-mentorships');
  }

  openLink(url: string) {
    if (!/^(?:f|ht)tps?\:\/\//.test(url)) {
      url = 'https://' + url;
    }
    window.open(url, '_blank');
  }

  updateMentorApplicationStatus(status: string) {
    this.mentorApplicationController
      .updateInvite(this.programId, status)
      .subscribe({
        next: (_) => {
          AlertManagement.createAlertSuccess(`Invite updated successfully!`);
          this.router.navigate(['/']);
        },
        error: (error) => {
          AlertManagement.createAlertDanger(error.error.message);
        },
      });
  }

  private scrollToTop() {
    window.scrollTo({
      top: 0,
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next('');
    this.destroy$.complete();
    this.destroy$.unsubscribe();
  }
}
