<div class="modal-body">
  <div>
    <div class="side-panel-header-q d-flex justify-content-end px-4 pt-2">
      <a
        href="javascript:void(0)"
        class="closebtn size-24"
        (click)="activeModal.dismiss('Cross click')"
        >×</a
      >
    </div>
    <div class="eligibilityRequirement">
      <div class="mx-3 py-4 eligibilityOptions">
        <h4 class="text-dark ml-3">You have already applied for this Mentorship Program</h4>
      </div>
    </div>
  </div>
</div>