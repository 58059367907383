<div class="dashboard-container">
  <lfx-breadcrumbs [breadcrumbs]="breadcrumbs"></lfx-breadcrumbs>
</div>
<ng-container *ngIf="!isLoading; else loadingTemplate">
  <div class="d-flex flex-column w-100 overview-header-container px-4 pl-f">
    <div
      class="
        d-flex
        align-items-center
        justify-content-between
        custom-padding-x
        pb-3
      "
    >
      <div class="d-flex">
        <label class="header-title">Program: </label>
        <span class="program-name size-18 pl-1"
          >{{ menteeApplication.program.name }}</span
        >
      </div>
    </div>
    <div class="d-flex flex-column custom-padding-x pt-1">
      <div class="d-flex w-100 row pb-3">
        <span class="col-lg-2 col-sm-3 header-title"> Projects </span>
        <span class="col-lg-10 col-sm-9 header-title pl-4"> Repositories </span>
      </div>
      <div
        class="d-flex align-items-center w-100 row mb-2"
        *ngFor="let projectRepository of menteeApplication.program.projects"
      >
        <div class="d-flex justify-content-start col-lg-2 col-sm-3">
          <span
            *ngIf="!projectRepository.project.logo"
            class="program-name size-14"
          >
            {{ projectRepository.project.name }}
          </span>
          <img
            *ngIf="projectRepository.project.logo"
            style="height: 30px"
            [src]="projectRepository.project.logo"
          />
        </div>
        <div class="col-lg-10 col-sm-9 pl-4 d-flex align-items-center">
          <span
            class="repo-card"
            lfxEllipsis
            *ngFor="let repo of projectRepository.repositories | slice: 0:4"
          >
            <!-- <a (click)="openLink(repo)">{{ repo }}</a> -->
          </span>
          <div *ngIf="projectRepository?.repositories?.length; else noRepo">
            <div
              *ngIf="projectRepository?.repositories?.length === 4"
              (mouseleave)="closePopover()"
            >
              <span
                class="more-repo"
                [ngbPopover]="projectRepositoriesViewerTemplate"
                #repositoryPopover="ngbPopover"
                [autoClose]="false"
                (mouseenter)="openPopover(repositoryPopover)"
                [placement]="['bottom', 'top']"
                triggers="manual"
                popoverClass="custom-popover"
              >
                {{ "+" + ("projectRepository.projects.repositories.length - 4)
                }}
              </span>
              <ng-template #projectRepositoriesViewerTemplate>
                <div
                  class="
                    selected-items-popover-item
                    text-primary
                    weight-400
                    size-12
                    cursor-pointer
                    ellipsis
                  "
                  *ngFor="
                  let repository of projectRepository.repositories | slice: 4
                "
                  (click)="viewRepository()"
                >

                  <a>{{ repository }}</a>
                </div>
              </ng-template>
            </div>
          </div>
          <ng-template #noRepo>
            <div class="no-repo size-14">No Repository Added</div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-template #loadingTemplate>
  <lfx-loading></lfx-loading>
</ng-template>

<div class="info-tile">
  <div class="row info-inner-tile">
    <div class="col-sm-12 col-md-1 col-lg-1 d-flex align-items-center">
      <img class="user-image mr-4" [src]=" mentee[0]?.logo_url" alt="" />
    </div>
    <div class="col-sm-12 col-md-4 col-lg-4 col-xl-2">
      <h6 class="title">Name</h6>
      <h3 class="title-value">
        {{mentee[0]?.first_name + ' ' + mentee[0]?.last_name }}
      </h3>
    </div>
    <div class="col-sm-12 col-md-4 col-lg-4 col-xl-1">
      <h6 class="title">Gender</h6>
      <h3 class="title-value">Male</h3>
    </div>
    <div class="col-sm-12 col-md-4 col-lg-4 col-xl-2">
      <h6 class="title">Address</h6>
      <h6 class="title-value-2">Address line 1</h6>
    </div>
    <div class="verticalLine mx-2"></div>
    <div class="col-sm-12 col-md-4 col-lg-4 col-xl-2">
      <h6 class="title">Application Status</h6>
      <h3
        class="title-value"
        [ngClass]="{
      
                'declined-status': menteeApplication.applicationStatus.toLowerCase() === menteeApplicationStatus.declined?.toLowerCase(),
                'accepted-status': menteeApplication.applicationStatus.toLowerCase() === menteeApplicationStatus.accepted?.toLowerCase()
              }"
      >
        {{menteeApplication?.applicationStatus | titlecase}}
      </h3>
    </div>
    <div class="col-sm-12 col-md-4 col-lg-4 col-xl-2">
      <h6 class="title">Program Term</h6>
      <h3 class="title-value">{{menteeApplication.program.terms[0]?.name}}</h3>
    </div>
    <div class="col-sm-12 col-md-4 col-lg-4 col-xl-1">
      <h6 class="title">Term Type</h6>
      <span class="term-type mw-100 w-100 title-value" lfxEllipsis
        >{{ menteeApplication.program.terms[0]?.isFullTime? "Full-Time" :
        "Part-Time" }}</span
      >
    </div>
  </div>
</div>

<div class="row my-4" style="margin-left: 1px">
  <div class="col-sm-12 col-md-8 col-lg-8">
    <div class="Prerequisites my-4">
      <div class="info-tile-header px-4 py-4">Prerequisites</div>
      <app-common-table
        class="h-100"
        [tableConfiguration]="tableConfig"
        [tableRecords]="menteeApplication.preRequisites"
      >
      </app-common-table>

      <ng-template #prereqNameTemplate let-element>
        <span class="text-black weight-600"> {{element.rowData.name}} </span>
      </ng-template>
      <ng-template #descriptionTemplate let-element>
        <div
          class="
            d-flex
            align-items-center
            justify-content-center
            h-100
            w-100
            pl-3
          "
        >
          <ng-container
            *ngIf="
      element.rowData.description.length > 200;
        else elseTemplate
      "
          >
            <app-ellipsis-text
              class="date-text mw-100 w-100"
              [text]="element.rowData.description"
              [minHeight]="'5.5rem'"
            >
            </app-ellipsis-text>
          </ng-container>
          <ng-template #elseTemplate>
            <span class="date-text mw-100 w-100" lfxEllipsis
              >{{ element.rowData.description }}</span
            >
          </ng-template>
        </div>
      </ng-template>
      <ng-template #manageTemplate let-element>
        <span class="weight-600 cursor-pointer text-primary">
          View
          <i class="fa fa-external-link" aria-hidden="true"></i>
        </span>
      </ng-template>
    </div>
  </div>
  <form
    class="col-sm-12 col-md-4 col-lg-4"
    (ngSubmit)="mentorReview()"
    [formGroup]="editDecisionForm"
  >
    <div class="make-decision decisionText">
      <div class="make-decision-tile-header px-4 my-4 py-3">Make Decision</div>
      <div class="acceptDeclineBtn mx-3 mb-3 px-4 py-3 decisionText">
        <span class="make-acceptance-decision">Make Acceptance Decision</span>
        <fieldset>
          <div class="d-flex">
            <label class="container recommendText"
              >Recommended
              <input
                type="radio"
                checked="checked"
                name="isAcceptanceRecommended"
                type="radio"
                id="flexRadioDefault1"
                formControlName="isAcceptanceRecommended"
                [value]="true"
              />
              <span class="checkmark"></span>
            </label>
            <label class="container recommendText"
              >Not Recommended
              <input
                type="radio"
                checked="checked"
                name="isAcceptanceRecommended"
                type="radio"
                id="flexRadioDefault2"
                formControlName="isAcceptanceRecommended"
                [value]="false"
              />
              <span class="checkmark"></span>
            </label>
          </div>
        </fieldset>
        <div class="horizontalLine my-3"></div>
        <div class="reason-tile">
          <div class="reason-title">Reason</div>
          <div class="reason-textarea mt-3">
            <textarea
              class="r-textarea w-100"
              placeholder="Enter Reason"
              formControlName="feedback"
            ></textarea>
          </div>
        </div>
      </div>
      <div class="d-flex mr-4 justify-between">
        <span class="cancel-button my-3 mr-3 d-flex justify-content-end">
          <button class="btn" type="cancel" (click)="cancel()">Cancel</button>
        </span>
        <span class="submitBtn my-3 mr-3 d-flex justify-content-end">
          <button class="btn btn-primary" type="submit">Submit</button>
        </span>
      </div>
    </div>
  </form>
</div>
