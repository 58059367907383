import {
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
  AfterViewInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  OnDestroy,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AlertManagement } from 'src/app/core/alert-management';
import { ProgramStatus } from 'src/app/core/constants';
import { Utilities } from 'src/app/core/utilities';
import { AuthService } from '@auth0/auth0-angular';
import {
  Program,
  TableColumnConfig,
  TableConfig,
} from 'src/app/core/controllers/mentorship.models';
import { MentorApplicationController } from 'src/app/core/controllers/mentor-application.controller';

@Component({
  selector: 'lfx-program-overview',
  templateUrl: './mentor-accept-program-overview.html',
  styleUrls: ['./mentor-accept-program-overview.scss'],
})
export class MentorAcceptProgramOverviewComponent
  implements OnInit, OnChanges, AfterViewInit, OnDestroy
{
  @Input() program!: Program;
  @Input() programId!: string;
  @Input() projectId!: string;
  @Input() isApprovePage: boolean = false;
  @Input() isExistProgram = false;
  @Output() public readonly goHome: EventEmitter<void> =
    new EventEmitter<void>();
  isAdminUser = false;
  isSuperAdmin = false;

  @Output() editProgramTab = new EventEmitter();
  @Output() editExistingProgramTab = new EventEmitter();
  @Output() goBack = new EventEmitter();
  @ViewChild('termNameTemplate') termNameTemplate!: TemplateRef<any>;
  @ViewChild('textTemplate') textTemplate!: TemplateRef<any>;
  @ViewChild('termTypeTemplate') termTypeTemplate!: TemplateRef<any>;
  @ViewChild('dateTemplate') dateTemplate!: TemplateRef<any>;
  @ViewChild('usernameTemplate') usernameTemplate!: TemplateRef<any>;
  @ViewChild('descriptionTemplate') descriptionTemplate!: TemplateRef<any>;
  @ViewChild('urlTemplate') urlTemplate!: TemplateRef<any>;
  @ViewChild('prerequisitesActionsTemplate')
  utilities = new Utilities();
  programStatus = ProgramStatus;
  config: TableConfig = {
    xPadding: '30px',
    colXPadding: '20px',
    columns: new Array<TableColumnConfig>(),
  };
  mentorsCoAdmins = new Array<any>();
  termConfig!: TableConfig;
  mentorConfig!: TableConfig;
  prerequisitesConfig!: TableConfig;
  isLoading = false;
  isProgramSaving = false;
  destroy$ = new Subject();
  public currentTab: number = 1;

  constructor(
    private mentorController: MentorApplicationController,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {}
  ngOnInit() {
    this.activatedRoute.paramMap.subscribe((params) => {
      let projectId = params.get('projectId');
      let programId = params.get('programId');

      if (projectId && programId) {
        this.getProgramById(projectId, programId);
      }
    });
    // Scroll to top on component load.
    this.scrollToTop();
  }

  ngOnChanges(changes: SimpleChanges): void {}

  public handleTab(value: number): void {
    this.currentTab = value;
  }

  getProgramById(programId: string, projectId: string) {
    this.isLoading = true;
    this.mentorController
      .getProgramById(projectId, programId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (program: Program) => {
          this.program = program;
          // this.checkIsProgramAdmin();
          this.mentorsCoAdmins = [];
          this.program.mentors?.forEach((mentor) => {
            this.mentorsCoAdmins.push({ invitationType: 'Mentor', ...mentor });
          });
          this.program.coAdmins?.forEach((admin) => {
            this.mentorsCoAdmins.push({
              invitationType: 'Co-Administrator',
              ...admin,
            });
          });
          this.isLoading = false;
        },
        (error: any) => {
          this.isLoading = false;
          AlertManagement.createAlertDanger(`Failed to load program.`);
        }
      );
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.fillTermsConfig();
      this.fillMentorsConfig();
      this.fillPrerequisitesConfig();
    }, 0);
  }

  private fillTermsConfig() {
    this.termConfig = Object.assign({}, this.config);
    this.termConfig.columns = [
      {
        title: 'Term Type',
        key: 'termType',
        width: '17%',
        template: this.termTypeTemplate,
      },
      {
        title: 'Term Name',
        key: 'name',
        width: '17%',
        template: this.termNameTemplate,
      },
      {
        title: 'Start Date-End Date',
        key: '',
        width: '26%',
        template: this.dateTemplate,
      },
      {
        title: 'Application Acceptance Period',
        key: 'applicationAcceptance',
        width: '26%',
        template: this.dateTemplate,
      },
      {
        title: 'Available Mentee Spots',
        isCenter: true,
        key: 'availableMenteeSpots',
        width: '14%',
        template: this.textTemplate,
      },
    ];
  }

  private fillMentorsConfig() {
    this.mentorConfig = Object.assign({}, this.config);
    this.mentorConfig.columns = [
      {
        title: 'Name',
        key: 'name',
        width: '75%',
        template: this.usernameTemplate,
      },
      {
        title: 'Invited As',
        key: 'invitationType',
        width: '25%',
        isCenter: true,
        template: this.textTemplate,
      },
    ];
  }
  private fillPrerequisitesConfig() {
    this.prerequisitesConfig = Object.assign({}, this.config);
    this.prerequisitesConfig.columns = [
      {
        title: 'Prerequisites Name',
        key: 'name',
        width: '21%',
        template: this.termNameTemplate,
      },
      {
        title: 'Description',
        key: 'description',
        width: '59%',
        template: this.descriptionTemplate,
      },
      {
        title: 'URL',
        key: 'url',
        width: '20%',
        template: this.urlTemplate,
      },
    ];
  }

  BackToHome() {
    this.goBack.emit();
  }

  private isValidProgram() {
    return (
      this.program.requiredSkills.length > 0 &&
      this.program.terms.length > 0 &&
      this.program.programPreRequisites.filter(
        (prerequisite) => prerequisite.isRequired
      ).length > 0 &&
      this.program.terms[0].availableMenteeSpots >= 1
    );
  }

  updateMentorApplicationStatus(status: string) {
    this.mentorController.updateInvite(this.program.id, status).subscribe({
      next: (_) => {
        AlertManagement.createAlertSuccess(`Invite updated successfully!`);
        this.router.navigate(['/my-mentorships']);
      },
      error: (error) => {
        AlertManagement.createAlertDanger(error.error.message);
      },
    });
  }

  updateMentorDecideLater() {
    this.router.navigate(['/my-mentorships']);
  }

  editProgram() {
    this.editProgramTab.emit();
  }

  openLink(url: string) {
    if (!/^(?:f|ht)tps?\:\/\//.test(url)) {
      url = 'https://' + url;
    }
    window.open(url, '_blank');
  }

  private scrollToTop() {
    window.scrollTo({
      top: 0,
    });
  }

  ngOnDestroy(): void {
    this.destroy$.complete();
    this.destroy$.unsubscribe();
  }
}
