import { PaginationConfig } from './../../../../core/controllers/mentorship.models';
import { ProgramOverviewHeaderComponent } from './../../../shared/program-overview-header/program-overview-header.component';
import {
  AfterViewInit,
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { AlertManagement } from 'src/app/core/alert-management';
import { MenteeApplicationStatus } from 'src/app/core/constants';
import {
  TableColumnConfig,
  TableConfig,
} from 'src/app/core/controllers/mentorship.models';
import { MentorApplicationController } from 'src/app/core/controllers/mentor-application.controller';

@Component({
  selector: 'app-mentor-invite-dashboard.',
  templateUrl: './mentor-invite-dashboard.component.html',
  styleUrls: ['./mentor-invite-dashboard.component.scss'],
})
export class MentorInviteDashboardComponent implements OnInit, AfterViewInit {
  @ViewChild('projectNameTemplate') projectNameTemplate!: TemplateRef<any>;
  @ViewChild('programTemplate') programTemplate!: TemplateRef<any>;
  @ViewChild('statusTemplate') statusTemplate!: TemplateRef<any>;
  @ViewChild('actionTemplate') actionTemplate!: TemplateRef<any>;
  @ViewChild('usernameTemplate') usernameTemplate!: TemplateRef<any>;
  @ViewChild('dateTemplate') dateTemplate!: TemplateRef<any>;
  @ViewChild('submitTemplate') submitTemplate!: TemplateRef<any>;

  tableConfig: TableConfig = {
    xPadding: '20px',
    colXPadding: '20px',
    columns: new Array<TableColumnConfig>(),
    isLoading: false,
    error: false,
  };

  applicationsPagination = new PaginationConfig();
  breadcrumbs = [
    {
      name: 'my mentorships',
      url: '',
    },
  ];

  breadcrumbs1 = [
    {
      name: 'Review Assigned Applications',
      url: '',
    },
  ];
  menteeAapplicationsConfig: TableConfig = {
    xPadding: '20px',
    colXPadding: '20px',
    columns: new Array<TableColumnConfig>(),
    isLoading: false,
    error: false,
  };
  menteeApplicationsPagination = new PaginationConfig();

  applications = new Array();
  destroy$ = new Subject();
  menteeApplications = new Array();

  applicationStatus = MenteeApplicationStatus;
  constructor(
    private route: Router,
    private mentorController: MentorApplicationController
  ) {}

  ngOnInit() {
    this.menteeApplicationsPagination.numberOfElementsPerPage = 6;
    this.applicationsPagination.numberOfElementsPerPage = 6;
    this.getMentorApplications();
    this.getMenteeApplicationsForMentor();
  }

  ngAfterViewInit(): void {
    this.tableConfig.columns = [
      {
        title: 'Project',
        width: '33.5%',
        template: this.projectNameTemplate,
      },
      {
        title: 'Program Name',
        width: '54%',
        template: this.programTemplate,
      },
      {
        title: 'Status',
        width: '10.5%',
        template: this.statusTemplate,
        isCenter: false,
        key: 'status',
      },
    ];

    this.menteeAapplicationsConfig.columns = [
      {
        title: 'Mentee Name',
        width: '33.5%',
        template: this.usernameTemplate,
        isCenter: false,
      },
      {
        title: 'Program Name',
        width: '33.5%',
        template: this.actionTemplate,
        isCenter: false,
      },
      {
        title: 'Applied Date',
        width: '10.5%',
        template: this.dateTemplate,
        isCenter: false,
      },
      {
        title: '',
        width: '22%',
        template: this.submitTemplate,
      },
    ];
  }

  previewProgram(programId: string, projectId: string) {
    this.route.navigate(['/mentor/overview', programId, projectId]);
  }

  

  reviewMentee(projectId: string) {
   this.route.navigate(['/mentor/menteeAppReview', projectId]);
  }

  private getMentorApplications() {
    this.tableConfig.isLoading = true;
    var errorMessage = '';
    this.mentorController
      .getMentorApplications()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (applications) => {
          this.applications = applications;
          this.generatePages(this.applications, this.applicationsPagination);

          this.tableConfig.isLoading = false;
        },
        error: (error) => {
          this.applications = [];
          if (error.error?.message) {
            AlertManagement.createAlertDanger(error.error.message);
          } else if (error.error?.error) {
            AlertManagement.createAlertDanger(error.error.error);
          } else {
            AlertManagement.createAlertDanger(error.message);
          }
          this.tableConfig.isLoading = false;
        },
      });
   
  }

  private getMenteeApplicationsForMentor() {
    this.menteeAapplicationsConfig.isLoading = true;
    this.mentorController
      .getMenteeApplicationsForMentor()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (menteeApplications) => {
          this.menteeApplications = menteeApplications;
          this.generatePages(this.menteeApplications, this.menteeApplicationsPagination);
          this.menteeAapplicationsConfig.isLoading = false;
        },
        error: (error) => {
          this.menteeApplications = [];
          if (error.error?.message) {
            AlertManagement.createAlertDanger(error.error.message);
          } else if (error.error?.error) {
            AlertManagement.createAlertDanger(error.message);
          } else {
            AlertManagement.createAlertDanger(error.message);
          }
          this.menteeAapplicationsConfig.isLoading = false;
        },
      });
  }
  private generatePages(itemsList: Array<any>, pagination: PaginationConfig) {
    pagination.numberOfPages = Math.ceil(itemsList.length / pagination.numberOfElementsPerPage);
    pagination.activePageIndex = 1;
    this.updatePagination(itemsList,pagination);
  }

  updatePagination(itemsList: Array<any>,pagination: PaginationConfig) {
    const from = (pagination.activePageIndex - 1) * pagination.numberOfElementsPerPage;
    const to = pagination.activePageIndex * pagination.numberOfElementsPerPage;
    pagination.items = itemsList.slice(from, to > itemsList.length ? undefined : to);
  }

  ngOnDestroy() {
    this.destroy$.next('');
    this.destroy$.complete();
    this.destroy$.unsubscribe();
  }
}
