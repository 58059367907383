import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { ProgramsStatusFilters } from '../constants';
import { ProgramController } from '../controllers/program.controller';

@Injectable({
  providedIn: 'root'
})
export class ProgramService {

  constructor(private programController: ProgramController) {
  }

  filterProgram(filteredText: string) {
    if (!filteredText.length) {
      return of({
        programs: [],
        metaData: {}
      })
    }
    return this.programController.getAllPrograms(5, 0, ProgramsStatusFilters.approved, filteredText);
  }
}
