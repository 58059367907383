import { Subject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpStatusCode } from '@angular/common/http';
import { AuthService } from '@auth0/auth0-angular';
import { HttpServiceFactory } from './http.service';

@Injectable({
    providedIn: 'root'
})
export class LinkedAccountService {
    isLoading: boolean = false;
    items = [];
    loadedIdentities: boolean = false;
    linkOptions = {
          google: 'google-oauth2',
          github: 'github',
          linkedin: 'linkedin',
          facebook: 'facebook'
        };
        id_token: string | undefined | null;
    constructor(private http: HttpClient, private authService: AuthService, private httpService: HttpServiceFactory) {}

   async loginWithPopup(connection: string) {
       let popup, prompt = 'login';
       if(connection == 'github') {
           prompt = 'consent'
       }
    //  var getLoginVal =  await this.authService.loginWithPopup({prompt: 'consent'}, {popup: popup});
    
    await this.authService.loginWithPopup({prompt: 'consent'}, {popup: popup}).subscribe({ error: (e) => {
        const message = e.error_description || e.message;
        const isCancelledError = message.toLowerCase().includes('user cancelled');
          // *info: we user close the popup manually
          const isTimoutError = message.toLowerCase().includes('timeout');
          // * info: sometimes lib isnt loaded yet
          const isUndefinedError = message.toLowerCase().includes('undefined');

          if (isCancelledError || isTimoutError || isUndefinedError) {
                    // * no need to display error on cancel
                    return;
            }
            throw e;

    } , complete: () => {
        console.log("loginWithPopup completed:");
    } });
    //   await this.authService.loginWithPopup({prompt: 'consent'}, {popup: popup}).subscribe(res => {
    //      console.log("loginWithPopup res:", res);
    //  },
    //  (err) => {
    //      if (err && err.toString() === 'Error: Could not open popup') {
    //         throw new Error('Please update your browser preferences to allow pop-up windows to connect.');
    //       }
    //       const message = err.error_description || err.message;

    //       const isCancelledError = message.toLowerCase().includes('user cancelled');
    //       // *info: we user close the popup manually
    //       const isTimoutError = message.toLowerCase().includes('timeout');
    //       // * info: sometimes lib isnt loaded yet
    //       const isUndefinedError = message.toLowerCase().includes('undefined');

    //       if (isCancelledError || isTimoutError || isUndefinedError) {
    //         // * no need to display error on cancel
    //         return;
    //       }
    //       // *info display any other errors
    //       throw err;
    //  });
     await this.authService.idTokenClaims$.subscribe(res => {
        this.id_token = res && res.__raw;
        this.link(this.id_token, 'github');
        return this.id_token;
   },
   (err) => {
       throw err;
   }); 

   this.GetIdentities(true);
   
   }

   async getIDToken() {
    await this.authService.idTokenClaims$.subscribe(res => {
       const id_token = res && res.__raw;
         return id_token;
    }); 
   }

   async GetIdentities(forceLoad: boolean) {
       var response;
    console.log('entered GetIdentities');
    if (this.isLoading) {
      return;
    }
    // if (!this.$user.inPreviewMode && this.loadedIdentities && !forceLoad) {
    if (this.loadedIdentities && !forceLoad) {
      return;
    }

    this.isLoading = true;

    const URL = '/user/linked-accounts';
    // const [err, response] = await this.httpService.GET(URL,null,false,'');
      this.httpService.GET(URL,null,false,'').then(res => {
        response = res;
       // return res.profileData && !!res.profileData.email;
        return response;
       // return res.profileData && !!res.profileData.email;
    }).catch((err) => {
        if (err) {
            this.isLoading = false;
            return;
          }
    });

    this.items = this.items || [];
    // if (!this.$user.inPreviewMode) {
    //   this.loadedIdentities = true;
    // }
    this.isLoading = false;
  }

  async link(targetIdToken: any, connection: string) {
    console.log('entered link and token is:', { targetIdToken });

    const payload = {
      targetIdToken,
      connection
    };

    const URL = '/user/link';
    // const [err, response] = await this.httpService.POST(URL, payload, targetIdToken, true, true, false);
    const response = await this.httpService.POST(URL, payload, targetIdToken, true, true, false).then(res => {
        return res;
    }).catch((err) => {
        if (err) {
          throw err;
        }
    });

    return response;
  }

//   async unlink(identity) {
//     log('entered unlink', { identity });

//     const payload = {
//       identity
//     };

//     const URL = '/user/unlink';
//     const [err, response] = await to(HttpService.POST(URL, payload));

//     log('err, response', { err, response });

//     if (err) {
//       throw err;
//     }

//     return response;
//   }

}