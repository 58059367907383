<div class="program-overview-container">
  <ng-container *ngIf="!isLoading; else loadingTemplate">
    <lfx-breadcrumbs [breadcrumbs]="breadcrumbs"></lfx-breadcrumbs>
    <mentor-program-overview-header
      class="fixed-overview-header"
      [program]="program"
    >
    </mentor-program-overview-header>
    <div class="d-flex mt-3">
      <div class="col-6 pl-0 pr-2">
        <div class="card h-100">
          <div
            class="
              card-header
              text-dark
              size-20
              weight-600
              d-flex
              align-items-center
              justify-content-between
            "
          >
            Description
          </div>
          <div class="card-body">
            {{ program.description }}
          </div>
        </div>
      </div>
      <div class="d-flex col-6 pl-2 pr-0">
        <div class="card px-0 flex-grow-1">
          <div
            class="
              card-header
              text-dark
              size-20
              weight-600
              d-flex
              align-items-center
              justify-content-between
            "
          >
            Learning Objectives and Outcomes
          </div>
          <div
            class="
              card-body
              learn-objectives-cards-container
              d-flex
              flex-column
            "
          >
            <ngb-accordion #accordion="ngbAccordion" [closeOthers]="true">
              <ngb-panel
                *ngFor="
                  let learnObjective of program.learningObjectives;
                  let index = index
                "
                [id]="'learn-objective-dialog-' + index"
              >
                <ng-template ngbPanelHeader let-opened="opened">
                  <button ngbPanelToggle class="normal-button">
                    <span
                      class="
                        learn-objective-header
                        text-dark
                        weight-600
                        size-14
                      "
                      (click)="isObjectiveExpanded = !isObjectiveExpanded"
                      >{{
                        "Learning
                                            Objectives " +
                          (index + 1)
                      }}</span
                    >
                    <i
                      class="pl-3 fa"
                      [ngClass]="{
                        'fa-chevron-up': !isObjectiveExpanded,
                        'fa-chevron-down': isObjectiveExpanded
                      }"
                      aria-hidden="true"
                    ></i>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <ng-container
                    *ngTemplateOutlet="
                      learnObjectiveTemplate;
                      context: { learnObjective: learnObjective }
                    "
                  >
                  </ng-container>
                </ng-template>
              </ngb-panel>
            </ngb-accordion>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex mt-3">
      <div class="col-12 pl-0 pr-2">
        <div class="card h-100" *ngIf="program.requiredSkills.length">
          <div class="card-header text-dark d-flex justify-content-between">
            <span class="size-20 weight-600">
              Required Skills and Technologies
            </span>
          </div>
          <div class="card-body">
            <div class="d-flex align-items-center flex-wrap">
              <div
                class="view-card px-3 py-2 text-light-black size-14 mr-2 mb-2"
                lfxEllipsis
                *ngFor="let skill of program.requiredSkills"
              >
                {{ skill.name }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex card mt-4" *ngIf="program.courses.length">
      <div class="card-header text-dark d-flex justify-content-between">
        <span class="size-20 weight-600">
          Freely provided Linux Foundation courses
        </span>
      </div>
      <div class="card-body">
        <div class="d-flex align-items-center flex-wrap">
          <div
            class="view-card px-3 py-2 text-light-black size-14 mr-2 mb-2"
            lfxEllipsis
            *ngFor="let course of program.courses"
          >
            {{ course.name }}
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex card mt-4">
      <div class="card-header text-dark terms-header d-flex">
        <span class="size-20 weight-600"> Term </span>
      </div>
      <div class="card-body">
        <app-common-table
          [tableConfiguration]="termConfig"
          [tableRecords]="program.terms"
        ></app-common-table>
      </div>
    </div>

    <div
      class="d-flex card mt-4"
      id="Prerequisites"
      *ngIf="program.programPreRequisites.length"
    >
      <div class="card-header text-dark d-flex justify-content-between">
        <span class="size-20 weight-600"> Mentee Prerequisites </span>
      </div>
      <div class="card-body">
        <app-common-table
          [tableConfiguration]="prerequisitesConfig"
          [tableRecords]="program.programPreRequisites"
        >
        </app-common-table>
      </div>
    </div>

    <div
      class="d-flex card mt-4 mb-2"
      id="Mentor"
      *ngIf="mentorsCoAdmins.length"
    >
      <div class="card-header text-dark d-flex justify-content-between">
        <span class="size-20 weight-600"> Mentors & Co-Administrator </span>
      </div>
      <div class="card-body">
        <app-common-table
          [tableConfiguration]="mentorConfig"
          [tableRecords]="mentorsCoAdmins"
        >
        </app-common-table>
      </div>
    </div>
    <div class="d-flex w-100 justify-content-end mt-5 mb-4-2">
      <button
        type="button"
        [disabled]="isInviteSaving"
        class="btn btn-outline-primary custom-btn justify-content-center"
        (click)="BackToMyMentorship()"
      >
        <span *ngIf="isInviteSaving" class="inline">
          <ng-container
            *ngTemplateOutlet="iconLoading; context: { color: '#0099CC' }"
          ></ng-container>
        </span>
        Decide Later
      </button>
      <div class="d-flex ml-auto">
        <button
          type="button"
          class="btn btn-outline-primary custom-btn justify-content-center mr-3"
          [disabled]="isInviteSaving"
          (click)="updateMentorApplicationStatus('declined')"
        >
          <span *ngIf="isInviteSaving" class="inline">
            <ng-container
              *ngTemplateOutlet="iconLoading; context: { color: '#0099CC' }"
            ></ng-container>
          </span>
          Decline Invitation
        </button>
        <button
          type="button"
          class="btn btn-primary custom-btn justify-content-center"
          [disabled]="isInviteSaving"
          (click)="updateMentorApplicationStatus('accepted')"
        >
          <span *ngIf="isInviteSaving" class="inline">
            <ng-container
              *ngTemplateOutlet="iconLoading; context: { color: '#ffffff' }"
            ></ng-container>
          </span>
          Accept Invitation
        </button>
      </div>
    </div>
  </ng-container>
</div>

<ng-template #loadingTemplate>
  <lfx-loading></lfx-loading>
</ng-template>

<ng-template #termNameTemplate let-element>
  <div class="d-flex align-items-center justify-content-center h-100 w-100">
    <span class="term-name mw-100 w-100" lfxEllipsis>
      {{ element.rowData[element.columnConfig.key] }}
    </span>
  </div>
</ng-template>

<ng-template #textTemplate let-element>
  <div class="d-flex align-items-center justify-content-center h-100 w-100">
    <span
      class="text-dark mw-100 w-100"
      [ngClass]="{ 'text-center': element.columnConfig.isCenter }"
      lfxEllipsis
    >
      {{
        element.rowData[element.columnConfig.key]
          ? element.rowData[element.columnConfig.key]
          : "--"
      }}
    </span>
  </div>
</ng-template>

<ng-template #termTypeTemplate let-element>
  <div class="d-flex align-items-center justify-content-center h-100 w-100">
    <span class="term-type mw-100 w-100" lfxEllipsis>{{
      element.rowData.isFullTime ? "Full-Time" : "Part-Time"
    }}</span>
  </div>
</ng-template>

<ng-template #descriptionTemplate let-element>
  <div
    class="d-flex align-items-center justify-content-center h-100 w-100 pl-3"
  >
    <ng-container
      *ngIf="
        element.rowData[element.columnConfig.key].length > 200;
        else elseTemplate
      "
    >
      <app-ellipsis-text
        class="date-text mw-100 w-100"
        [text]="element.rowData[element.columnConfig.key]"
        [minHeight]="'5.5rem'"
      >
      </app-ellipsis-text>
    </ng-container>
    <ng-template #elseTemplate>
      <span class="date-text mw-100 w-100" lfxEllipsis>{{
        element.rowData[element.columnConfig.key]
      }}</span>
    </ng-template>
  </div>
</ng-template>

<ng-template #urlTemplate let-element>
  <div
    class="d-flex align-items-center justify-content-center h-100 w-100 pl-3"
  >
    <a
      *ngIf="element.rowData[element.columnConfig.key]"
      class="cursor-pointer"
      [title]="element.rowData[element.columnConfig.key]"
      (click)="openLink(element.rowData[element.columnConfig.key])"
    >
      <i
        class="fa fa-external-link-alt text-primary size-24"
        aria-hidden="true"
      ></i>
    </a>
    <span
      *ngIf="!element.rowData[element.columnConfig.key]"
      class="date-text mw-100 w-100"
      >-</span
    >
  </div>
</ng-template>

<ng-template #dateTemplate let-element>
  <div class="d-flex align-items-center justify-content-center h-100 w-100">
    <span class="date-text mw-100 w-100" lfxEllipsis>
      {{
        (element.rowData[
          element.columnConfig.key.length
            ? element.columnConfig.key + "StartDate"
            : "startDate"
        ] | date: "mediumDate") +
          " - " +
          (element.rowData[
            element.columnConfig.key.length
              ? element.columnConfig.key + "EndDate"
              : "endDate"
          ] | date: "mediumDate")
      }}
    </span>
  </div>
</ng-template>

<ng-template #usernameTemplate let-element>
  <div class="d-flex align-items-center w-100">
    <img
      class="user-image mr-4"
      [src]="
        element.rowData.logoUrl
          ? element.rowData.logoUrl
          : 'https://lfx-cdn-prod.s3.amazonaws.com/users/avatar/' +
            element.rowData.firstName.toLowerCase().charAt(0) +
            '.png'
      "
      alt=""
    />
    <span class="user-name" lfxEllipsis>{{
      element.rowData["firstName"] + " " + element.rowData["lastName"]
    }}</span>
  </div>
</ng-template>

<ng-template #prerequisitesActionsTemplate let-element>
  <div class="d-flex justify-content-center align-items-center w-100 h-100">
    <button type="button" class="btn btn-outline-primary py-1 px-3">
      View
    </button>
  </div>
</ng-template>

<ng-template #learnObjectiveTemplate let-learnObjective="learnObjective">
  <div class="d-flex flex-column size-14">
    <label class="text-dark weight-600">Title</label>
    <span class="learn-objective-text mb-3">{{ learnObjective.title }}</span>
    <label class="text-dark weight-600">Description</label>
    <span class="learn-objective-text">{{ learnObjective.description }}</span>
  </div>
</ng-template>

<ng-template #iconLoading let-color="color">
  <svg
    class="w-5 h-5 mr-3 -ml-1 animate-spin"
    [ngStyle]="{ color: color }"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
  >
    <circle
      class="opacity-25"
      cx="12"
      cy="12"
      r="10"
      stroke="currentColor"
      stroke-width="4"
    ></circle>
    <path
      class="opacity-75"
      fill="currentColor"
      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
    ></path>
  </svg>
</ng-template>
