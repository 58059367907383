<div class="d-flex flex-column h-100 side-menu-container">
  <div class="position-relative">
    <i class="fa fa-search" aria-hidden="true"></i>
    <input
      type="text"
      class="form-control mb-2 search-input"
      [formControl]="searchInput"
      placeholder="Search Mentorships…"
    />
    <div
      *ngIf="isLoading"
      class="small-spinner spinner-border text-primary"
      role="status"
    >
      <span class="sr-only">Loading...</span>
    </div>
    <div
      class="d-flex flex-column search-result-container"
      *ngIf="showSearchResult"
    >
      <span
        class="dropdown-item size-14"
        *ngFor="let program of searchResult"
        (click)="viewProgram(program)"
      >
        {{ program.name }}
      </span>
    </div>
  </div>
  <div class="accordion-menu mt-1" [ngClass]="{ active: isMentorshipOpened }">
    <span
      class="menu-item header-item"
      (click)="isMentorshipOpened = !isMentorshipOpened"
    >
      Mentorships
      <i
        class="fa"
        [ngClass]="{
          'fa-chevron-right': !isMentorshipOpened,
          'fa-chevron-down': isMentorshipOpened
        }"
        aria-hidden="true"
      ></i>
    </span>
    <div class="d-flex flex-column" *ngIf="isMentorshipOpened">
      <span
        class="menu-item sub-menu-item"
        (click)="changeActiveUrl('all-mentorships')"
        [ngClass]="{ active: activeUrl === programsStatusFilters.approved }"
      >
        All Mentorships
      </span>
      <span
        class="menu-item sub-menu-item"
        (click)="
          changeMentorshipTab(programsStatusFilters.acceptedApplications)
        "
        [ngClass]="{
          active: activeUrl === programsStatusFilters.acceptedApplications
        }"
      >
        Accepting Applications
      </span>
      <span
        class="menu-item sub-menu-item"
        (click)="changeMentorshipTab(programsStatusFilters.inProgress)"
        [ngClass]="{ active: activeUrl === programsStatusFilters.inProgress }"
      >
        In Progress
      </span>
      <span
        class="menu-item sub-menu-item"
        (click)="changeMentorshipTab(programsStatusFilters.completed)"
        [ngClass]="{ active: activeUrl === programsStatusFilters.completed }"
      >
        Completed
      </span>
    </div>
  </div>
  <span
    class="menu-item"
    (click)="changeActiveUrl('mentors')"
    [ngClass]="{ active: activeUrl === 'mentors' }"
    >Mentors</span
  >
  <span
    class="menu-item"
    (click)="changeActiveUrl('mentees')"
    [ngClass]="{ active: activeUrl === 'mentees' }"
    >Mentees</span
  >
  <span
    class="menu-item"
    (click)="changeActiveUrl('my-mentorship')"
    [ngClass]="{ active: activeUrl === 'my-mentorship' }"
    >My Mentorships</span
  >
</div>
