import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MenteeApplicationStatus, taskStatus } from '../constants';
import { MenteeApplication, PreRequisiteTask, Program } from './mentorship.models';

@Injectable({
  providedIn: 'root'
})
export class MenteeApplicationController {
  api = environment.mentorshipBFF;

  constructor(private http: HttpClient) { }

  getAppliedMentee() {
    return this.http.get<any>(`${this.api}/mentorship/me/mentee/applications`);
  }

  getMenteeApplications() {
    return this.http.get<any>(`${this.api}/mentorship/me/mentee/applications`).pipe(map(res => {
      return res.menteeApplications.map((application: any) => {
        const program = application.term.program;
        return {
          id: application.id,
          status: application.status?.text,
          displayedStatus: this.getApplicationDisplayStatus(application.status?.text),
          program: {
            id: program.id,
            name: program.name,
            project: {
              id: program.projects[0].projectID,
              name: program.projects[0].name,
              logo: program.projects[0].logo,
            }
          }
        };
      })
    }));
  }

  getMenteeApplicationById(applicationId: string) {
    return this.http.get<any>(`${this.api}/mentorship/me/mentee/applications/${applicationId}`).pipe(map(res => {
      const backendApplication = res.menteeApplication;
      return this.mapMenteeApplication(backendApplication);
    }));
  }

  createMenteeApplication(reqBody: any) {
    return this.http.post<any>(`${this.api}/mentorship/me/mentor/mentorReview`, reqBody);
  }

  updatePrerequisitesStatus(request: any, applicationId: string) {
    return this.http.patch<any>(`${this.api}/mentorship/me/mentee/applications/${applicationId}`, request);
  }

  deleteApplication(applicationId: string) {
    return this.http.delete<any>(`${this.api}/mentorship/me/mentee/applications/${applicationId}`);
  }

  private mapMenteeApplication(backendApplication: any): MenteeApplication {
    const menteeApplication = new MenteeApplication();
    menteeApplication.id = backendApplication.id;
    menteeApplication.applicationDate = new Date(backendApplication.applicationDate).getTime();
    menteeApplication.applicationStatus = backendApplication.status?.text ? backendApplication.status.text : MenteeApplicationStatus.draft;
    menteeApplication.program = this.mapApplicationProgram(backendApplication);
    menteeApplication.preRequisites = this.mapApplicationPrerequisites(backendApplication.applicationPreRequisiteTasks)
    return menteeApplication;
  }

  private mapApplicationProgram(backendApplication: any) {
    const backendProgram = backendApplication.term.program;
    const backendTerm = backendApplication.term;
    return {
      id: backendProgram.id,
      name: backendProgram.name,
      description: backendProgram.description,
      projects: backendProgram.projects.map((project: any) => {
        return {
          id: project.id,
          project: {
            id: project.projectID,
            name: project.name,
            logo: project.logo,
          },
          repositories: project.repositories,
        };
      }),
      terms: [
        {
          id: backendTerm.id,
          name: backendTerm.name,
          isFullTime: backendTerm.isFullTime,
          applicationAcceptanceStartDate: backendTerm.applicationAcceptanceStartDate,
          applicationAcceptanceEndDate: backendTerm.applicationAcceptanceEndDate,
          startDate: backendTerm.startDate,
          endDate: backendTerm.endDate,
          availableMenteeSpots: 0
        }
      ]
    } as Program;
  }

  private mapApplicationPrerequisites(prerequisites: any[]) {
    return prerequisites.map((backendPrerequisite) => {
      const prerequisite = new PreRequisiteTask();
      prerequisite.id = backendPrerequisite.id;
      prerequisite.name = backendPrerequisite.name;
      prerequisite.description = backendPrerequisite.description;
      prerequisite.isCustom = backendPrerequisite.isCustom;
      prerequisite.isRequired = backendPrerequisite.isRequired;
      prerequisite.url = backendPrerequisite.URL;
      prerequisite.requiresUpload = backendPrerequisite.requiresUpload;
      prerequisite.dueDate = new Date(backendPrerequisite.dueDate);
      prerequisite.submissionValue = backendPrerequisite.submissionValue;
      prerequisite.status = backendPrerequisite.status.text;
      return prerequisite;
    });
  }

  private getApplicationDisplayStatus(backendStatus: string) {
    switch (backendStatus) {
      case MenteeApplicationStatus.draft:
        return 'Prerequisites Pending';
      case MenteeApplicationStatus.submitted:
        return 'Application Submitted';
      case MenteeApplicationStatus.withdrawn:
        return 'Withdrawn';
      case MenteeApplicationStatus.offerSent:
        return 'Offer Sent';
      case MenteeApplicationStatus.accepted:
      case MenteeApplicationStatus.approved:
        return 'In Progress';
      case MenteeApplicationStatus.declined:
        return 'Declined';
      case MenteeApplicationStatus.graduated:
        return 'Graduated';
      default:
        return 'Prerequisites Pending';
    }
  }
}
