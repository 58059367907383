<div class="modal-body">
    <div class="side-panel-header-q d-flex justify-content-between px-4 py-2">
      <span class="size-18">Questionnaire</span>
      <a
        href="javascript:void(0)"
        class="closebtn"
        (click)="activeModal.dismiss('Cross click')"
        >×</a
      >
    </div>
    <div class="eligibilityRequirement">
      <div class="mx-3 pt-4 eligibilityOptions">
        <h4 class="text-dark ml-3">Eligibility Requirements</h4>
        <div class="Q1 mx-3 px-4 py-4 my-4">
          <label class="mt-3"
            >Are you at least 18 years of age, or will you be by the time
            mentorship program starts?</label
          >
          <div class="ml-4" *ngFor="let radiobuttonQ1 of radioItems1">
            <input
              type="radio"
              class="radioBtn"
              name="options1"
              (click)="model1.option = radiobuttonQ1"
              [checked]="radiobuttonQ1 === model1.option"
              (change)="getSelectedValue('Q1')"
            />
            <span class="pl-2">{{ radiobuttonQ1 }}</span>
          </div>
          <div
            class="alert alert-danger mt-3"
            *ngIf="warningMessageQ1"
          >
            {{ warningMessageQ1 }}
          </div>
        </div>
        <div class="Q2 mx-3 px-4 py-4 my-4" *ngIf="showSecondQ">
          <label class="mt-3"
            >Are you eligible to work in the country you reside in for the duration
            of the mentorship?</label
          >
          <div class="ml-4" *ngFor="let radiobuttonQ2 of radioItems2">
            <input
              type="radio"
              class="radioBtn"
              name="options2"
              (click)="model2.option = radiobuttonQ2"
              [checked]="radiobuttonQ2 === model2.option"
              (change)="getSelectedValue('Q2')"
            />
            <span class="pl-2">{{ radiobuttonQ2 }}</span>
          </div>
          <div
            class="alert alert-danger mt-3"
            *ngIf="warningMessageQ2"
          >
            {{ warningMessageQ2 }}
          </div>
        </div>
        <div class="Q3 mx-3 px-4 py-4 my-4" *ngIf="showThirdQ">
          <label class="mt-3"
            >Please select "No" to confirm that you do not have other mentee profile(s) on the LFX Mentorship platform and are not participating in other Linux Foundation mentorship program(s). Doing so will disqualify you from the program.
          </label>
          <div class="ml-4" *ngFor="let radiobuttonQ3 of radioItems3">
            <input
              type="radio"
              class="radioBtn"
              name="options3"
              (click)="model3.option = radiobuttonQ3"
              [checked]="radiobuttonQ3 === model3.option"
              [(ngModel)]="Qs3"
              (change)="getSelectedValue('Q3')"
            />
            <span class="pl-2">{{ radiobuttonQ3 }}</span>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-end mr-2 pb-3">
        <button
          *ngIf="!showSaveNContinue"
          class="cancelButtons"
          (click)="activeModal.dismiss('Cross click')"
        >
          Cancel
        </button>
        <button
          *ngIf="showSaveNContinue"
          class="btn btns btn-primary ml-2"
          (click)="moveToMenteeProfile()"
        >
          Save & Continue
        </button>
      </div>
    </div>
</div>
