<div class="all-programs-container ">
  <ng-container *ngIf="!isLoading && programs!.length > 0; else loadingTemplate">
    <div infinite-scroll [infiniteScrollDistance]="2" (scrolled)="loadMore()">
      <div class="grid-container mb-4 " >
        <lfx-mentorship-details-card *ngFor="let program of programs" [program]="program"
          (viewProgram)="viewProgram(program.id)">
        </lfx-mentorship-details-card>
      </div>
      <lfx-loading *ngIf="isLoadMore" class="flex-grow-1 my-5"></lfx-loading>
    </div>
  </ng-container>
</div>

<ng-template #loadingTemplate>
  <div *ngIf="!isLoading"
    class="size-20 weight-bold d-flex flex-grow-1 w-100 align-items-center justify-content-center mt-4">
    No records found
  </div>
  <lfx-loading *ngIf="isLoading" class="flex-grow-1"></lfx-loading>
</ng-template>
