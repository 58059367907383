import { Component, Input, OnInit } from '@angular/core';
import { AlertManagement } from 'src/app/core/alert-management';
import { MenteeApplicationStatus, taskStatus } from 'src/app/core/constants';
import { MenteeApplicationController } from 'src/app/core/controllers/mentee-application.controller';
import { MenteeApplication, PreRequisiteTask } from 'src/app/core/controllers/mentorship.models';
import { DownloadFileService } from 'src/app/core/services/download-file.service';
import { FileUploadService } from 'src/app/core/services/file-upload.service';
import { Utilities } from 'src/app/core/utilities';

@Component({
  selector: 'lfx-prerequisites',
  templateUrl: './prerequisites.component.html',
  styleUrls: ['./prerequisites.component.scss']
})
export class PrerequisitesComponent implements OnInit {
  @Input() preRequisites!: Array<PreRequisiteTask>;
  @Input() application!: MenteeApplication;
  preRequisitesColumns = [
    {
      title: 'Prerequisite Name',
      isCenter: false,
    },
    {
      title: 'Status',
      isCenter: true,
    },
    {
      title: 'Due Date',
      isCenter: true,
    },
    {
      title: 'Manage',
      isCenter: true,
    }
  ];
  taskStatus = taskStatus;
  menteeApplicationStatus = MenteeApplicationStatus;
  isLoading = false;
  errorIndexList = new Array<number>();
  expandedRow = -1;
  acceptedFileExtensions = '.doc,.docx,.pdf,.txt';
  uploadLoading = new Array<number>();
  constructor(
    private fileUploadService: FileUploadService,
    private menteeController: MenteeApplicationController,
    private downloadService: DownloadFileService
  ) { }

  ngOnInit() {
    this.uploadLoading = new Array<number>(this.preRequisites.length).fill(0);
  }

  toggleSection(index: number) {
    if (this.expandedRow === index) {
      this.expandedRow = -1;
    } else {
      this.expandedRow = index;
    }
  }


  browseFiles(index: number) {
    const elem: any = document.querySelector(`#pre-req-${index}`);
    if (elem) {
      elem.click();
    }
  }

  handleFiles(ev: any, index: number, prerequisiteId: string) {
    if (!ev.target.files || ev.target.files.length !== 1) {
      AlertManagement.createAlertDanger('Please select a file');
      return;
    }
    const file: File = ev.target.files[0];
    const acceptedFilesTypes = this.acceptedFileExtensions.split(',');
    const extension = file.name.substring(file.name.lastIndexOf('.'));
    if (!acceptedFilesTypes.includes(extension)) {
      AlertManagement.createAlertDanger(`The supported extensions are ${this.acceptedFileExtensions} only`);
      return;
    }
    const fileSize = (file.size / 1024 / 1024) // file size in MiB
    if (fileSize > 10) {
      AlertManagement.createAlertDanger(`Can not upload file with size more than 10 MB`);
      return;
    }
    this.uploadLoading[index] = 1;
    this.fileUploadService.uploadFile(ev.target.files[0]).subscribe((url) => {
      this.updateStatus(url, prerequisiteId, index);
      const prerequisite = this.preRequisites[index];
      prerequisite.submissionValue = url;
    });
  }

  updateStatus(url: string, prerequisiteId: string, index: number) {
    const prerequisite = this.preRequisites[index];
    const request = {
      status: this.application.applicationStatus,
      program: {
        programPreRequisites: [
          {
            id: prerequisiteId,
            status: prerequisite.status === taskStatus.submitted ? taskStatus.submitted : taskStatus.uploaded,
            response: url,
          }
        ]
      }
    };
    this.menteeController.updatePrerequisitesStatus(request, this.application.id!).subscribe(() => {
      prerequisite.status = request.program.programPreRequisites[0].status;
      prerequisite.submissionValue = url;
      this.uploadLoading[index] = 0;
    });
  }

  submitURLPrerequisite(url: string, prerequisiteId: string, index: number) {
    const prerequisite = this.preRequisites[index];
    if (prerequisite.status === taskStatus.submitted) {
      return;
    }
    const errorIndex = this.errorIndexList.findIndex(error => error === index);
    if (errorIndex !== -1) {
      this.errorIndexList.splice(errorIndex, 1);
    }
    this.errorIndexList.includes(index) ? this.errorIndexList.push(index) : false
    if (Utilities.checkUrl(url)) {
      this.updateStatus(url, prerequisiteId, index);
    } else {
      !this.errorIndexList.includes(index) ? this.errorIndexList.push(index) : false;
    }
  }

}
