import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgBootstrapModule } from './ng-bootstrap.module';
import { CommonTableComponent } from './common-table/common-table.component';
import { LoadingComponent } from './loading/loading.component';
import { EllipsisDirective } from 'src/app/core/directives/ellipsis.directive';
import { ProgramOverviewHeaderComponent } from './program-overview-header/program-overview-header.component';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { SideMenuComponent } from './side-menu/side-menu.component';
import { MenteeSidePanelComponent } from '../program/components/mentee-side-panel/mentee-side-panel.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { EllipsisTextComponent } from './ellipsis-text/ellipsis-text.component';
import { EllipsisModule } from 'ngx-ellipsis';
import { DialogMenteeAppliedComponent } from '../program/components/dialog-mentee-applied/dialog-mentee-applied.component';
import { MenteeQuestionnaireComponent } from '../program/components/mentee-questionaaire/mentee-questionaaire.component';
import { ThousandSuffixesPipe} from './helpers/thousands.pipe'
import { PaginationComponent } from './pagination/pagination.component';
@NgModule({
  imports: [
    CommonModule,
    NgBootstrapModule,
    RouterModule,
    EllipsisModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    InfiniteScrollModule,
  
  ],
  declarations: [
    CommonTableComponent,
    LoadingComponent,
    EllipsisDirective,
    ProgramOverviewHeaderComponent,
    BreadcrumbsComponent,
    SideMenuComponent,
    MenteeSidePanelComponent,
    EllipsisTextComponent,
    DialogMenteeAppliedComponent,
    MenteeQuestionnaireComponent,
    ThousandSuffixesPipe,
    PaginationComponent
  ],
  exports: [
    NgBootstrapModule,
    FormsModule,
    ReactiveFormsModule,
    InfiniteScrollModule,
    EllipsisModule,
    ThousandSuffixesPipe,
    CommonTableComponent,
    LoadingComponent,
    EllipsisDirective,
    ProgramOverviewHeaderComponent,
    BreadcrumbsComponent,
    SideMenuComponent,
    EllipsisTextComponent,
    PaginationComponent
  ],
  providers: [ThousandSuffixesPipe,]
})
export class SharedModule { }
