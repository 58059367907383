export const skills = [
    {
      name: "3D"
    },
    {
      name: "Ajax"
    },
    {
      name: "Algorithm"
    },
    {
      name: "Amp"
    },
    {
      name: "Android"
    },
    {
      name: "Angular"
    },
    {
      name: "Ansible"
    },
    {
      name: "API"
    },
    {
      name: "Arduino"
    },
    {
      name: "ASP.NET"
    },
    {
      name: "Atom"
    },
    {
      name: "Awesome Lists"
    },
    {
      name: "Amazon Web Services"
    },
    {
      name: "Azure"
    },
    {
      name: "Babel"
    },
    {
      name: "Bash"
    },
    {
      name: "Bitcoin"
    },
    {
      name: "Blockchain"
    },
    {
      name: "Bootstrap"
    },
    {
      name: "Bot"
    },
    {
      name: "C"
    },
    {
      name: "Chrome"
    },
    {
      name: "Chrome extension"
    },
    {
      name: "Command line interface"
    },
    {
      name: "Clojure"
    },
    {
      name: "Code quality"
    },
    {
      name: "Code review"
    },
    {
      name: "Compiler"
    },
    {
      name: "Continuous integration"
    },
    {
      name: "C++"
    },
    {
      name: "Cryptocurrency"
    },
    {
      name: "Crystal"
    },
    {
      name: "C#"
    },
    {
      name: "CSS"
    },
    {
      name: "Data structures"
    },
    {
      name: "Data visualization"
    },
    {
      name: "Database"
    },
    {
      name: "Deep learning"
    },
    {
      name: "Dependency management"
    },
    {
      name: "Deployment"
    },
    {
      name: "Design"
    },
    {
      name: "Django"
    },
    {
      name: "Docker"
    },
    {
      name: "Documentation"
    },
    {
      name: ".NET"
    },
    {
      name: "Electron"
    },
    {
      name: "Elixir"
    },
    {
      name: "Emacs"
    },
    {
      name: "Ember"
    },
    {
      name: "Emoji"
    },
    {
      name: "Emulator"
    },
    {
      name: "ES6"
    },
    {
      name: "ESLint"
    },
    {
      name: "Ethereum"
    },
    {
      name: "Express"
    },
    {
      name: "Firebase"
    },
    {
      name: "Firefox"
    },
    {
      name: "Flask"
    },
    {
      name: "Font"
    },
    {
      name: "Framework"
    },
    {
      name: "Front end"
    },
    {
      name: "Game engine"
    },
    {
      name: "Git"
    },
    {
      name: "GitHub API"
    },
    {
      name: "GO"
    },
    {
      name: "Google"
    },
    {
      name: "Gradle"
    },
    {
      name: "GraphQL"
    },
    {
      name: "Gulp"
    },
    {
      name: "Haskell"
    },
    {
      name: "Homebrew"
    },
    {
      name: "Homebridge"
    },
    {
      name: "HTML"
    },
    {
      name: "HTTP"
    },
    {
      name: "Icon font"
    },
    {
      name: "iOS"
    },
    {
      name: "IPFS"
    },
    {
      name: "Java"
    },
    {
      name: "JavaScript"
    },
    {
      name: "Jekyll"
    },
    {
      name: "jQuery"
    },
    {
      name: "JSON"
    },
    {
      name: "The Julia Language"
    },
    {
      name: "Jupyter Notebook"
    },
    {
      name: "Koa"
    },
    {
      name: "Kotlin"
    },
    {
      name: "Kubernetes"
    },
    {
      name: "Laravel"
    },
    {
      name: "LaTeX"
    },
    {
      name: "Library"
    },
    {
      name: "Linux"
    },
    {
      name: "Localization"
    },
    {
      name: "Lua"
    },
    {
      name: "Machine Learning"
    },
    {
      name: "macOS"
    },
    {
      name: "Markdown"
    },
    {
      name: "Mastodon"
    },
    {
      name: "Material design"
    },
    {
      name: "MATLAB"
    },
    {
      name: "Maven"
    },
    {
      name: "Minecraft"
    },
    {
      name: "Mobile"
    },
    {
      name: "Monero"
    },
    {
      name: "MongoDB"
    },
    {
      name: "Mongoose"
    },
    {
      name: "Monitoring"
    },
    {
      name: "MvvmCross"
    },
    {
      name: "MySQL"
    },
    {
      name: "NativeScript"
    },
    {
      name: "Nim"
    },
    {
      name: "Natural language processing"
    },
    {
      name: "Node.js"
    },
    {
      name: "NoSQL"
    },
    {
      name: "npm"
    },
    {
      name: "Objective-C"
    },
    {
      name: "OpenGL"
    },
    {
      name: "Operating system"
    },
    {
      name: "P2P"
    },
    {
      name: "Package manager"
    },
    {
      name: "Language parsing"
    },
    {
      name: "Perl"
    },
    {
      name: "Perl 6"
    },
    {
      name: "Phaser"
    },
    {
      name: "PHP"
    },
    {
      name: "PICO-8"
    },
    {
      name: "Pixel Art"
    },
    {
      name: "PostgreSQL"
    },
    {
      name: "Project management"
    },
    {
      name: "Publishing"
    },
    {
      name: "PWA"
    },
    {
      name: "Python"
    },
    {
      name: "Qt"
    },
    {
      name: "R"
    },
    {
      name: "Rails"
    },
    {
      name: "Raspberry Pi"
    },
    {
      name: "Ratchet"
    },
    {
      name: "React"
    },
    {
      name: "React Native"
    },
    {
      name: "ReactiveUI"
    },
    {
      name: "Redux"
    },
    {
      name: "REST API"
    },
    {
      name: "Ruby"
    },
    {
      name: "Rust"
    },
    {
      name: "Sass"
    },
    {
      name: "Scala"
    },
    {
      name: "scikit-learn"
    },
    {
      name: "Software-defined networking"
    },
    {
      name: "Security"
    },
    {
      name: "Server"
    },
    {
      name: "Serverless"
    },
    {
      name: "Shell"
    },
    {
      name: "SpaceVim"
    },
    {
      name: "Spring Boot"
    },
    {
      name: "SQL"
    },
    {
      name: "Storybook"
    },
    {
      name: "Support"
    },
    {
      name: "Swift"
    },
    {
      name: "Symfony"
    },
    {
      name: "Telegram"
    },
    {
      name: "Tensorflow"
    },
    {
      name: "Terminal"
    },
    {
      name: "Terraform"
    },
    {
      name: "Testing"
    },
    {
      name: "Twitter"
    },
    {
      name: "TypeScript"
    },
    {
      name: "Ubuntu"
    },
    {
      name: "Unity"
    },
    {
      name: "Unreal Engine"
    },
    {
      name: "Vagrant"
    },
    {
      name: "Vim"
    },
    {
      name: "Virtual reality"
    },
    {
      name: "Vue.js"
    },
    {
      name: "Wagtail"
    },
    {
      name: "Web Components"
    },
    {
      name: "Web app"
    },
    {
      name: "Webpack"
    },
    {
      name: "Windows"
    },
    {
      name: "WordPlate"
    },
    {
      name: "WordPress"
    },
    {
      name: "Xamarin"
    },
    {
      name: "XML"
    }
  ]
  