
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { DatePipe } from '@angular/common';
import { Program } from 'src/app/core/controllers/mentorship.models';
import { MenteeApplicationStatus, MentorInvitationStatus, ProgramStatus } from 'src/app/core/constants';
import { Utilities } from 'src/app/core/utilities';

@Component({
  selector: 'lfx-mentorship-details-card',
  templateUrl: './mentorship-details-card.component.html',
  styleUrls: ['./mentorship-details-card.component.scss']
})
export class MentorshipDetailsCardComponent implements OnInit {

  @Input() program!: Program;
  @Output() viewProgram = new EventEmitter();
  @Output() editProgram = new EventEmitter();
  programMentees = new Array();
  activePopover!: NgbPopover;
  programStatus = ProgramStatus;
  utilities = new Utilities();
  programMentors = new Array();
  today = new Date();
  public datePipe = new DatePipe('en-US');
  constructor() { }

  ngOnInit() {
    if (this.program.mentees) {
      this.programMentees = _.cloneDeep(this.program.mentees.filter((mentee: any) => mentee.applicationStatus === MenteeApplicationStatus.accepted));
    }
    if (this.program.mentors) {
      this.programMentors = _.cloneDeep(this.program.mentors.filter((mentor: any) => mentor.applicationStatus === MentorInvitationStatus.accepted));
    }
    const today = this.utilities.dateTransform(this.today, this.datePipe);
    const startDt = this.utilities.dateTransform(this.program.terms[0].startDate, this.datePipe);
    const endDt = this.utilities.dateTransform(this.program.terms[0].endDate, this.datePipe);
    const appliStartDate = this.utilities.dateTransform(this.program.terms[0].applicationAcceptanceStartDate, this.datePipe);
    const appliEndDate = this.utilities.dateTransform(this.program.terms[0].applicationAcceptanceEndDate, this.datePipe);
    if (this.program.status == 'approved') {
      if (today < appliStartDate) {
        this.program.status = this.programStatus.upcoming;
      } else if ((today >= appliStartDate) && (today <= appliEndDate)) {
        this.program.status = this.programStatus.acceptingApplication;
      } else if ((today >= startDt) && (today <= endDt)) {
        this.program.status = this.programStatus.inprogress;
      } if (today > endDt) {
        this.program.status = this.programStatus.completed;
      }
    }
  }

  navigateToProgramDetail() {
    this.viewProgram.emit();
  }

  editProgramDetail() {
    this.editProgram.emit();
  }

  openPopover(popover: NgbPopover) {
    this.activePopover = popover;
    if (this.activePopover && !this.activePopover.isOpen()) {
      this.activePopover.open();
    }
  }

  closePopover() {
    if (this.activePopover && this.activePopover.isOpen()) {
      this.activePopover.close();
    }
  }

}
