import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "lfx-dialog-mentee-applied",
  templateUrl: "./dialog-mentee-applied.component.html",
  styleUrls: ["./dialog-mentee-applied.component.scss"],
})
export class DialogMenteeAppliedComponent implements OnInit {

  constructor(public activeModal: NgbActiveModal
  ) {}

  ngOnInit(): void {
     
  }
}
