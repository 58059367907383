import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { MentorInviteApplicationComponent } from './pages/mentor-invite-application/mentor-invite-application.component';
import { MentorProgramOverviewHeaderComponent } from './components/program-overview-header/mentor-program-overview-header.component';
import { MentorInviteDashboardComponent } from './pages/mentor-invite-dashboard/mentor-invite-dashboard.component';
// import { ProgramOverviewComponent } from '../program/pages/program-overview/program-overview.component';
import {MentorAcceptProgramOverviewComponent} from '../mentor/pages/mentor-accept-program-overview/mentor-accept-program-overview';
import {MentorMenteeApplicationReviewComponent} from '../mentor/pages/mentor-mentee-application-review/mentor-mentee-application-review';

@NgModule({
  declarations: [
    MentorProgramOverviewHeaderComponent,
    MentorInviteApplicationComponent,
    MentorInviteDashboardComponent,
    MentorAcceptProgramOverviewComponent,
    MentorMenteeApplicationReviewComponent
   
  
  ],
  imports: [CommonModule, SharedModule],
  exports: [],
  providers: [],
})
export class MentorModule {}
