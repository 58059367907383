<div class="program-overview-container">
  <ng-container *ngIf="!isLoading else loadingTemplate">
    <lfx-program-overview-header class="fixed-overview-header" [program]="program" [isMenteeApplicationPage]="false">
    </lfx-program-overview-header>
    <div class="page-title">
      Overview
    </div>
    <div class="d-flex mt-3">
      <div class="col-6 pl-0 pr-2">
        <div class="card h-100">
          <div class="card-header d-flex align-items-center justify-content-between">
            <span class="text-dark size-20 weight-600">
              Description
            </span>
            <div *ngIf="program.projectCodeConductURL?.length" class="code-of-conduct">
              <a [href]="program.projectCodeConductURL?.includes('://') ? program.projectCodeConductURL : '//'+program.projectCodeConductURL"
                target="_blank" rel="noopener noreferrer">
                Code Of Conduct
              </a>
            </div>
          </div>
          <div class="card-body">
            {{program.description}}
          </div>
        </div>
      </div>
      <div class="d-flex col-6 pl-2 pr-0">
        <div class="card px-0 flex-grow-1">
          <div class="card-header text-dark size-20 weight-600 d-flex align-items-center justify-content-between">
            Learning Objectives and Outcomes
          </div>
          <div class="card-body learn-objectives-cards-container d-flex flex-column">
            <ngb-accordion #accordion="ngbAccordion" [closeOthers]="true">
              <ngb-panel *ngFor="let learnObjective of program.learningObjectives; let index = index"
                [id]="'learn-objective-dialog-'+index">
                <ng-template ngbPanelHeader let-opened="opened">
                  <button ngbPanelToggle class="normal-button">
                    <span class="learn-objective-header text-dark weight-600 size-14">{{'Learning Objectives '+(index +
                      1)}}</span>
                    <!-- <lfx-icon-chevron-down class="arrow-icon" [width]="14" [height]="14"
                      [ngClass]="{'rotate-180': accordion.isExpanded('learn-objective-dialog-'+index)}">
                    </lfx-icon-chevron-down> -->
                    <i class="fa fa-chevron-down arrow-icon"
                      [ngClass]="{'rotate-180': accordion.isExpanded('learn-objective-dialog-'+index)}"
                      aria-hidden="true"></i>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <ng-container *ngTemplateOutlet="learnObjectiveTemplate;context:{learnObjective:  learnObjective}">
                  </ng-container>
                </ng-template>
              </ngb-panel>
            </ngb-accordion>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex card mt-4">
      <div class="card-header text-dark terms-header d-flex ">
        <span class="size-20 weight-600">
          Term
        </span>
      </div>
      <div class="card-body">
        <app-common-table [tableConfiguration]="termConfig" [tableRecords]="program.terms"></app-common-table>
      </div>
    </div>
    <div class="d-flex mt-3">
      <div class="col-6 pl-0 pr-2">
        <div class="card h-100" *ngIf="program.requiredSkills.length">
          <div class="card-header text-dark d-flex justify-content-between">
            <span class="size-20 weight-600">
              Required Skills and Technologies
            </span>
          </div>
          <div class="card-body">
            <div class="d-flex align-items-center flex-wrap">
              <div class="view-card px-3 py-2 text-light-black size-14 mr-2 mb-2" lfxEllipsis
                *ngFor="let skill of program.requiredSkills">
                {{skill.name}}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-6 pr-0 pl-2">
        <div class="card h-100" *ngIf="program.requiredSkills.length">
          <div class="card-header text-dark d-flex justify-content-between">
            <span class="size-20 weight-600">
              Collaboration Repositories
            </span>
          </div>
          <div class="card-body d-flex align-items-center flex-wrap">
            <span class="repo-card" lfxEllipsis *ngFor="let repo of programProjectsRepositories"
              (click)="viewRepository(repo)">
              <a>
                {{repo}}
              </a>
            </span>
            <div *ngIf="!programProjectsRepositories.length" class="text-black size-14 px-0">
              No Repositories Added
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex card mt-4" *ngIf="program.courses.length">
      <div class="card-header text-dark d-flex justify-content-between">
        <span class="size-20 weight-600">
          Freely provided Linux Foundation courses
        </span>
      </div>
      <div class="card-body">
        <div class="d-flex align-items-center flex-wrap">
          <div class="view-card px-3 py-2 text-light-black size-14 mr-2 mb-2" lfxEllipsis
            *ngFor="let course of program.courses">
            {{course.name}}
          </div>
        </div>
      </div>
    </div>


    <div class="d-flex card mt-4" id="Prerequisites" *ngIf="program.programPreRequisites.length">
      <div class="card-header text-dark d-flex justify-content-between">
        <span class="size-20 weight-600">
          Application Prerequisites
        </span>
      </div>
      <div class="card-body">
        <app-common-table [tableConfiguration]="prerequisitesConfig" [tableRecords]="program.programPreRequisites">
        </app-common-table>
      </div>
    </div>

    <div class="d-flex card mt-4 mb-2" id="Mentor" *ngIf="programMentors?.length">
      <div class="card-header text-dark d-flex justify-content-between">
        <span class="size-20 weight-600">
          Mentors
        </span>
      </div>
      <div class="card-body">
        <app-common-table [tableConfiguration]="mentorConfig" [tableRecords]="programMentors"></app-common-table>
      </div>
    </div>

    <div class="d-flex card mt-4 mb-2" id="Mentor" *ngIf="acceptedMentees?.length">
      <div class="card-header text-dark d-flex justify-content-between">
        <span class="size-20 weight-600">
          Accepted Mentees
        </span>
      </div>
      <div class="card-body">
        <app-common-table [tableConfiguration]="acceptedConfig" [tableRecords]="acceptedMentees"></app-common-table>
      </div>
    </div>

    <div class="d-flex card mt-4 mb-4-2" id="Mentor" *ngIf="program.coAdmins?.length">
      <div class="card-header text-dark d-flex justify-content-between">
        <span class="size-20 weight-600">
          Administrators
        </span>
      </div>
      <div class="card-body">
        <app-common-table [tableConfiguration]="adminConfig" [tableRecords]="program.coAdmins"></app-common-table>
      </div>
    </div>
  </ng-container>
</div>

<ng-template #loadingTemplate>
  <lfx-loading class="flex-grow-1"></lfx-loading>
</ng-template>

<ng-template #termNameTemplate let-element>
  <div class="d-flex align-items-center justify-content-center h-100 w-100">
    <span class="term-name mw-100 w-100" lfxEllipsis>
      {{element.rowData[element.columnConfig.key]}}
    </span>
  </div>
</ng-template>

<ng-template #textTemplate let-element>
  <div class="d-flex align-items-center justify-content-center h-100 w-100">
    <span class="text-dark mw-100 w-100" [ngClass]="{'text-center': element.columnConfig.isCenter}" lfxEllipsis>
      {{element.rowData[element.columnConfig.key] ? element.rowData[element.columnConfig.key] : '--'}}
    </span>
  </div>
</ng-template>

<ng-template #termTypeTemplate let-element>
  <div class="d-flex align-items-center justify-content-center h-100 w-100">
    <span class="term-type mw-100 w-100" lfxEllipsis>{{element.rowData.isFullTime ? 'Full-Time':'Part-Time'}}</span>
  </div>
</ng-template>

<ng-template #descriptionTemplate let-element>
  <div class="d-flex align-items-center justify-content-center h-100 w-100 pl-3">
    <ng-container *ngIf="element.rowData[element.columnConfig.key].length > 200; else elseTemplate">
      <app-ellipsis-text class="date-text mw-100 w-100" [text]="element.rowData[element.columnConfig.key]"
        [minHeight]="'5.5rem'">
      </app-ellipsis-text>
    </ng-container>
    <ng-template #elseTemplate>
      <span class="date-text mw-100 w-100" lfxEllipsis>{{element.rowData[element.columnConfig.key]}}</span>
    </ng-template>

  </div>
</ng-template>

<ng-template #dateTemplate let-element>
  <div class="d-flex align-items-center justify-content-center h-100 w-100">
    <span class="date-text mw-100 w-100" lfxEllipsis>
      {{(element.rowData[element.columnConfig.key.length ?element.columnConfig.key+'StartDate' : 'startDate'] |
      date:'mediumDate') +
      ' - ' +
      (element.rowData[element.columnConfig.key.length ? element.columnConfig.key+'EndDate': 'endDate'] | date:
      'mediumDate')}}
    </span>
  </div>
</ng-template>

<ng-template #usernameTemplate let-element>
  <div class="d-flex align-items-center w-100 h-100">
    <img class="user-image mr-4"
      [src]="element.rowData.logoUrl ? element.rowData.logoUrl :'https://lfx-cdn-prod.s3.amazonaws.com/users/avatar/'+element.rowData.firstName.toLowerCase().charAt(0)+'.png'"
      alt="">
    <span class="user-name" lfxEllipsis>{{element.rowData['firstName'] + ' ' + element.rowData['lastName']}}</span>
  </div>
</ng-template>

<ng-template #tagsTemplate let-element>
  <div class="d-flex align-items-center w-100 flex-wrap">
    <span class="tag" lfxEllipsis *ngFor="let tag of element.rowData[element.columnConfig.key]">
      {{tag}}
    </span>
  </div>
</ng-template>


<ng-template #learnObjectiveTemplate let-learnObjective="learnObjective">
  <div class="d-flex flex-column size-14">
    <label class="text-dark weight-600">Title</label>
    <span class="learn-objective-text mb-3">{{learnObjective.title}}</span>
    <label class="text-dark weight-600">Description</label>
    <span class="learn-objective-text">{{learnObjective.description}}</span>
  </div>
</ng-template>

<ng-template #iconLoading let-color="color">
  <svg class="w-5 h-5 mr-3 -ml-1 animate-spin" [ngStyle]="{'color': color}" xmlns="http://www.w3.org/2000/svg"
    fill="none" viewBox="0 0 24 24">
    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
    <path class="opacity-75" fill="currentColor"
      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
    </path>
  </svg>
</ng-template>
