import { Component, Input, OnInit } from '@angular/core';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { Program } from 'src/app/core/controllers/mentorship.models';

@Component({
  selector: 'mentor-program-overview-header',
  templateUrl: './mentor-program-overview-header.component.html',
  styleUrls: ['./mentor-program-overview-header.component.scss']
})
export class MentorProgramOverviewHeaderComponent implements OnInit {
  @Input() program!: Program;
  activePopover!: NgbPopover;
  constructor() { }

  ngOnInit() {
  }

  openPopover(popover: NgbPopover) {
    this.activePopover = popover;
    if (this.activePopover && !this.activePopover.isOpen()) {
      this.activePopover.open();
    }
  }

  closePopover() {
    if (this.activePopover && this.activePopover.isOpen()) {
      this.activePopover.close();
    }
  }

  openLink(url: string) {
    if (!/^(?:f|ht)tps?\:\/\//.test(url)) {
      url = "https://" + url;
    }
    window.open(url, "_blank");
  }
}
