import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { AuthService } from '@auth0/auth0-angular';

@Injectable()
export class AuthHttpInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (this.checkExceptionURLs(request)) {
      return next.handle(request);
    } else if (this.isTokenExpired()) {
      localStorage.clear();
      return this.authService.idTokenClaims$.pipe(
        mergeMap((claims: any) => {
          if (claims) {
            localStorage.setItem('token', claims.__raw);
            const expDate = new Date();
            expDate.setHours(expDate.getHours() + 6);
            localStorage.setItem('exp', expDate.getTime() + '');
            const tokenReq = request.clone({
              setHeaders: { Authorization: `Bearer ${claims.__raw}` },
            });
            return next.handle(tokenReq);
          } else {
            return next.handle(request);
          }
        })
      );
    } else {
      const token = localStorage.getItem('token');
      const tokenReq = request.clone({
        setHeaders: { Authorization: `Bearer ${token}` },
      });
      return next.handle(tokenReq);
    }
  }

  isTokenExpired() {
    const date = localStorage.getItem('exp');
    if (date) {
      return new Date().getTime() > +date;
    }
    return true;
  }

  checkExceptionURLs(request: HttpRequest<any>) {
    return (
      request.url.includes('s3.amazonaws.com') ||
      request.url.includes('sso.linuxfoundation.org') ||
      request.url.includes('linuxfoundation-dev.auth0.com')
    );
  }
}
