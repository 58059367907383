import { AfterViewInit, Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { AlertManagement } from './core/alert-management';
import { LfxHeaderFooterService } from './core/services/lfx-header-footer.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {
  title = 'frontend';
  isLoading = false;
  headerHeight = 120;
  constructor(
    private route: Router,
    private lfxHeaderFooterService: LfxHeaderFooterService,
    public authService: AuthService) {
    this.route.events.subscribe(event => {
      if ((event instanceof NavigationEnd)) {
        window.scrollTo(0, 0);
      }
    })
  }

  ngAfterViewInit(): void {
    AlertManagement.parentNode = document.getElementById('alert-messages-container') as any;

  }

  navigateToOverview() {
    this.route.navigate(['program/overview/1']);
  }

}
