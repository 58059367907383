import { TemplateRef } from '@angular/core';


export class Program {
  public id!: string;
  public name!: string;
  public budget!: number;
  public minProjectSpend!: number;
  public maxProjectSpend!: number;
  public paymentFrequency!: string;
  public status!: string;
  public displayStatus!: string;
  public description!: string;
  public courses!: { id?: string, name: string }[];
  public projects!: ProjectRepository[];
  public requiredSkills!: { id?: string, name: string }[];
  public learningObjectives!: LearningObjective[];
  public terms!: Term[];
  public mentors!: Mentor[];
  public mentees: any[];
  public programPreRequisites!: PreRequisiteTask[];
  public coAdmins?: CoAdmin[];
  public projectCodeConductURL?: string;
  public createdBy?: string;
  public createdAt?: string;
  constructor() {
    this.mentees = [];
    this.mentors = [];
  }
}

export class ProjectRepository {
  project!: Project;
  repositories?: string[];
  constructor() { }
}

export class Project {
  id!: string;
  name!: string;
  logo?: string;
  constructor() { }
}

export class Term {
  id!: string;
  name!: string;
  startDate!: number | Date; // epoch
  endDate!: number | Date;
  applicationAcceptanceStartDate!: number | Date;
  applicationAcceptanceEndDate!: number | Date;
  availableMenteeSpots!: number;
  isFullTime!: boolean;
  constructor() { }
}

export class LearningObjective {
  title!: string;
  description!: string;
  constructor() { }
}

export interface CoAdmin {
  id?: string;
  firstName: string;
  lastName: string;
  name?: string;
  email: string;
  role?: string;
  logoUrl?: string;
}

export class PreRequisiteTask {
  id!: string;
  name!: string;
  description!: string;
  dueDate?: Date | number;
  url?: string;
  requiresUpload?: boolean;
  submissionValue?: string;
  isRequired!: boolean;
  isCustom?: boolean;
  status?: string;
}

export interface Task {
  taskName: String;
  description: String;
  status: String;
  taskType: String;
  menteeComments: String;
  mentorFeedBack: String;
}


export interface Mentor {
  id: string;
  firstName: string;
  lastName: string;
  name?: string;
  email: string;
  role?: string;
  imageUrl?: string;
  programs?: Program;
  mentees?: Mentee;
  invitationStatus: string;
  companyName?: string;
  githubHandle?: string;
  emailMessage: string;
  dateAccepted?: number;
}

export interface Mentee {
  id?: string;
  firstName: string;
  lastName: string;
  name?: string;
  email: string;
  role?: string;
  imageUrl?: string;
  menteeApplications?: MenteeApplication[];
  mentors?: Mentor[];
  status: string;
  companyName?: string;
  githubHandle?: string;
  emailMessage: string;
  userID: string;
  dateAccepted?: number;
}

export interface MentorReview {
  mentorID: string;
  reviewStatus: string;
  reviewComments: string;
}

export class MenteeApplication {
  id!: string;
  program!: Program;
  applicationStatus!: string;
  applicationDate!: number;
  preRequisites!: PreRequisiteTask[];
  dateAssigned?: number;
  mentorReviews?: MentorReview[];
  mentee?: Mentee;
  constructor() {
    this.program = new Program();
    this.preRequisites = new Array<PreRequisiteTask>();
    this.mentorReviews = new Array<MentorReview>();
  }
}

export class TableColumnConfig {
  title!: string;
  width!: string;
  template!: TemplateRef<any>;
  isCenter?: boolean; // make table column header in center
  isSelection?: boolean; // make table column header as checkbox
  [property: string]: any; // this line mean that you can add multi attributes more than the mandatory fields

  constructor() { }
}

export class TableConfig {
  xPadding: string = '30px';
  colXPadding: string = '20px';
  columns: TableColumnConfig[] = new Array<TableColumnConfig>();
  isLoading?: boolean = false;
  error?: boolean = false;

  constructor() { }
}

export class PaginationConfig {
  numberOfPages: number;
  numberOfElementsPerPage: number;
  activePageIndex: number;
  items: any[];

  constructor() {
    this.numberOfPages = 1;
    this.numberOfElementsPerPage = 12;
    this.activePageIndex = 1;
    this.items = new Array();
  }
}


export class MentorReview {
  id!: string;
  mentorID!: string;
  menteeTermEnrollmentID!: string;
  isAcceptanceRecommended!: boolean;
  feedback!: string;
  status!: string;

  constructor() {

  }
}




