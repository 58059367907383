<div class="dashboard-container">
  <lfx-breadcrumbs [breadcrumbs]="breadcrumbs"></lfx-breadcrumbs>
  <div class="table-container flex-grow-1 mb-5">
    <app-common-table
      class="h-100"
      [tableConfiguration]="tableConfig"
      [tableRecords]="applicationsPagination.items"
    >
    </app-common-table>
    <lfx-pagination *ngIf="applicationsPagination.items.length"
    [paginationConfig]="applicationsPagination"
    (paginationChange)="
      updatePagination(applications, applicationsPagination)
    "
  ></lfx-pagination>
  </div>
  <lfx-breadcrumbs
    [breadcrumbs]="breadcrumbs1"
    style="text-transform: none"
  ></lfx-breadcrumbs>
  <div class="table-container flex-grow-1 mb-5">
    <app-common-table
      class="h-100"
      [tableConfiguration]="menteeAapplicationsConfig"
      [tableRecords]="menteeApplicationsPagination.items"
    >
    </app-common-table>
    <lfx-pagination *ngIf="menteeApplicationsPagination.items.length"
      [paginationConfig]="menteeApplicationsPagination"
      (paginationChange)="
        updatePagination(menteeApplications, menteeApplicationsPagination)
      "
    ></lfx-pagination>
  </div>
</div>

<ng-template #projectNameTemplate let-elementment>
  <span class="text-black weight-600">
    {{ elementment.rowData.program?.project?.name }}
  </span>
</ng-template>

<ng-template #programTemplate let-elementment>
  <span
    class="
      d-flex
      align-items-center
      w-100
      mw-100
      cursor-pointer
      size-16
      text-primary
    "
    (click)="
      previewProgram(
        elementment.rowData.program.id,
        elementment.rowData.program?.project?.id
      )
    "
  >
    {{ elementment.rowData.program?.name }}
  </span>
  <span class=".w-100">
    <button
      class="statusapp px-2 py-1 ellipsis accept-status status"
      [ngClass]="{
        pending: elementment.rowData.acceptanceStatus === 'Awaiting Decision',
        denied: elementment.rowData.acceptanceStatus === 'Declined'
      }"
    >
      {{ elementment.rowData.acceptanceStatus }}
    </button>
  </span>
</ng-template>

<ng-template #statusTemplate let-elementment>
  <span class="text-black weight-500" lfxEllipsis>
    {{ elementment.rowData.displayedStatus | titlecase }}
  </span>
</ng-template>

<!-- ******************************* Mentee table -->
<ng-template #usernameTemplate let-element1>
  <span class="text-black weight-600">
    {{ element1.rowData.menteeName }}
  </span>
</ng-template>

<ng-template #actionTemplate let-element>
  <span
    class="
      d-flex
      align-items-center
      w-100
      mw-100
      cursor-pointer
      size-16
      text-primary
    "
  >
    {{ element.rowData.programName }}
  </span>
</ng-template>

<ng-template #dateTemplate let-element>
  <span class="text-black weight-600">
    {{ element.rowData.applicationDate | date: "MM/dd/yy" }}
  </span>
</ng-template>

<ng-template #submitTemplate let-element>
  <span class="text-black weight-600 reviewbutton">
    <button class="btn reviewtext" (click)="reviewMentee(element.rowData.id)">
      Review Application
    </button>
  </span>
</ng-template>
