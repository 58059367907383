<div class="d-flex flex-column w-100 overview-header-container">
  <div class="d-flex align-items-center justify-content-between pb-3">
    <div class="d-flex">
      <label class="header-title">Program: </label>
      <span class="program-name size-18 pl-1">{{program.name}}</span>
    </div>
  </div>
  <div class="d-flex flex-column pt-1">
    <div class="d-flex w-100 pb-3">
      <span class="col-lg-2 col-sm-3 header-title px-0">
        Projects
      </span>
      <span class="col-lg-10 col-sm-9 header-title pl-4 pr-0">
        Repositories
      </span>
    </div>
    <ng-container *ngIf="program.projects.length; else noProjectOrRepository">
      <div class="d-flex align-items-center w-100 mb-2" *ngFor="let projectRepository of program.projects">
        <div class="d-flex justify-content-start col-lg-2 col-sm-3 px-0"
          *ngIf="projectRepository.project?.name else noProjectTemplate">
          <span *ngIf="!projectRepository.project.logo" class=" program-name size-14">
            {{projectRepository.project.name}}
          </span>
          <img *ngIf="projectRepository.project.logo" style="height: 30px" [src]="projectRepository.project.logo">
        </div>
        <div class="col-lg-10 col-sm-9 pl-4 d-flex align-items-center">
          <span class="repo-card" lfxEllipsis *ngFor="let repo of projectRepository.repositories | slice : 0:4">
            <a (click)="openLink(repo)">{{repo}}</a>
          </span>
          <div *ngIf="projectRepository.repositories?.length ; else noRepo">
            <div *ngIf="projectRepository.repositories && projectRepository.repositories.length > 4"
              (mouseleave)="closePopover()">
              <span class="more-repo" [ngbPopover]="projectRepositoriesViewerTemplate" #repositoryPopover="ngbPopover"
                [autoClose]="false" (mouseenter)="openPopover(repositoryPopover)" [placement]="['bottom', 'top']"
                triggers="manual" popoverClass="custom-popover">
                {{'+' + (projectRepository.repositories.length - 4)}}
              </span>
              <ng-template #projectRepositoriesViewerTemplate>
                <div class="selected-items-popover-item text-primary weight-400 size-12 cursor-pointer ellipsis"
                  *ngFor="let repository of projectRepository.repositories | slice: 4">
                  <a (click)="openLink(repository)">{{repository}}</a>
                </div>
              </ng-template>
            </div>
          </div>
          <ng-template #noRepo>
            <div class="no-repo size-14">
              No Repository Added
            </div>
          </ng-template>
          <ng-template #noProjectTemplate>
            <div class="no-repo size-14">
              No Project Added
            </div>
          </ng-template>
        </div>
      </div>
    </ng-container>
    <ng-template #noProjectOrRepository>
      <div class="d-flex">
        <div class="no-repo size-14 col-lg-2 col-sm-3 px-0">
          No Project Added
        </div>
        <div class="no-repo size-14 col-lg-10 col-sm-9 pl-4">
          No Repositories Added
        </div>
      </div>
    </ng-template>
  </div>
</div>