import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { MenteeSidePanelComponent } from "../mentee-side-panel/mentee-side-panel.component";

@Component({
  selector: "lfx-mentee-questionaaire",
  templateUrl: "./mentee-questionaaire.component.html",
  styleUrls: ["./mentee-questionaaire.component.scss"],
})
export class MenteeQuestionnaireComponent implements OnInit {
  @Output() closePanel = new EventEmitter();
  @Input() programID!: string;
  editMenteeForm = new FormGroup({});
  radioItems1 = ["Yes", "No"];
  radioItems2 = ["Yes", "No"];
  radioItems3 = ["No"];
  model1 = { option: "" };
  model2 = { option: "" };
  model3 = { option: "" };
  warningMessageQ1: string = "";
  warningMessageQ2: string = "";
  showSecondQ: boolean = false;
  showThirdQ: boolean = false;
  showSaveNContinue: boolean = false;
  Qs3 = null;

  constructor(
    public activeModal: NgbActiveModal,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void { }

  closesideNav() {
    let closePanel = true;
    this.closePanel.emit(closePanel);
  }

  getSelectedValue(question: string) {
    if (question == "Q1") {
      if (this.model1.option == "No") {
        this.warningMessageQ1 =
          "You're not eligible to participate in the Linux Foundation Mentorship programs at this time.";
        this.showSecondQ = false;
        this.showThirdQ = false;
        this.showSaveNContinue = false;
      } else {
        this.warningMessageQ1 = "";
        this.warningMessageQ2 = "";
        this.showSecondQ = true;
        this.showThirdQ = false;
      }
      this.model2.option = '';
      this.Qs3 = null;
    } else if (question == "Q2") {
      this.showSecondQ = true;
      if (this.model2.option == "No") {
        this.warningMessageQ2 =
          "You're not eligible to participate in the Linux Foundation Mentorship programs at this time.";
        this.showThirdQ = false;
        this.showSaveNContinue = false;
        this.Qs3 = null;
      } else {
        this.warningMessageQ2 = "";
        this.showThirdQ = true;
      }
    } else if (question == "Q3") {
      if (this.model2.option) {
        this.showSaveNContinue = true;
      }
    }
  }

  moveToMenteeProfile() {
    const dialogRef = this.modalService.open(MenteeSidePanelComponent, {
      size: "lg",
      centered: true,
    });
    const instance = dialogRef.componentInstance;
    instance.programID = this.programID;
    this.activeModal.dismiss();
  }

}
