import { AfterViewInit, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { AlertManagement } from 'src/app/core/alert-management';
import { MenteeApplicationStatus, ProgramStatus } from 'src/app/core/constants';
import { MenteeApplicationController } from 'src/app/core/controllers/mentee-application.controller';
import { TableColumnConfig, TableConfig } from 'src/app/core/controllers/mentorship.models';

@Component({
  selector: 'app-mentee-application-dashboard',
  templateUrl: './mentee-application-dashboard.component.html',
  styleUrls: ['./mentee-application-dashboard.component.scss']
})
export class MenteeApplicationDashboardComponent implements OnInit, AfterViewInit {

  @ViewChild('projectNameTemplate') projectNameTemplate!: TemplateRef<any>;
  @ViewChild('programTemplate') programTemplate!: TemplateRef<any>;
  @ViewChild('statusTemplate') statusTemplate!: TemplateRef<any>;
  @ViewChild('actionTemplate') actionTemplate!: TemplateRef<any>;

  tableConfig: TableConfig = {
    xPadding: '20px',
    colXPadding: '20px',
    columns: new Array<TableColumnConfig>(),
    isLoading: false,
    error: false
  }
  breadcrumbs = [{
    name: "my mentorships",
    url: ""
  }];
  applications = new Array();
  destroy$ = new Subject();

  applicationStatus = MenteeApplicationStatus;
  constructor(
    private route: Router,
    private menteeController: MenteeApplicationController
  ) { }

  ngOnInit() {
    this.getMenteeApplications();
  }

  ngAfterViewInit(): void {
    //Called after ngOnInit when the component's or directive's content has been initialized.
    //Add 'implements AfterContentInit' to the class.
    this.tableConfig.columns = [
      {
        title: 'Project',
        width: '23.5%',
        template: this.projectNameTemplate
      },
      {
        title: 'Program Name',
        width: '42%',
        template: this.programTemplate,
      },
      {
        title: 'Application Status',
        width: '22.5%',
        template: this.statusTemplate,
        isCenter: false,
        key: 'status'
      },
      {
        title: 'Manage',
        width: '12%',
        template: this.actionTemplate,
        isCenter: true,
      }
    ];
  }

  private getMenteeApplications() {
    this.tableConfig.isLoading = true;
    this.menteeController.getMenteeApplications().pipe(takeUntil(this.destroy$)).subscribe(
      {
        next: applications => {
          this.applications = applications;
          this.tableConfig.isLoading = false;
        },
        error: error => {
          this.applications = [];
          if (error.error?.message) {
            AlertManagement.createAlertDanger(error.error.message);
          } else if (error.error?.error) {
            AlertManagement.createAlertDanger(error.error.error);
          } else {
            AlertManagement.createAlertDanger(error.message);
          }
          this.tableConfig.isLoading = false;
        }
      });
  }

  previewProgram(programID: string) {
    this.route.navigate(['/program/overview', programID]);
  }

  navigateToMenteeApplication(application: any) {
    this.route.navigate([`program/${application.program.id}/application/${application.id}`]);
  }

  withdrawApplication(application: any) {
    if (application.status === MenteeApplicationStatus.draft) {
      // delete application
      this.menteeController.deleteApplication(application.id).subscribe({
        next: res => {
          AlertManagement.createAlertSuccess('Application is withdrawn successfully');
          this.getMenteeApplications();
        },
        error: error => {
          if (error.error?.message) {
            AlertManagement.createAlertDanger(error.error.message);
          } else if (error.error?.error) {
            AlertManagement.createAlertDanger(error.error.error);
          } else {
            AlertManagement.createAlertDanger(error.message);
          }
        }
      });
    } else {
      // withdraw application
      const request = {
        status: MenteeApplicationStatus.withdrawn
      };
      this.menteeController.updatePrerequisitesStatus(request, application.id).subscribe({
        next: res => {
          AlertManagement.createAlertSuccess('Application is withdrawn successfully');
          application.status = application.displayedStatus = MenteeApplicationStatus.withdrawn;
        },
        error: error => {
          if (error.error?.message) {
            AlertManagement.createAlertDanger(error.error.message);
          } else if (error.error?.error) {
            AlertManagement.createAlertDanger(error.error.error);
          } else {
            AlertManagement.createAlertDanger(error.message);
          }
        }
      });
    }
  }

  ngOnDestroy() {
    this.destroy$.next('');
    this.destroy$.complete();
    this.destroy$.unsubscribe();
  }
}
