<div class="mentorship-card-container card">
  <div class="program-card-header d-flex">
    <div class="status inprogress-status px-2 py-1" [ngClass]="{
        'draft-status':
          program.displayStatus?.toLowerCase() === programStatus.draft.toLowerCase(),
          'pending-status':
          program.displayStatus?.toLowerCase() === programStatus.pending.toLowerCase(),
          'declined-status':
          program.displayStatus?.toLowerCase() === programStatus.declined.toLowerCase(),
        'inprogress-status':
          program.displayStatus?.toLowerCase() ===
          programStatus.inprogress.toLowerCase(),
        'accept-status':
          program.displayStatus?.toLowerCase() ===
          programStatus.acceptingApplication.toLowerCase(),
        'upcoming-status':
          program.displayStatus?.toLowerCase() ===
          programStatus.upcoming.toLowerCase(),
        'completed-status':
          program.displayStatus?.toLowerCase() ===
          programStatus.completed.toLowerCase()
      }" lfxEllipsis>
      {{
      program.displayStatus?.toLowerCase() === programStatus.pending.toLowerCase()
      ? "Pending Approval"
      : "" ||
      program.displayStatus?.toLowerCase() ===
      programStatus.completed.toLowerCase()
      ? "Completed"
      : "" ||
      program.displayStatus?.toLowerCase() === programStatus.draft.toLowerCase()
      ? "Draft"
      : "" ||
      program.displayStatus?.toLowerCase() === programStatus.inprogress.toLowerCase()
      ? "In Progress"
      : "" ||
      program.displayStatus?.toLowerCase() === programStatus.acceptingApplication.toLowerCase()
      ? "Accepting Applications"
      : "" ||
      program.displayStatus?.toLowerCase() === programStatus.upcoming.toLowerCase()
      ? "Upcoming"
      : "" || program.displayStatus?.toLowerCase() === programStatus.declined.toLowerCase()
      ? "Declined" : "" }}
    </div>
    <i class="fa fa-share-alt size-20 " aria-hidden="true"></i>
  </div>
  <div class="mentorship-card-progName padding-x-20">
    <div *ngIf="program.projects?.length; else noProject">
      <div class="program-name d-flex align-items-center justify-content-center" *ngIf="program.projects?.length == 1">
        <span *ngIf="!program.projects[0]?.project?.logo">
          {{ program.projects[0]?.project?.name }}
        </span>
        <img *ngIf="program.projects[0]?.project?.logo" style="height: 30px;"
          [src]="program.projects[0]?.project?.logo">
      </div>
      <div class="program-name d-flex align-items-center justify-content-center" *ngIf="program.projects.length > 1">
        <div class="projName" lfxEllipsis>
          <span *ngIf="!program.projects[0]?.project?.logo">
            {{ program.projects[0]?.project?.name }}
          </span>
          <img *ngIf="program.projects[0]?.project?.logo" [src]="program.projects[0]?.project?.logo">
        </div>
        <span class="px-1">|</span>
        <div class="projName">
          <span *ngIf="!program.projects[1]?.project?.logo" lfxEllipsis>
            {{ program.projects[1]?.project?.name }}
          </span>
          <img *ngIf="program.projects[1]?.project?.logo" [src]="program.projects[1]?.project?.logo">
        </div>
        <div *ngIf="program.projects && program.projects.length > 2" class="icon-default" (mouseleave)="closePopover()">
          <span class="px-1">|</span>
          <span [ngbPopover]="projectLists" #projectList="ngbPopover" (mouseenter)="openPopover(projectList)"
            [placement]="['bottom', 'top']" triggers="manual" popoverClass="custom-popover" class="cursor-default">
            +{{ program.projects.length - 2 }}
          </span>
        </div>
      </div>
    </div>
    <ng-template #noProject>
      <div class="parentProjectLogo">
      </div>
    </ng-template>
    <div class="project-name my-2">{{ program?.name }}</div>
    <div class="term-name" *ngIf="program.terms.length; else noTerms">{{ program.terms[0].name }} |
      <span>{{program.terms[0].isFullTime === true ? 'Full-Time' : 'Part-Time'}}</span>
    </div>
    <ng-template #noTerms>
      <div class="term-name">
        <span>No Term Selected</span>
      </div>
    </ng-template>
  </div>
  <div class="program-mentor-body mentor-body margin-y-20 padding-x-20">
    <div class="mentor-title mb-1">Mentors</div>
    <div class="icons-container-mentors" *ngIf="programMentors.length; else noMentor">
      <ng-container *ngFor="let mentor of programMentors | slice: 0:3">
        <img class="icon" [src]="
            mentor.imageUrl
              ? mentor.imageUrl
              : 'https://lfx-cdn-prod.s3.amazonaws.com/users/avatar/' +
                mentor.firstName.toLowerCase().charAt(0) +
                '.png'
          " [title]="mentor.firstName + ' ' + mentor.lastName" />
      </ng-container>
      <div *ngIf="programMentors && programMentors.length > 3" class="icon-default" (mouseleave)="closePopover()">
        <span [ngbPopover]="mentorListViewerTemplate" #mentorList="ngbPopover" (mouseenter)="openPopover(mentorList)"
          [placement]="['bottom', 'top']" triggers="manual" popoverClass="custom-popover" class="cursor-default">
          +{{ programMentors.length - 3 }}
        </span>
      </div>
    </div>
    <ng-template #noMentor>
      <div class="no-items-found">
        Mentors have been invited
      </div>
    </ng-template>
  </div>
  <div class="program-mentee-body mentee-body padding-x-20">
    <div class="mentee-title mb-1">Mentees</div>
    <div class="icons-container" *ngIf="programMentees.length; else noMentee">
      <img *ngFor="let mentee of programMentees | slice: 0:3" class="icon" [src]="
          mentee.imageUrl
            ? mentee.imageUrl
            : 'https://lfx-cdn-prod.s3.amazonaws.com/users/avatar/' +
              mentee.firstName.toLowerCase().charAt(0) +
              '.png'
        " [title]="mentee.firstName + ' ' + mentee.lastName" />
      <div *ngIf="programMentees.length > 3" class="icon-default" (mouseleave)="closePopover()">
        <span [ngbPopover]="menteeListViewerTemplate" #menteeList="ngbPopover" (mouseenter)="openPopover(menteeList)"
          [placement]="['bottom', 'top']" triggers="manual" popoverClass="custom-popover" class="cursor-default">
          +{{ programMentees.length - 3 }}
        </span>
      </div>
    </div>
    <ng-template #noMentee>
      <div class="no-items-found">
        No Mentees Selected
      </div>
    </ng-template>
  </div>
  <div class="program-skills-body padding-x-20 skills-body margin-y-20">
    <div class="skills-title">Required Skills And Technologies</div>
    <div class="d-flex justify-content-center mt-2" *ngIf="program.requiredSkills.length; else noSkills">
      <div class="skill mr-2" lfxEllipsis *ngFor="let skill of program.requiredSkills | slice: 0:3; index as i">
        {{ skill.name }}
      </div>
      <small class="skill" *ngIf="program?.requiredSkills && program.requiredSkills.length > 3"
        (mouseleave)="closePopover()">
        <span [ngbPopover]="listViewerTemplate" #skillsList="ngbPopover" (mouseenter)="openPopover(skillsList)"
          [placement]="['bottom', 'top']" triggers="manual" popoverClass="custom-popover">
          +{{ program.requiredSkills.length - 3 }}
        </span>
      </small>
    </div>
    <ng-template #noSkills>
      <div class="no-items-found mt-3 mb-2">
        No Skills Selected
      </div>
    </ng-template>
  </div>
  <div class="line"></div>
  <div class="program-details padding-x-20 d-flex align-items-center justify-content-center"
    (click)="navigateToProgramDetail()">
    PROGRAM DETAILS
  </div>
</div>

<ng-template #projectLists>
  <div class="selected-items-popover-item" lfxEllipsis *ngFor="let project of program.projects | slice: 2">
    {{ project.project?.name }}
  </div>
</ng-template>

<ng-template #menteeListViewerTemplate>
  <div class="selected-items-popover-item" lfxEllipsis *ngFor="let mentee of programMentees | slice: 3">
    {{ mentee.firstName + " " + mentee.lastName }}
  </div>
</ng-template>

<ng-template #mentorListViewerTemplate>
  <div class="selected-items-popover-item" lfxEllipsis *ngFor="let mentor of program.mentors | slice: 3">
    {{ mentor.firstName + " " + mentor.lastName }}
  </div>
</ng-template>

<ng-template #listViewerTemplate>
  <div class="selected-items-popover-item" lfxEllipsis *ngFor="let skill of program.requiredSkills | slice: 3">
    {{ skill.name }}
  </div>
</ng-template>
