import { menteeApplication } from './../../modules/mentee-application/pages/mentee-application-dummy';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs';
import { environment as env } from 'src/environments/environment';
import { environment } from 'src/environments/environment';

import {
  PreRequisiteTask,
  Program,
  ProjectRepository,
  MenteeApplication,
  Term,
  Mentee,
} from './mentorship.models';
import {
  MenteeApplicationStatus,
  ProgramsStatusFilters,
  AppInviteTypes,
  MAX_MENTEE_BUDGET,
  MIN_MENTEE_BUDGET,
  ProgramStatus,

} from '../constants';
@Injectable({
  providedIn: 'root',
})
export class MentorApplicationController {
  api = environment.mentorshipBFF;
  constructor(private http: HttpClient) {}

  updateInvite(programId: string, status: string) {
    return this.http.patch(
      `${env.mentorshipBFF}/mentorship/me/mentor/invites`,
      { programID: programId, status: status }
    );
  }

  getMentorApplications() {
    return this.http
      .get<any>(`${this.api}/mentorship/me/mentor/applications`)
      .pipe(
        map((res) => {
          return res.mentorApplications.map((application: any) => {
            const program = application.program;
            return {
              id: application.id,
              acceptanceStatus: this.getApplicationInviteStatus(
                application.acceptanceStatus
              ),
              status: application.program.status?.text,
              displayedStatus: this.getApplicationDisplayStatus(
                application.program.status?.text
              ),
              program: {
                id: program.id,
                name: program.name,
                project: {
                  id: program.projects[0].projectID,
                  name: program.projects[0].name,
                  logo: program.projects[0].logo,
                },
              },
            };
          });
        })
      );
  }

  getMenteeApplicationsForMentor() {
    return this.http
      .get<any>(`${this.api}/mentorship/me/mentor/mentee/menteeApplications`)
      .pipe(
        map((res) => {
          return res.menteeApplications.map((menteeAapplications: any) => {
            const program = menteeAapplications.term.program;
            return {
              id: menteeAapplications.id,
              menteeName: menteeAapplications.mentee.fullName,
              menteelogo: menteeAapplications.mentee.logoUrl,
              userLFID: menteeAapplications.mentee.userLFID,
              programName: program.name,
              applicationDate: menteeAapplications.applicationDate,
              applicationSatus: menteeAapplications.status?.text,
            };
          });
        })
      );
  }

  getMentorMenteeApplicationById(applicationId: string){
    return this.http.get<any>(`${this.api}/mentorship/me/mentor/mentee/application/${applicationId}`)
    .pipe(
      map(res => {
      const menteeReviewApplication = res.menteeApplication;
      return this.mapManteeApplication(menteeReviewApplication)

    }));
  };


  getProgramById(programId: string, projectId: string) {
    return this.http
      .get<any>(
        `${this.api}/mentorship/me/mentor/projects/${projectId}/programs/${programId}`
      )
      .pipe(map(this.mapProgramToFrontendModel));
  }

  updateProgramStatus(programId: string, projectId: string, status: string) {
    return this.http.patch(
      `${this.api}/mentorshipadmin/projects/${projectId}/programs/${programId}/status`,
      {
        status,
      }
    );
  };

  createMentorReview(mentorRev: any){
    console.log(mentorRev);
  return this.http.post<any>(`${this.api}/mentorship/me/mentor/mentorReview`, mentorRev)
  }


  private mapManteeApplication(menteeReviewApplication: any): MenteeApplication {
   const menteeApplication = new MenteeApplication();
    menteeApplication.mentee= menteeReviewApplication.mentee; 
    menteeApplication.id = menteeReviewApplication.id;
    menteeApplication.applicationDate = new Date(menteeReviewApplication.applicationDate).getTime();
    menteeApplication.applicationStatus = menteeReviewApplication.status?.text ? menteeReviewApplication.status.text : MenteeApplicationStatus.draft;
    menteeApplication.program = this.mapApplicationProgram(menteeReviewApplication);
    menteeApplication.preRequisites = this.mapApplicationPrerequisites(menteeReviewApplication.applicationPreRequisiteTasks)
    return menteeApplication;
  }

  private mapApplicationProgram(backendApplication: any) {
    const backendProgram = backendApplication.term.program;
    const backendTerm = backendApplication.term;
    return {
      id: backendProgram.id,
      name: backendProgram.name,
      description: backendProgram.description,
      projects: backendProgram.projects.map((project: any) => {
        return {
          id: project.id,
          project: {
            id: project.projectID,
            name: project.name,
            logo: project.logo,
          },
          repositories: project.repositories,
        };
      }),
      terms: [
        {
          id: backendTerm.id,
          name: backendTerm.name,
          isFullTime: backendTerm.isFullTime,
          applicationAcceptanceStartDate: backendTerm.applicationAcceptanceStartDate,
          applicationAcceptanceEndDate: backendTerm.applicationAcceptanceEndDate,
          startDate: backendTerm.startDate,
          endDate: backendTerm.endDate,
          availableMenteeSpots: 0
        }
      ]
    } as Program;
  }


  private mapApplicationPrerequisites(prerequisites: any[]) {
    return prerequisites.map((backendPrerequisite) => {
      const prerequisite = new PreRequisiteTask();
      prerequisite.id = backendPrerequisite.id;
      prerequisite.name = backendPrerequisite.name;
      prerequisite.description = backendPrerequisite.description;
      prerequisite.isCustom = backendPrerequisite.isCustom;
      prerequisite.isRequired = backendPrerequisite.isRequired;
      prerequisite.url = backendPrerequisite.URL;
      prerequisite.requiresUpload = backendPrerequisite.requiresUpload;
      prerequisite.dueDate = new Date(backendPrerequisite.dueDate);
      prerequisite.submissionValue = backendPrerequisite.submissionValue;
      prerequisite.status = backendPrerequisite.status.text;
      return prerequisite;
    });
  }

  private getApplicationDisplayStatus(backendStatus: string) {
    switch (backendStatus) {
      case ProgramsStatusFilters.approved:
        return 'Approved';
      case ProgramsStatusFilters.inProgress:
        return 'in-progress';
      case ProgramsStatusFilters.acceptedApplications:
        return 'accepting-applications';
      case ProgramsStatusFilters.completed:
        return 'completed';
      case MenteeApplicationStatus.accepted:
      case MenteeApplicationStatus.approved:
        return 'In Progress';
      case MenteeApplicationStatus.declined:
        return 'Declined';
      case MenteeApplicationStatus.graduated:
        return 'Graduated';
      default:
        return 'Prerequisites Pending';
    }
  }

  private getApplicationInviteStatus(acceptanceStatus: string) {
    switch (acceptanceStatus) {
      case AppInviteTypes.accepted:
        return 'Accepted';
      case AppInviteTypes.pending:
        return 'Awaiting Decision';
      case AppInviteTypes.declined:
        return 'Declined';
      default:
        return 'Unknown Status';
    }
  }

  private mapProgramTerms(terms: any[]) {
    if (terms && terms.length) {
      const backendTerm = terms[0];
      const term = new Term();
      term.name = backendTerm.name;
      term.availableMenteeSpots = backendTerm.menteeCapacity
        ? backendTerm.menteeCapacity
        : 0;
      term.startDate = new Date(backendTerm.startDate);
      term.endDate = new Date(backendTerm.endDate);
      term.applicationAcceptanceStartDate = new Date(
        backendTerm.applicationAcceptanceStartDate
      );
      term.applicationAcceptanceEndDate = new Date(
        backendTerm.applicationAcceptanceEndDate
      );
      term.isFullTime = backendTerm.isFullTime;
      return [term];
    } else {
      return [];
    }
  }

  private mapProgramProjects(projects: any) {
    return projects
      .map((project: any) => {
        if (!project) {
          return null;
        }
        return {
          id: project.id,
          project: {
            id: project.projectID,
            name: project.name,
            logo: project.logo,
          },
          repositories: project.repositories,
        };
      })
      .filter((project: any) => !!project);
  }

  private mapProgramMentors(mentors: any[]) {
    if (mentors) {
      return mentors.map((mentor) => {
        mentor.name = mentor.firstName + ' ' + mentor.lastName;
        mentor.userLFID = mentor.userLFID;
        return mentor;
      });
    } else {
      return [];
    }
  }

  private mapProgramMentee(metes: any[])  {
    if(metes) {
       return metes.map( mentee => {
         mentee.firstName = mentee.first_name;
         mentee.lastName = mentee.last_name;
         mentee.imageUrl = mentee.logo_url;
         mentee.email = mentee.email;
         mentee.userID = mentee.user_name;
         mentee.emailMessage = '';
         mentee.status = '';
         return mentee ;
       });
    } else {
      return [];
    }
  }
    
  

  private mapProgramPrerequisites(
    prerequisites: any[],
    withoutID: boolean = false
  ) {
    return prerequisites.map((backendPrerequisite) => {
      const prerequisite = new PreRequisiteTask();
      if (!withoutID) {
        prerequisite.id = backendPrerequisite.id;
      }
      prerequisite.name = backendPrerequisite.name;
      prerequisite.description = backendPrerequisite.description;
      prerequisite.isCustom = backendPrerequisite.isCustom;
      prerequisite.isRequired = backendPrerequisite.isRequired;
      prerequisite.url = backendPrerequisite.URL;
      prerequisite.requiresUpload = backendPrerequisite.requiresUpload;
      prerequisite.dueDate = new Date(backendPrerequisite.dueDate);
      return prerequisite;
    });
  }

  private mapProgramStatus(program: Program) {
    const today = new Date().getTime();
    const startDt = new Date(program.terms[0].startDate).getTime();
    const endDt = new Date(program.terms[0].endDate).getTime();
    const applicationStartDate = new Date(
      program.terms[0].applicationAcceptanceStartDate
    ).getTime();
    const applicationEndDate = new Date(
      program.terms[0].applicationAcceptanceEndDate
    ).getTime();
    let displayedStatus = program.status;
    if (program.status == ProgramStatus.approved) {
      if (today < applicationStartDate) {
        displayedStatus = ProgramStatus.upcoming;
      } else if (today >= applicationStartDate && today <= applicationEndDate) {
        displayedStatus = ProgramStatus.acceptingApplication;
      } else if (
        (today >= startDt || today > applicationEndDate) &&
        today <= endDt
      ) {
        displayedStatus = ProgramStatus.inprogress;
      }
      if (today > endDt) {
        displayedStatus = ProgramStatus.completed;
      }
    }
    return displayedStatus;
  }

  private mapProgramToFrontendModel = (backendProgram: any) => {
    const program = new Program();
    program.id = backendProgram.id;
    program.name = backendProgram.name;
    program.projectCodeConductURL = backendProgram.cocURL;
    program.createdBy = backendProgram.createdBy;
    program.description = backendProgram.description;
    program.budget = backendProgram.budget;
    program.paymentFrequency = backendProgram.paymentFrequency;
    program.maxProjectSpend =
      MAX_MENTEE_BUDGET *
      (backendProgram.programTerms[0]?.menteeCapacity
        ? backendProgram.programTerms[0]?.menteeCapacity
        : 0);
    program.minProjectSpend =
      MIN_MENTEE_BUDGET *
      (backendProgram.programTerms[0]?.menteeCapacity
        ? backendProgram.programTerms[0]?.menteeCapacity
        : 0);
    program.status = backendProgram.status?.text;
    program.requiredSkills = backendProgram.skills;
    program.terms = this.mapProgramTerms(backendProgram.programTerms);
    program.projects = this.mapProgramProjects(backendProgram.projects);
    program.courses = backendProgram.courses;
    program.learningObjectives = backendProgram.learningObjectives;
    program.coAdmins = backendProgram.coAdmins?.map((admin: any) => {
      admin.name = admin.firstName + ' ' + admin.lastName;
      admin.userLFID = admin.userLFID;
      admin.role = 'Mentorship Admin';
      return admin;
    });
    program.mentors = this.mapProgramMentors(backendProgram.mentors);
    program.programPreRequisites = this.mapProgramPrerequisites(
      backendProgram.preRequisites
    );
    if (program.terms.length) {
      program.displayStatus = this.mapProgramStatus(program);
    } else {
      program.displayStatus = ProgramStatus.draft;
    }
    return program;
  };
}
