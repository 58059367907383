import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { AlertManagement } from 'src/app/core/alert-management';
import { ProgramsStatusFilters, PROGRAMS_PAGE_SIZE } from 'src/app/core/constants';
import { ProgramController } from 'src/app/core/controllers/program.controller';
import { Program } from 'src/app/core/controllers/mentorship.models';
import { programDummy } from '../program-overview/dummy-program';


@Component({
  selector: 'app-all-programs',
  templateUrl: './all-programs.component.html',
  styleUrls: ['./all-programs.component.scss']
})
export class AllProgramsComponent implements OnInit {

  programs = new Array<Program>();
  isLoading = false;
  isLoadMore = false;
  activeTab = ProgramsStatusFilters.approved;
  pageIndex = 0;
  totalPages = 1;
  destroy$ = new Subject();

  constructor(
    private route: Router,
    private activatedRoute: ActivatedRoute,
    private programController: ProgramController
  ) { }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(res => {
      let tab = res['tab'];
      if (!tab || !ProgramsStatusFilters.validList.includes(tab)) {
        this.activeTab = ProgramsStatusFilters.approved;
      } else {
        this.activeTab = tab;
      }
      this.pageIndex = 0;
      this.programs = [];
      this.destroy$.next('');
      this.getAllPrograms();
    });
  }

  getAllPrograms() {
    this.isLoading = true;
    this.pageIndex = 0;
    this.programController.getAllPrograms(PROGRAMS_PAGE_SIZE, this.pageIndex, this.activeTab).pipe(takeUntil(this.destroy$)).subscribe(
      {
        next: res => {
          const { programs, metaData } = res;
          this.totalPages = Math.ceil(metaData.totalCount / PROGRAMS_PAGE_SIZE);
          this.programs = programs;
          this.pageIndex++;
          this.isLoading = false;
        },
        error: (err) => {
          this.isLoading = false;
          AlertManagement.createAlertDanger(err.error.message);
        }
      }
    );
  }

  loadMore() {
    if (this.isLoadMore || this.isLoading || this.totalPages <= this.pageIndex) {
      return;
    }
    this.isLoadMore = true;
    this.programController.getAllPrograms(PROGRAMS_PAGE_SIZE, this.pageIndex, this.activeTab).pipe(takeUntil(this.destroy$)).subscribe({
      next: res => {
        const { programs, metaData } = res;
        this.programs.push(...programs);
        this.pageIndex++;
        this.isLoadMore = false;
      },
      error: (err) => {
        this.isLoadMore = false;
        AlertManagement.createAlertDanger(err.error.message);
      }
    }
    );
  }

  viewProgram(programId: string) {
    this.route.navigateByUrl(`/program/overview/${programId}`);
  }

}
