import { ProgramStatus } from "src/app/core/constants";
import { Program } from "src/app/core/controllers/mentorship.models";

export const programDummy: Program = {
  id: '',
  name: 'Hyperledger Mentorship Program',
  projects: [
    {
      project: {
        id: 'a0941000002wBz4AAE',
        name: 'Cloud Native Computing Foundation (CNCF)',
        logo: 'https://lf-master-project-logos-prod.s3.us-east-2.amazonaws.com/cncf.svg',
      },
      repositories: [
        'https://github.com/LF-Engineering/lfx-acs-elements.git',
        'https://github.com/LF-Engineering/lfx-acs-elements.git',
        'git@github.com:LF-Engineering/lfx-acs-elements.git',
        'git@github.com:LF-Engineering/lfx-acs-elements.git',
        'https://github.com/LF-Engineering/lfx-acs-elements.git',
        'git@github.com:LF-Engineering/lfx-acs-elements.git',
        'git@github.com:LF-Engineering/lfx-acs-elements.git',
      ],
    },
  ],
  budget: 50000,
  minProjectSpend: 50000,
  maxProjectSpend: 50000,
  paymentFrequency: 'Milestone Based',
  courses: [{ name: 'Course 1' }, { name: 'Course 2' }, { name: 'Course 3' }],
  description:
    'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo',
  learningObjectives: [
    {
      title: "Lorem Ipsum Has Been The Industry's Standard",
      description: `Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit. Proin Feugiat Mauris Sed Accumsan Feugiats Uspendisse Ligula Orci, Finibus Necscelerisque Nec, Cursuslorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit. Proin Feugiat Mauris Sed Accumsan Feugiats`,
    },
    {
      title: "Lorem Ipsum Has Been The Industry's Standard",
      description: `Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit. Proin Feugiat Mauris Sed Accumsan Feugiats Uspendisse Ligula Orci, Finibus Necscelerisque Nec, Cursuslorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit. Proin Feugiat Mauris Sed Accumsan Feugiats`,
    },
    {
      title: "Lorem Ipsum Has Been The Industry's Standard",
      description: `Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit. Proin Feugiat Mauris Sed Accumsan Feugiats Uspendisse Ligula Orci, Finibus Necscelerisque Nec, Cursuslorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit. Proin Feugiat Mauris Sed Accumsan Feugiats`,
    },
  ],
  requiredSkills: [{ name: 'Linux Kernel' }, { name: 'C' }, { name: 'Shell' }],
  terms: [
    {
      id: '',
      name: 'Spring 2021',
      isFullTime: true,
      availableMenteeSpots: 8,
      startDate: 1639738138897,
      endDate: 1639738138897,
      applicationAcceptanceStartDate: 1639738138897,
      applicationAcceptanceEndDate: 1639738138897,
    },
  ],
  programPreRequisites: [
    {
      id: "",
      name: 'Resume',
      description: 'Upload the most recent version of your resume.',
      isRequired: true,
    },
    {
      id: "",
      name: 'Cover Letter',
      description: `A letter to the program covering the following topics:
      • How did you find out about our mentorship program?
      • Why are you interested in this program?
      • What experience and knowledge/skills do you have that are applicable to this program?`,
      isRequired: true,
    },
  ],
  status: ProgramStatus.approved,
  displayStatus: ProgramStatus.upcoming,
  coAdmins: [
    {
      firstName: 'Anastasia',
      lastName: 'Marinina',
      email: '',
      id: '',
      name: 'Anastasia Marinina',
      role: 'Community Manager'
    },
    {
      firstName: 'Romi',
      lastName: 'Pak',
      email: '',
      id: '',
      name: 'Romi Pak',
      role: 'Community Manager'
    }
  ],
  mentors: [
    {
      firstName: 'David',
      lastName: 'Ali Rashed',
      email: '',
      emailMessage: '',
      id: '',
      invitationStatus: '',
      name: 'David Ali Rashed',
      role: 'Technology Strategist'
    },
    {
      firstName: 'Susana',
      lastName: 'Abaitua',
      email: '',
      emailMessage: '',
      id: '',
      invitationStatus: '',
      name: 'Susana Abaitua',
      role: 'Technology Strategist'
    }
  ],
  mentees: [],
  projectCodeConductURL: ''
};

export const programMentors = [
  {
    name: 'David Ali Rashed',
    firstName: 'David',
    lastName: 'Ali Rashed',
    logoUrl: '',
    title: 'Technology Strategist',
    skills: [
      'Linux Kernel',
      'C',
      'Javascript',
      'Go',
      'Shell'
    ],
    otherMentorships: [
      'AI Modeling',
      'Distributed Data Processing'
    ]
  },
  {
    name: 'Susana Abaitua',
    firstName: 'Susana',
    lastName: 'Abaitua',
    logoUrl: '',
    title: 'Community Manager',
    skills: [
      'Linux Kernel',
      'Shell'
    ],
    otherMentorships: [
      'Bluetooth Communications Pairing'
    ]
  },
  {
    name: 'Teyonah Parris',
    firstName: 'Susana',
    lastName: 'Parris',
    logoUrl: '',
    title: 'Senior Programmer',
    skills: [
      'Linux Kernel',
    ],
    otherMentorships: [
      'AI Modeling',
      'Hyperledger Mentorship...'
    ]
  }
];

export const acceptedMentees = [
  {
    name: 'Patricia Smith',
    firstName: 'Patricia',
    lastName: 'Smith',
    logoUrl: ''
  },
  {
    name: 'Mahesh Pillai',
    firstName: 'Mahesh',
    lastName: 'Pillai',
    logoUrl: ''
  },
  {
    name: 'Helen Anduze',
    firstName: 'Helen',
    lastName: 'Anduze',
    logoUrl: ''
  }
];

export const admins = [
  {
    name: 'Asif Ali',
    firstName: 'Asif',
    lastName: 'Ali',
    logoUrl: '',
    title: 'Program Manager',
  },
  {
    name: 'Anastasia Marinina',
    firstName: 'Anastasia',
    lastName: 'Marinina',
    logoUrl: '',
    title: 'Manager',
  },
  {
    name: 'Teyonah Parris',
    firstName: 'Teyonah',
    lastName: 'Parris',
    logoUrl: '',
    title: 'Senior Programmer',
  }
]
