import { NgModule } from '@angular/core';
import { NgbAccordionModule, NgbDatepickerModule, NgbNavModule, NgbPopoverModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
    exports: [
        NgbTooltipModule,
        NgbNavModule,
        NgbDatepickerModule,
        NgbPopoverModule,
        NgbAccordionModule
    ],
})
export class NgBootstrapModule { }