import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MentorInviteApplicationComponent } from './modules/mentor/pages/mentor-invite-application/mentor-invite-application.component';
import { MenteeApplicationComponent } from './modules/mentee-application/pages/mentee-application/mentee-application.component';
import { AllProgramsComponent } from './modules/program/pages/all-programs/all-programs.component';
import { AllMentorshipComponent } from  './modules/program/pages/all-mentorship/all-mentorship.component'
import { ProgramOverviewComponent } from './modules/program/pages/program-overview/program-overview.component'
import { AuthGuard } from '@auth0/auth0-angular';
import { MentorInviteDashboardComponent } from './modules/mentor/pages/mentor-invite-dashboard/mentor-invite-dashboard.component';
import { MentorAcceptProgramOverviewComponent } from './modules/mentor/pages/mentor-accept-program-overview/mentor-accept-program-overview';
import {MentorMenteeApplicationReviewComponent} from './modules/mentor/pages/mentor-mentee-application-review/mentor-mentee-application-review';
const routes: Routes = [
  {
    path: '',
    component: AllProgramsComponent
  },
  {
    path: 'all-mentorships',
    component: AllMentorshipComponent
  },
  {
    path: 'program/overview/:programId',
    component: ProgramOverviewComponent
  },
  {
    path: 'program/:programId/application/:applicationId',
    component: MenteeApplicationComponent
  },
  {
    path: 'my-mentorships',
    component: MentorInviteDashboardComponent
  },
  {
    path: 'mentor-invite',
    component: MentorInviteApplicationComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'mentor/overview/:programId/:projectId',
    component: MentorAcceptProgramOverviewComponent               
  },
  {
    path: 'mentor/menteeAppReview/:projectId',
    component: MentorMenteeApplicationReviewComponent              
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
