import { Injectable } from '@angular/core';
import { skills } from '../skills';
@Injectable()
export class SkillsService {
  skillsList: { name: string }[] = skills;

  constructor() { }

  public search = (searchText: string): { name: string }[] => {
    searchText = (searchText || '').toLowerCase();
    if (!searchText) {
      return this.skillsList;
    }
    return this.skillsList.filter(skillName => skillName.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1).slice(0, 10);
  }
}
