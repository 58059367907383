import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "@auth0/auth0-angular";
import { NgbPopover } from "@ng-bootstrap/ng-bootstrap";
import { ProgramStatus } from "src/app/core/constants";
import { Program } from "src/app/core/controllers/mentorship.models";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { DialogMenteeAppliedComponent } from "../../program/components/dialog-mentee-applied/dialog-mentee-applied.component";
import { MenteeApplicationController } from "src/app/core/controllers/mentee-application.controller";
import { MenteeQuestionnaireComponent } from "../../program/components/mentee-questionaaire/mentee-questionaaire.component";

@Component({
  selector: "lfx-program-overview-header",
  templateUrl: "./program-overview-header.component.html",
  styleUrls: ["./program-overview-header.component.scss"],
})
export class ProgramOverviewHeaderComponent implements OnInit {
  @Input() program!: Program;
  @Input() isMenteeApplicationPage!: boolean;
  activePopover!: NgbPopover;
  statistics = {
    remainSpots: 8,
    accepted: 3,
    application: 25,
  };
  openSidePanel = false;
  menteeAlreadyApplied = false;
  isLoggedIn = false;
  programStatus = ProgramStatus;

  constructor(
    private authService: AuthService,
    private modalService: NgbModal,
    private menteeApplicationController: MenteeApplicationController
  ) { }

  ngOnInit() {
    this.authService.isAuthenticated$.subscribe((res) => {
      this.isLoggedIn = res;
    });
  }

  applyAsMentee() {
    if (this.isLoggedIn) {
      this.menteeApplicationController.getAppliedMentee().subscribe((res) => {
        var alreadyApplied = res.menteeApplications.filter(
          (program: any) => program.term.program.id == this.program.id
        );
        if (alreadyApplied.length) {
          this.modalService.open(DialogMenteeAppliedComponent, {
            centered: true,
            modalDialogClass: "custom-class",
          });
        } else {
          this.openSidePanel = true;
          this.openDialog();
        }
      }, error => {
        this.openSidePanel = true;
        this.openDialog();
      });
    } else {
      this.authService.loginWithRedirect({
        appState: { target: `/program/overview/${this.program.id}` },
      });
    }
  }

  openDialog() {
    const dialogRef = this.modalService.open(MenteeQuestionnaireComponent, {
      size: "lg",
      centered: true,
    });
    const instance = dialogRef.componentInstance;
    instance.programID = this.program.id;
  }

  getClosePanelVal(evt: any) {
    this.openSidePanel = false;
  }

  openPopover(popover: NgbPopover) {
    this.activePopover = popover;
    if (this.activePopover && !this.activePopover.isOpen()) {
      this.activePopover.open();
    }
  }

  closePopover() {
    if (this.activePopover && this.activePopover.isOpen()) {
      this.activePopover.close();
    }
  }
}
