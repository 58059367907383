<div class="all-programs-container background">
  <ng-container
    *ngIf="!isLoading && programs!.length > 0; else loadingTemplate"
  >
    <div infinite-scroll [infiniteScrollDistance]="2" (scrolled)="loadMore()">
      <div class="mentorship-accepting-text">
        Mentorship Programs Accepting Applications
      </div>
      <!-- <div
        class="grid-container mb-4 card-top"
        
      > -->
      <owl-carousel-o [options]="customOptions">
        <ng-container *ngFor="let program of programs">
          <ng-template carouselSlide>
            <!-- <div class="grid-container mb-4"> -->
            <lfx-mentorship-details-card
              (viewProgram)="viewProgram(program.id)"
              [program]="program"
            >
            </lfx-mentorship-details-card>
            <!-- </div> -->
          </ng-template>
        </ng-container>
      </owl-carousel-o>
      <!-- </div> -->
      <lfx-loading *ngIf="isLoadMore" class="flex-grow-1 my-5"></lfx-loading>
    </div>
  </ng-container>
</div>

<!-- 
<owl-carousel-o [options]="customOptions">
  <ng-template carouselSlide>Slide 1</ng-template>  
  <ng-template carouselSlide>Slide 2</ng-template>  
  <ng-template carouselSlide>Slide 3</ng-template>  
</owl-carousel-o> -->

<ng-template #loadingTemplate>
  <div
    *ngIf="!isLoading"
    class="
      size-20
      weight-bold
      d-flex
      flex-grow-1
      w-100
      align-items-center
      justify-content-center
      mt-4
    "
  >
    No records found
  </div>
  <lfx-loading *ngIf="isLoading" class="flex-grow-1"></lfx-loading>
</ng-template>
