<div class="dashboard-container">
  <lfx-breadcrumbs [breadcrumbs]="breadcrumbs"></lfx-breadcrumbs>
  <div class="table-container flex-grow-1 mb-5">
    <app-common-table class="h-100" [tableConfiguration]="tableConfig" [tableRecords]="applications">
    </app-common-table>
  </div>
  <div class="spacer"></div>
</div>


<ng-template #projectNameTemplate let-element>
  <span class="text-black weight-600">
    {{element.rowData.program?.project?.name}}
  </span>
</ng-template>

<ng-template #programTemplate let-element>
  <span class="d-flex align-items-center w-100 mw-100 cursor-pointer size-16 text-primary"
    (click)="previewProgram(element.rowData.program.id)">
    {{element.rowData.program.name}}
  </span>
</ng-template>


<ng-template #statusTemplate let-element>
  <span class="mw-100 w-100 d-flex align-items-center cursor-pointer size-16 text-primary" lfxEllipsis
    (click)="navigateToMenteeApplication(element.rowData)">
    {{element.rowData.displayedStatus | titlecase}}
  </span>

</ng-template>

<ng-template #actionTemplate let-element>
  <div class="d-flex justify-content-center align-items-center w-100"
    *ngIf="[applicationStatus.draft, applicationStatus.submitted].includes(element.rowData.status)">
    <i class="fa fa-trash-alt text-primary cursor-pointer" (click)="withdrawApplication(element.rowData)"
      aria-hidden="true">

    </i>
  </div>
</ng-template>
