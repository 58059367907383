import {
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
  AfterViewInit,
  Input,
  OnChanges,
  SimpleChanges,
  OnDestroy,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ProgramStatus } from 'src/app/core/constants';
import { ProgramController } from 'src/app/core/controllers/program.controller';
import {
  Program,
  TableColumnConfig,
  TableConfig,
} from 'src/app/core/controllers/mentorship.models';
import { Utilities } from 'src/app/core/utilities';
import {
  acceptedMentees,
  admins,
  programDummy,
  programMentors,
} from './dummy-program';

@Component({
  selector: 'lfx-program-overview',
  templateUrl: './program-overview.component.html',
  styleUrls: ['./program-overview.component.scss'],
})
export class ProgramOverviewComponent
  implements OnInit, OnChanges, AfterViewInit, OnDestroy
{
  program: Program = new Program();
  @Input() programId!: string;

  @ViewChild('termNameTemplate') termNameTemplate!: TemplateRef<any>;
  @ViewChild('textTemplate') textTemplate!: TemplateRef<any>;
  @ViewChild('termTypeTemplate') termTypeTemplate!: TemplateRef<any>;
  @ViewChild('dateTemplate') dateTemplate!: TemplateRef<any>;
  @ViewChild('usernameTemplate') usernameTemplate!: TemplateRef<any>;
  @ViewChild('descriptionTemplate') descriptionTemplate!: TemplateRef<any>;
  @ViewChild('tagsTemplate') tagsTemplate!: TemplateRef<any>;
  utilities = new Utilities();
  programStatus = ProgramStatus;
  config: TableConfig = {
    xPadding: '30px',
    colXPadding: '20px',
    columns: new Array<TableColumnConfig>(),
  };
  termConfig!: TableConfig;
  mentorConfig!: TableConfig;
  acceptedConfig!: TableConfig;
  adminConfig!: TableConfig;
  prerequisitesConfig!: TableConfig;
  isLoading = false;
  programProjectsRepositories = new Array<string>();
  activePopover!: NgbPopover;
  acceptedMentees = new Array();
  programMentors = new Array();
  destroy$ = new Subject();

  constructor(
    private programController: ProgramController,
    private activatedRoute: ActivatedRoute
  ) {}
  ngOnInit() {
    this.activatedRoute.paramMap.subscribe((paramMap) => {
      const programId = paramMap.get('programId');
      if (programId) {
        this.getProgram(programId);
        this.fillDummyData();
      }
    });
  }

  private fillDummyData() {
    // this.program = programDummy;
    this.acceptedMentees = acceptedMentees;
    this.programMentors = programMentors;
    this.program.coAdmins = admins as any;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['programId'] && changes['programId'].currentValue) {
      this.getProgram(this.programId);
    }
  }

  getProgram(programId: string) {
    this.isLoading = true;
    this.programController
      .getProgramById(programId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (program: Program) => {
          this.program = program;
          this.programProjectsRepositories = [];
          this.program.projects.forEach((project) => {
            if (project.repositories) {
              this.programProjectsRepositories.push(...project.repositories);
            }
          });
          this.isLoading = false;
        },
        () => {
          this.isLoading = false;
        }
      );
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.fillTermsConfig();
      this.fillMentorsConfig();
      this.fillAcceptedMenteeConfig();
      this.fillAdminsConfig();
      this.fillPrerequisitesConfig();
    }, 0);
  }

  private fillTermsConfig() {
    this.termConfig = Object.assign({}, this.config);
    this.termConfig.columns = [
      {
        title: 'Term Type',
        key: 'termType',
        width: '28.5%',
        template: this.termTypeTemplate,
      },
      {
        title: 'Term Name',
        key: 'name',
        width: '26.5%',
        template: this.termNameTemplate,
      },
      {
        title: 'Start Date-End Date',
        key: '',
        width: '22.5%',
        template: this.dateTemplate,
      },
      {
        title: 'Application Acceptance Period',
        key: 'applicationAcceptance',
        width: '22.5%',
        template: this.dateTemplate,
      },
    ];
  }

  private fillMentorsConfig() {
    this.mentorConfig = Object.assign({}, this.config);
    this.mentorConfig.columns = [
      {
        title: 'Name',
        key: 'name',
        width: '26.5%',
        template: this.usernameTemplate,
      },
      {
        title: 'Title',
        key: 'title',
        width: '23%',
        template: this.textTemplate,
      },
      {
        title: 'Skills',
        key: 'skills',
        width: '22.5%',
        template: this.tagsTemplate,
      },
      {
        title: 'Other Mentorships',
        key: 'otherMentorships',
        width: '28%',
        template: this.tagsTemplate,
      },
    ];
  }

  private fillAcceptedMenteeConfig() {
    this.acceptedConfig = Object.assign({}, this.config);
    this.acceptedConfig.columns = [
      {
        title: 'Name',
        key: 'name',
        width: '100%',
        template: this.usernameTemplate,
      },
    ];
  }

  private fillAdminsConfig() {
    this.adminConfig = Object.assign({}, this.config);
    this.adminConfig.columns = [
      {
        title: 'Name',
        key: 'name',
        width: '33%',
        template: this.usernameTemplate,
      },
      {
        title: 'Title',
        key: 'title',
        width: '67%',
        template: this.textTemplate,
      },
    ];
  }
  private fillPrerequisitesConfig() {
    this.prerequisitesConfig = Object.assign({}, this.config);
    this.prerequisitesConfig.columns = [
      {
        title: 'Prerequisites Name',
        key: 'name',
        width: '41%',
        template: this.termNameTemplate,
      },
      {
        title: 'Description',
        key: 'description',
        width: '59%',
        template: this.descriptionTemplate,
      },
    ];
  }

  viewRepository(repoUrl: string) {
    if (!/^(?:f|ht)tps?\:\/\//.test(repoUrl)) {
      repoUrl = 'https://' + repoUrl;
    }
    window.open(repoUrl, '_blank');
  }

  openPopover(popover: NgbPopover) {
    this.activePopover = popover;
    if (this.activePopover && !this.activePopover.isOpen()) {
      this.activePopover.open();
    }
  }

  closePopover() {
    if (this.activePopover && this.activePopover.isOpen()) {
      this.activePopover.close();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next('');
    this.destroy$.complete();
    this.destroy$.unsubscribe();
  }
}
