export class AlertManagement {
  private static iconMap = {
    info: 'fa fa-info-circle',
    success: 'fa fa-thumbs-up',
    warning: 'fa fa-exclamation-triangle',
    danger: 'fa ffa fa-exclamation-circle',
  };
  static parentNode: HTMLElement;
  constructor() { }

  /**
   * Creates alert function to generate alert
   * @param title Alert main header title
   * @param subTitle Alert sub title
   * @param details Alert details body message
   * @param alertType Alert type like danger, info, success,warning
   * @param dismissible add dismiss icon or not
   * @param closeAfter dismiss alert after period of time in seconds
   * @param appendToId alert message parent element ID
   */
  private static createAlert(
    details: string,
    alertType: string,
    dismissible: boolean,
    closeAfter: number
  ) {
    let iconAdded = false;
    const alertClasses = ['alert', 'd-flex', 'align-items-center'];
    alertClasses.push('alert-' + alertType.toLowerCase());

    if (dismissible) {
      alertClasses.push('alert-dismissible');
    }

    const msgIcon = document.createElement('i');
    msgIcon.classList.add(...['mr-2']);

    switch (alertType) {
      case 'danger':
        msgIcon.innerHTML = dangerIcon;
        break;
      case 'success':
        msgIcon.innerHTML = successIcon;
        break
    }

    const msg = document.createElement('div');
    msg.classList.add(...alertClasses);
    if (details) {
      const msgDetails = document.createElement('span');
      msgDetails.classList.add(...['d-flex', 'align-items-center'])
      msgDetails.innerText = details;
      msg.appendChild(msgDetails);

      if (!iconAdded) {
        msgDetails.prepend(msgIcon);
        iconAdded = true;
      }
    }

    if (dismissible) {
      const msgClose = document.createElement('span');
      msgClose.classList.add(...['close', 'cursor-pointer']);
      msgClose.setAttribute('data-dismiss', 'alert');
      msgClose.innerHTML = timesIcon;
      msgClose.addEventListener('click', () => {
        msg.remove();
      })
      msg.appendChild(msgClose);
    }

    this.parentNode.prepend(msg);

    setTimeout(() => {
      msg.remove();
    }, closeAfter * 1000);
  }

  public static createAlertSuccess(details: string, dismissible: boolean = true, closeAfter: number = 5) {
    this.createAlert(
      details,
      'success',
      dismissible,
      closeAfter
    );
  }

  public static createAlertDanger(details: string, dismissible: boolean = true, closeAfter: number = 5) {
    this.createAlert(
      details,
      'danger',
      dismissible,
      closeAfter
    );
  }
}

const timesIcon = `<svg
width="14"
height="14"
fill="#000"
xmlns="http://www.w3.org/2000/svg"
viewBox="0 0 352 512"
>
<path
  fill="currentColor"
  d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
></path>
</svg>`;

const dangerIcon = `<svg
width="20"
height="20"
fill="#FF5A5A"
xmlns="http://www.w3.org/2000/svg"
viewBox="0 0 512 512"
>
<path
  fill="#FF5A5A"
  d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zm-248 50c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z"
></path>
</svg>`;


const successIcon = `<svg width="20" height="20" color="#A2D683" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check-circle" class="svg-inline--fa fa-check-circle fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
<path fill="currentColor" d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"/>
</svg>
`
