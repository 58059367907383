import { Injectable } from '@angular/core';
import { MAX_MENTEE_BUDGET, MIN_MENTEE_BUDGET, ProgramStatus } from '../constants';
import { PreRequisiteTask, Program, ProjectRepository, Term } from './mentorship.models';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, of, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProgramController {

  httpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  });

  options = {
    headers: this.httpHeaders
  };

  constructor(private http: HttpClient) { }


  //  TODO: To be removed after implementing the proper BFF setup.
  getProgramByIdAndProjectId(programId: string, projectId: string) {
    return this.http
      .get<any>(
        `https://lfx-acs-ui-api.dev.platform.linuxfoundation.org/mentorshipadmin/projects/${projectId}/programs/${programId}`
      )
      .pipe(
        map(this.mapProgramToFrontendModel)
      );
  }

  getProgramById(programId: string) {
    return this.http
      .get<any>(
        `https://lfx-acs-ui-api.dev.platform.linuxfoundation.org/mentorshipadmin/programs/${programId}`
      )
      .pipe(
        map(this.mapProgramToFrontendModel)
      );
  }

  //  TODO: will need to change API URL after implementing the proper BFF setup
  getAllPrograms(pageSize: number, pageIndex: number, status: string, name?: string) {
    let route = `https://lfx-acs-ui-api.dev.platform.linuxfoundation.org/mentorshipadmin/programs?pageSize=${pageSize}&offset=${pageSize * pageIndex}&status=${status}`
    if (name && name.length) {
      route += `&name=${name}`;
    }
    return this.http.get<any>(route).pipe(map(res => {
      return { programs: this.mapCustomAllProgram(res.programs), metaData: res.metaData }
    }
    ));
  }

  private mapProgramToFrontendModel = (backendProgram: any) => {
    const program = new Program();
    program.id = backendProgram.id;
    program.name = backendProgram.name;
    program.projectCodeConductURL = backendProgram.cocURL;
    program.createdBy = backendProgram.createdBy;
    program.description = backendProgram.description;
    program.budget = backendProgram.budget;
    program.paymentFrequency = backendProgram.paymentFrequency;
    program.maxProjectSpend =
      MAX_MENTEE_BUDGET *
      (backendProgram.programTerms[0]?.menteeCapacity
        ? backendProgram.programTerms[0]?.menteeCapacity
        : 0);
    program.minProjectSpend =
      MIN_MENTEE_BUDGET *
      (backendProgram.programTerms[0]?.menteeCapacity
        ? backendProgram.programTerms[0]?.menteeCapacity
        : 0);
    program.status = backendProgram.status?.text;
    program.requiredSkills = backendProgram.skills;
    program.terms = this.mapProgramTerms(backendProgram.programTerms);
    program.projects = this.mapProgramProjects(backendProgram.projects);
    program.courses = backendProgram.courses;
    program.learningObjectives = backendProgram.learningObjectives;
    program.coAdmins = backendProgram.coAdmins?.map((admin: any) => {
      admin.name = admin.firstName + ' ' + admin.lastName;
      admin.userLFID = admin.userLFID;
      admin.role = 'Mentorship Admin';
      return admin
    });
    program.mentors = this.mapProgramMentors(backendProgram.mentors);
    program.programPreRequisites = this.mapProgramPrerequisites(
      backendProgram.preRequisites
    );
    if (program.terms.length) {
      program.displayStatus = this.mapProgramStatus(program);
    } else {
      program.displayStatus = ProgramStatus.draft;
    }
    return program;
  }

  private mapProgramProjects(projects: any) {
    return projects.map((project: any) => {
      if (!project) {
        return null;
      }
      return {
        id: project.id,
        project: {
          id: project.projectID,
          name: project.name,
          logo: project.logo,
        },
        repositories: project.repositories,
      };
    }).filter((project: ProjectRepository) => !!project);
  }

  private mapProgramTerms(terms: any[]) {
    return terms.map((backendTerm) => {
      const term = new Term();
      term.name = backendTerm.name;
      term.availableMenteeSpots = backendTerm.menteeCapacity
        ? backendTerm.menteeCapacity
        : 0;
      term.startDate = new Date(backendTerm.startDate);
      term.endDate = new Date(backendTerm.endDate);
      term.applicationAcceptanceStartDate = new Date(
        backendTerm.applicationAcceptanceStartDate
      );
      term.applicationAcceptanceEndDate = new Date(
        backendTerm.applicationAcceptanceEndDate
      );
      term.isFullTime = backendTerm.isFullTime;
      return term;
    });
  }

  private mapProgramPrerequisites(prerequisites: any[]) {
    return prerequisites.map((backendPrerequisite) => {
      const prerequisite = new PreRequisiteTask();
      prerequisite.name = backendPrerequisite.name;
      prerequisite.description = backendPrerequisite.description;
      prerequisite.isCustom = backendPrerequisite.isCustom;
      prerequisite.isRequired = backendPrerequisite.isRequired;
      prerequisite.url = backendPrerequisite.URL;
      prerequisite.requiresUpload = backendPrerequisite.requiresUpload;
      prerequisite.dueDate = new Date(backendPrerequisite.dueDate);
      return prerequisite;
    });
  }

  private mapProgramStatus(program: Program) {
    const today = new Date().getTime();
    const startDt = new Date(program.terms[0].startDate).getTime();
    const endDt = new Date(program.terms[0].endDate).getTime();
    const applicationStartDate = new Date(program.terms[0].applicationAcceptanceStartDate).getTime();
    const applicationEndDate = new Date(program.terms[0].applicationAcceptanceEndDate).getTime();
    let displayedStatus = program.status;
    if (program.status == ProgramStatus.approved) {
      if (today < applicationStartDate) {
        displayedStatus = ProgramStatus.upcoming;
      } else if (today >= applicationStartDate && today <= applicationEndDate) {
        displayedStatus = ProgramStatus.acceptingApplication;
      } else if ((today >= startDt || today > applicationEndDate) && (today <= endDt)) {
        displayedStatus = ProgramStatus.inprogress;
      } if (today > endDt) {
        displayedStatus = ProgramStatus.completed;
      }
    }
    return displayedStatus;
  }

  private mapProgramMentors(mentors: any[]) {
    if (mentors) {
      return mentors.map(mentor => {
        mentor.name = mentor.firstName + ' ' + mentor.lastName;
        mentor.userLFID = mentor.userLFID;
        return mentor;
      });
    } else {
      return [];
    }
  }

  private mapCustomAllProgram = (backendPrograms: Array<any>): Array<Program> => {
    return backendPrograms.map(backendProgram => {
      const program = new Program();
      program.id = backendProgram.id;
      program.name = backendProgram.name;
      program.projectCodeConductURL = backendProgram.cocURL;
      program.createdBy = backendProgram.createdBy;
      program.description = backendProgram.description;
      program.courses = backendProgram.courses;
      program.status = backendProgram.status;
      program.learningObjectives = backendProgram.learningObjectives;
      program.terms = this.mapProgramTerms(backendProgram.programTerms);
      program.requiredSkills = backendProgram.skills;
      program.programPreRequisites = backendProgram.preRequisites ? this.mapProgramPrerequisites(
        backendProgram.preRequisites
      ) : [];
      program.coAdmins = backendProgram.coAdmins?.filter((admin: any) => admin).map((admin: any) => {
        admin.name = admin.firstName + ' ' + admin.lastName;
        admin.userLFID = admin.userLFID;
        admin.role = 'Mentorship Admin';
        return admin
      });
      program.mentors = this.mapProgramMentors(backendProgram.mentors);
      if (program.terms.length) {
        program.displayStatus = this.mapProgramStatus(program);
      } else {
        program.displayStatus = ProgramStatus.draft;
      }
      program.projects = this.mapProgramProjects(backendProgram.projects);
      return program;
    });
  }


}
