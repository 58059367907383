import { Subject, Observable, map } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpStatusCode } from '@angular/common/http';
import { AuthService } from '@auth0/auth0-angular';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class UserService {
    api = environment.adminBFF;
    mentorshipBFF = environment.mentorshipBFF; 
    constructor(private http: HttpClient, private authService: AuthService) {}

    getUserDetails() {
        return this.http.get<any>(`${this.api}/users/me`);
    }

    updateUserDetails(reqBody: any, userId: string) {
        return this.http.put(`${this.mentorshipBFF}/users/${userId}`, reqBody);
      }

    getResumePresignedURL(reqBody : any, userId: string) {
        return this.http.put(`${this.mentorshipBFF}/users/${userId}/resumes/upload/init`, reqBody).pipe(map((res: any) => {
            return res.PresignedURL;
        }))
    }
   updateUserResume(resumeReqBody : any, userId: string) {
       return this.http.post(`${this.mentorshipBFF}/users/${userId}/resumes`, resumeReqBody)
   }

}